import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import './sign.css';
import { pdfjs } from 'react-pdf';

import { Rnd } from "react-rnd";
import Loading from '../../../../components/loading/loading'

class Sign extends Component {

    constructor(props) {
        super(props);

        this.state = {
            scrollY: window.innerHeight / 2,
            pdfUrl: this.props.url,
            pdfContext: null,
            numPages: null,
            pageNumber: 1,
            w: 55,
            h: 55,
            x: 250,
            y: 250,
            pulse: true,
            isSigning: 0,
            signPoints: [],
            scale: 1.2,
            customText: "",
            notifications: [],
            loaded: false
        }

        this.scrollEvent = this.scrollEvent.bind(this);

        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

        this.addSignSpot = this.addSignSpot.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.addSig = this.addSig.bind(this);
        this.addDate = this.addDate.bind(this);
        this.removeObject = this.removeObject.bind(this);
        this.calcScaledCords = this.calcScaledCords.bind(this);
        this.loadPdf = this.loadPdf.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.changeNotif = this.changeNotif.bind(this);
        this.finishNotification = this.finishNotification.bind(this);
    }

    loadPdf() {
        var loadingTask = pdfjs.getDocument(this.state.pdfUrl);
        loadingTask.promise.then((pdf) => {
            pdf.getPage(this.state.pageNumber).then((page) => {
                var viewport = page.getViewport({ scale: this.state.scale, });

                this.setState({ numPages: pdf._pdfInfo.numPages, pdfContext: pdf, loaded: true });

                var canvas = document.getElementById('the-canvas');
                var context = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                var renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };
                page.render(renderContext);

                M.Dropdown.init(document.querySelectorAll('.dropdown-trigger'), { coverTrigger: false });

                M.Modal.init(document.querySelectorAll('.modal'));
            });
        });
    }

    checkAll() {
        let isChecked = document.getElementById('checkAll').checked;
        this.state.notifications.map((data) => {
            data.active = isChecked;
            document.getElementById(data.id).checked = isChecked;
        });
    }

    changeNotif(id) {
        this.state.notifications.map((data) => {
            if (data.id == id) {
                let checked = document.getElementById(data.id).checked;
                data.active = checked;
            }
        });
    }

    finishNotification() {

        let data = {}
        data['users'] = this.state.notifications;
        data['type'] = this.props.type;
        data['dealName'] = this.props.dealName;
        data['dealId'] = this.props.dealId;

        fetch(this.props.route + `/sendnotifications`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                this.props.back();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    componentDidMount() {
        window.addEventListener("scroll", this.scrollEvent);

        this.loadPdf();
        if (this.props.isPartner) {
            this.props.notifs.map((data) => {
                let type = "legal";
                if (data.role === 3) {
                    type = "loanAdmin";
                }
                this.state.notifications.push({
                    id: data.id,
                    name: data.name,
                    type: type,
                    active: true
                });
            });
        } else {
            this.props.notifs.map((data) => {
                let type = "legal";
                if (data.role === 3) {
                    type = "loanAdmin";
                }
                this.state.notifications.push({
                    id: data.id,
                    name: data.name,
                    type: type,
                    active: false
                });
            });
        }



    }

    scrollEvent() {
        this.setState({ scrollY: window.scrollY + window.innerHeight / 2 });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollEvent);
    }

    calcScaledCords(x, y) {
        let origHeight = Math.abs((y * 0.17) - y);
        let origWidth = Math.abs((x * 0.17) - x);
        this.setState({ x: origWidth, y: origHeight, checkPulse: true });
    }

    removeObject() {
        this.setState({ isSigning: 0 });
    }

    setOjbectBounds(w, h) {
        this.setState({ w: w, h: h, pulse: false });
    }

    addSignSpot(type) {
        let pageHeight = parseInt(document.getElementById("the-canvas").height);
        let updatedY = Math.abs(this.state.y - (pageHeight / this.state.scale));

        let data = {};

        let object = {
            x: this.state.x,
            y: updatedY,
            w: this.state.w,
            h: this.state.h,
            type: type,
            page: this.state.pageNumber
        }

        this.state.signPoints.push(object)

        data['dealId'] = this.props.dealId;
        data['type'] = this.props.type;
        data['object'] = this.state.signPoints;
        data['userId'] = this.props.userId;
        data['dealName'] = this.props.dealName;
        data['customText'] = this.state.customText;

        fetch(this.props.route + `/signPDF`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                //Reload notifs, btn located in nav
                document.getElementById('reload-state').click();

                //IF not admin save signpoints else reset on every sign.
                if (this.props.userId == null) {
                    let url = this.props.url.split("/getfile?")[0]
                    this.setState({ pdfUrl: url + res.url, isSigning: 0, checkPulse: false, w: 55, h: 55, pulse: true });
                    this.props.setSigned();
                } else {
                    if (this.props.type === "DS" || this.props.type === "RN" || this.props.type === "LD" || this.props.type === "LDR") {
                        this.setState({ pdfUrl: this.props.url + "&signed=true", isSigning: 0, checkPulse: false, w: 55, h: 55, pulse: true, signPoints: [] });
                        this.props.updateFS(this.props.type);
                    } else {
                        this.setState({ pdfUrl: this.props.url, isSigning: 0, checkPulse: false, w: 55, h: 55, pulse: true, signPoints: [] });
                    }

                }
                document.getElementById('show-done-btn').classList.remove('hidden');

                M.toast({ html: `Document Signed!`, classes: 'rounded green' });
                this.loadPdf();

            })
            .catch((err) => {
                console.log(err);
            })

    }

    addSig() {
        this.setState({ isSigning: 1 });
    }

    showCustomText() {
        document.getElementById('open-custom-text').click();
    }
    addDate() {
        this.setState({ isSigning: 2, customText: document.getElementById('custom-text-edit').value });
    }

    nextPage() {
        let x = this.state.pageNumber + 1;
        if (x > this.state.numPages) {
            return;
        }
        this.setState({ pageNumber: x });
        this.state.pdfContext.getPage(x).then((page) => {
            var viewport = page.getViewport({ scale: this.state.scale, });

            var canvas = document.getElementById('the-canvas');
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            var renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            page.render(renderContext);
        });
    }

    prevPage() {
        let x = this.state.pageNumber - 1;
        if (x <= 0) {
            return;
        }
        this.setState({ pageNumber: x });
        this.state.pdfContext.getPage(x).then((page) => {
            var viewport = page.getViewport({ scale: this.state.scale, });

            var canvas = document.getElementById('the-canvas');
            var context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            var renderContext = {
                canvasContext: context,
                viewport: viewport,
            };
            page.render(renderContext);
        });
    }

    render() {
        if (this.props.url === "") {
            return;
        }

        if (!this.state.loaded) {
            return (
                <Loading />
            );
        }

        const style = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderWidth: "0.1em",
            borderStyle: "dashed",
            zIndex: '999'
        };

        let pulse;
        if (this.state.pulse) {
            pulse = "green lighten-2 pulse"
        }

        let signature;
        let date;
        if (this.state.isSigning === 1) {
            signature = <Rnd
                id="sig-drag"
                style={style}
                className={pulse}
                maxHeight={55 * this.state.scale}
                minHeight={55 * this.state.scale}
                minWidth={55 * this.state.scale}
                maxWidth={55 * this.state.scale}
                default={{
                    x: 250 * this.state.scale,
                    y: parseInt(this.state.scrollY),
                    width: 55 * this.state.scale,
                    height: 55 * this.state.scale,
                }}
                onDragStart={() => { this.setState({ pulse: false }); }}
                onResizeStop={() => { var w = document.getElementById('sig-drag').offsetWidth; var h = document.getElementById('sig-drag').offsetHeight; this.setOjbectBounds(w, h) }}
                onDragStop={(e, d) => { this.calcScaledCords(d.x, d.y) }}
            >
                <img alt="" width={this.state.w * this.state.scale} height={this.state.h * this.state.scale} draggable="false" src={`${this.props.sigRoute}/${this.props.myId}.png`} />
                <button onClick={() => { this.addSignSpot("Signature") }} className="btn waves-effect waves-light green z-depth-1 document-stamp-btn"> <i className="material-icons prefix">check</i></button>
                <button onClick={this.removeObject} className="btn waves-effect waves-light red z-depth-1 document-stamp-btn-remove"> <i className="material-icons prefix">remove</i></button>

            </Rnd>
        } else if (this.state.isSigning === 2) {
            date = <Rnd
                id="date-drag"
                style={style}
                className={pulse}
                maxHeight="75"
                minHeight="75"
                minWidth="150"
                default={{
                    x: 250 * this.state.scale,
                    y: parseInt(this.state.scrollY), //position at bottom
                    width: 150,
                    height: 75,
                }}
                onDragStart={() => { this.setState({ pulse: false }); }}
                onResizeStop={() => { var w = document.getElementById('date-drag').offsetWidth; var h = document.getElementById('date-drag').offsetHeight; this.setState({ w: w, h: h, pulse: false }) }}
                onDragStop={(e, d) => { this.calcScaledCords(d.x, d.y) }}
            >
                <p>{this.state.customText}</p>
                <button onClick={() => { this.addSignSpot("date") }} className="btn waves-effect waves-light green z-depth-1 document-stamp-btn"> <i className="material-icons prefix">check</i></button>
                <button onClick={this.removeObject} className="btn waves-effect waves-light red z-depth-1 document-stamp-btn-remove"> <i className="material-icons prefix">remove</i></button>

            </Rnd>
        }

        let notification = this.state.notifications.map((data) => {
            return (
                <tr key={Math.random()} className="mydeal-table-row" >
                    <td className="mydeals-table-font sign-table-row">{data.name}</td>
                    <td className="sign-table-row"><p><label><input id={data.id} className="user-checkbox" onChange={() => { this.changeNotif(data.id) }} type="checkbox" /> <span></span> </label></p></td>
                </tr>
            );
        });

        let doneBtn = <button id="show-done-btn" className="btn waves-effect pulse waves-green green hidden modal-trigger sign-done-btn" data-target="modal2">Done</button>;
        if (this.props.isPartner) {
            doneBtn = <button id="show-done-btn" onClick={this.finishNotification} className="btn waves-effect pulse waves-green green hidden  sign-done-btn">Done</button>
        }



        return (
            <div className="document-sign-main-div">
                {this.props.note !== "" && window.innerWidth > 800 ?
                    <button onClick={() => { alert(this.props.note) }} className="btn green pulse z-depth-3 sign-note-btn">View Note</button>
                    :
                    null
                }
                <div id="modal2" className="modal blue-grey darken-4">
                    <div className="modal-content">
                        <button onClick={this.finishNotification} className="modal-close waves-effect waves-green btn green right">Finish<i className="material-icons prefix right">check</i></button>
                        <h4 className="center white-text">Notify Users</h4>
                        <table className="centered manage-entries-table z-depth-5">
                            <thead>
                                <tr className="viewdeal-tabel-th">
                                    <th>Name</th>
                                    <th><p>
                                        <label>
                                            <input id="checkAll" className="user-checkbox" onChange={this.checkAll} type="checkbox" />
                                            <span></span>
                                        </label>
                                    </p></th>
                                </tr>
                            </thead>

                            <tbody id="deals-table" className="sign-notification-div">
                                {notification}
                            </tbody>
                        </table>
                    </div>
                </div>
                <button id="open-custom-text" className="waves-effect waves-light btn modal-trigger hidden" data-target="modal1"></button>
                <div id="modal1" className="modal">
                    <div className="modal-content">
                        <h5 className="center">Edit the text below</h5>
                        <br />
                        <textarea id="custom-text-edit" defaultValue={new Date().toDateString()} className="center" />
                    </div>
                    <div className="center">
                        <button onClick={this.addDate} className="btn btn-large modal-close waves-effect waves-green green">Insert</button>
                    </div>
                    <br />
                </div>
                <div className="document-toolbar-div">
                    <button onClick={this.props.back} className="btn-floating red z-depth-5"><i className="material-icons">arrow_back</i></button>
                    &nbsp;&nbsp;&nbsp;
                    <button className='btn-floating dropdown-trigger pulse document-sign-button green z-depth-5' data-target='dropdown1'><i className="material-icons">offline_pin</i></button>
                    <ul id='dropdown1' className='dropdown-content document-toolbar-dropdown'>
                        <li className="toolbar-dropdown waves-effect waves-light " onTouchStart={this.showCustomText} onClick={this.showCustomText}><i className="material-icons left blue-text">date_range</i>Custom Date</li>
                        <li className="toolbar-dropdown waves-effect waves-light " onTouchStart={this.addSig} onClick={this.addSig}><i className="material-icons left blue-text">mode_edit</i>Signature</li>
                    </ul>
                    <div className="document-switch-page-div">
                        <button onClick={this.prevPage} className="btn-floating waves-effect waves-light blue z-depth-5"><i className="material-icons">chevron_left</i></button>
                        &nbsp;&nbsp;&nbsp;
                    <i className="white-text">{this.state.pageNumber}/{this.state.numPages}</i>
                        &nbsp;&nbsp;&nbsp;
                    <button onClick={this.nextPage} className="btn-floating waves-effect waves-light blue z-depth-5"><i className="material-icons">chevron_right</i></button>
                    </div>
                    <div className="center">
                        {doneBtn}
                    </div>
                </div>
                <div id='document-container' className="document-container">
                    <div>
                        {date}
                        {signature}
                        <canvas id="the-canvas"></canvas>
                    </div>
                </div>
            </div>

        );

    }
}

export default Sign;
