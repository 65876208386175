import React, { Component } from 'react';
import '../../documents.css';

class Users extends Component {

    constructor(props) {
        super(props);

        this.optionSelected = this.optionSelected.bind(this);

    }


    optionSelected(event, form) {

        switch (event.target.options[event.target.selectedIndex].text) {
            case "View Signed Document":
                this.props.getPdf(false, event.target.value)
                break;
            case "Sign Document":
                if (this.props.type === "DS" || this.props.type === "RN" || this.props.type === "LD" || this.props.type === "LDR") {
                    if (form === "2") {
                        this.props.sign(event.target.value, true)
                    } else {
                        this.props.sign(event.target.value, false)
                    }
                } else {
                    this.props.sign(event.target.value, true)
                }
                break;
            case "Clear File":
                this.props.clear(event.target.value);
                break;
            case "Download Original Document":
                this.props.download(true, event.target.value)
                break;
            case "Download Signed Document":
                this.props.download(false, event.target.value)
                break;
            case "Delete Signed Document":
                this.props.deleteFile(event.target.value)
                break;
            case "View Original Document":
                this.props.getPdf(true, event.target.value)
                break;
            case "Re-upload Document":
                let currentFile;
                switch (this.props.type) {
                    case "LDR":
                        currentFile = "Lender Disclosure Renewal"
                        break;
                    case "LD":
                        currentFile = "Lender Disclosure"
                        break;
                    case "RN":
                        currentFile = "Renewal"
                        break;
                    case "DS":
                        currentFile = "Discharge Statement"
                        break;
                    case "9D":
                        currentFile = "Form 9D"
                        break;
                    case "CD":
                        currentFile = "Closing Documents"
                        break;
                    case "DAD":
                        currentFile = "Discharge Documents"
                        break;
                    case "ARD":
                        currentFile = "Assignment"
                        break;
                    default:
                        break;
                }
                this.props.uploadPdf({ id: event.target.value, name: "Re-uploading" }, { id: this.props.type, name: currentFile })
                break;
            default:
                break;
        }
        return;
    }

    render() {
        return this.props.entries.map((data) => {

            let isSigned = <i className="material-icons prefix red-cross">close</i>;
            let canSign;
            let canUpload;
            let canClear;
            let uploadIcon;
            switch (this.props.type) {

                case "LDR":
                    if (data.userId === 'q0yQCoJtduJn57KFONE1KrzJbbf6bM1O') {
                        canSign = <option value={data.userId}>Sign Document</option>
                        canClear = <option value={data.userId}>Clear File</option>;
                        canUpload = <option value={data.userId}>Re-upload Document</option>;
                        uploadIcon = <td className="mydeals-table-font"><button onClick={() => { this.props.setUpload({ id: data.userId, name: data.name }, { id: "LDR", name: "Lender Disclosure Renewal" }) }} className="btn z-depth-3">Upload</button></td>
                        if (this.props.role === 2) {
                            canSign = "";
                            canClear = "";
                            canUpload = "";
                            uploadIcon = <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>;
                        }
                        if (data.ldrForm === "2") {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={(event) => { this.optionSelected(event, data.ldrForm) }}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Signed Document</option>
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Signed Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                            {/* <option value={data.userId}>Delete Signed Document</option> */}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else if (data.ldrForm === "1") {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    {uploadIcon}
                                    <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                        </select>
                                    </td>
                                </tr>
                            )
                        }
                    } else {
                        return null;
                    }
                case "LD":
                    if (data.userId === 'q0yQCoJtduJn57KFONE1KrzJbbf6bM1O') {
                        canSign = <option value={data.userId}>Sign Document</option>
                        canUpload = <option value={data.userId}>Re-upload Document</option>;
                        canClear = <option value={data.userId}>Clear File</option>;
                        uploadIcon = <td className="mydeals-table-font"><button onClick={() => { this.props.setUpload({ id: data.userId, name: data.name }, { id: "LD", name: "Lender Disclosure" }) }} className="btn z-depth-3">Upload</button></td>
                        if (this.props.role === 2) {
                            canSign = "";
                            canUpload = "";
                            canClear = "";
                            uploadIcon = <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>;
                        }
                        if (data.ldForm === "2") {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={(event) => { this.optionSelected(event, data.ldForm) }}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Signed Document</option>
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Signed Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                            {/* <option value={data.userId}>Delete Signed Document</option> */}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else if (data.ldForm === "1") {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    {uploadIcon}
                                    <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                        </select>
                                    </td>
                                </tr>
                            )
                        }
                    } else {
                        return null;
                    }
                case "RN":
                    if (data.userId === 'q0yQCoJtduJn57KFONE1KrzJbbf6bM1O') {
                        canSign = <option value={data.userId}>Sign Document</option>
                        canClear = <option value={data.userId}>Clear File</option>;
                        canUpload = <option value={data.userId}>Re-upload Document</option>;
                        uploadIcon = <td className="mydeals-table-font"><button onClick={() => { this.props.setUpload({ id: data.userId, name: data.name }, { id: "RN", name: "Renewal" }) }} className="btn z-depth-3">Upload</button></td>
                        if (this.props.role === 2) {
                            canSign = "";
                            canClear = "";
                            canUpload = "";
                            uploadIcon = <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>;
                        }
                        if (data.rnForm === "2") {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={(event) => { this.optionSelected(event, data.rnForm) }}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Signed Document</option>
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Signed Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                            {/* <option value={data.userId}>Delete Signed Document</option> */}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else if (data.rnForm === "1") {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    {uploadIcon}
                                    <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                        </select>
                                    </td>
                                </tr>
                            )
                        }
                    } else {
                        return null;
                    }
                case "DS":
                    if (data.userId === 'q0yQCoJtduJn57KFONE1KrzJbbf6bM1O') {
                        canSign = <option value={data.userId}>Sign Document</option>
                        canClear = <option value={data.userId}>Clear File</option>;
                        canUpload = <option value={data.userId}>Re-upload Document</option>;
                        uploadIcon = <td className="mydeals-table-font"><button onClick={() => { this.props.setUpload({ id: data.userId, name: data.name }, { id: "DS", name: "Discharge Statement" }) }} className="btn z-depth-3">Upload</button></td>
                        if (this.props.role === 2) {
                            canSign = "";
                            canUpload = "";
                            canClear = "";
                            uploadIcon = <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>;
                        }
                        if (data.dsForm === "2") {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={(event) => { this.optionSelected(event, data.dsForm) }}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Signed Document</option>
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Signed Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                            {/* <option value={data.userId}>Delete Signed Document</option> */}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else if (data.dsForm === "1") {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">{data.name}</td>
                                    {uploadIcon}
                                    <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                        </select>
                                    </td>
                                </tr>
                            )
                        }
                    } else {
                        return null;
                    }
                case "9D":
                    canClear = <option value={data.userId}>Clear File</option>;
                    canSign = <option value={data.userId}>Sign Document</option>
                    canUpload = <option value={data.userId}>Re-upload Document</option>;
                    uploadIcon = <td className="mydeals-table-font"><button onClick={() => { this.props.setUpload({ id: data.userId, name: data.name }, { id: "9D", name: "Form 9D" }) }} className="btn z-depth-3">Upload</button></td>
                    if (this.props.role === 3) {
                        canSign = "";
                        canUpload = "";
                        canClear = "";
                        uploadIcon = <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>;
                    }
                    if (data.nineDForm === "2") {
                        return (
                            <tr key={Math.random()} className="mydeal-table-row">
                                <td className="mydeals-table-font">{data.name}</td>
                                <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                <td className="mydeals-table-font documents-user-select">
                                    <select className="browser-default" onChange={this.optionSelected}>
                                        <option value={data.userId}>Choose an option</option>
                                        {canSign}
                                        <option value={data.userId}>View Signed Document</option>
                                        <option value={data.userId}>View Original Document</option>
                                        <option value={data.userId}>Download Signed Document</option>
                                        <option value={data.userId}>Download Original Document</option>
                                        {canUpload}
                                        {canClear}
                                        {/* <option value={data.userId}>Delete Signed Document</option> */}
                                    </select>
                                </td>
                            </tr>
                        )
                    } else if (data.nineDForm === "1") {
                        return (
                            <tr key={Math.random()} className="mydeal-table-row">
                                <td className="mydeals-table-font">{data.name}</td>
                                <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                <td className="mydeals-table-font documents-user-select">
                                    <select className="browser-default" onChange={this.optionSelected}>
                                        <option value={data.userId}>Choose an option</option>
                                        <option value={data.userId}>View Original Document</option>
                                        <option value={data.userId}>Download Original Document</option>
                                        {canUpload}
                                        {canClear}
                                    </select>
                                </td>
                            </tr>
                        )
                    } else {
                        return (
                            <tr key={Math.random()} className="mydeal-table-row">
                                <td className="mydeals-table-font">{data.name}</td>
                                {uploadIcon}
                                <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>
                                <td className="mydeals-table-font documents-user-select">
                                    <select className="browser-default" onChange={this.optionSelected}>
                                        <option value={data.userId}>Choose an option</option>
                                    </select>
                                </td>
                            </tr>
                        )
                    }
                case "CD":
                    if (data.userId === 'q0yQCoJtduJn57KFONE1KrzJbbf6bM1O') {
                        canUpload = <option value={data.userId}>Re-upload Document</option>;
                        canClear = <option value={data.userId}>Clear File</option>;
                        uploadIcon = <td className="mydeals-table-font"><button onClick={() => { this.props.setUpload({ id: "ALL", name: "ALL" }, { id: "CD", name: "Closing Documents" }) }} className="btn z-depth-3">Upload</button></td>;
                        if (this.props.role === 3) {
                            canUpload = "";
                            canClear = "";
                            uploadIcon = <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>;
                        }
                        if (data.cdForm === "1") {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">Group Upload</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font">Not Required</td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                        </select>
                                    </td>
                                </tr>
                            )
                        }
                        return (
                            <tr key={Math.random()} className="mydeal-table-row">
                                <td className="mydeals-table-font">Group Upload</td>
                                {uploadIcon}
                                <td className="mydeals-table-font">Not Required</td>
                                <td className="mydeals-table-font documents-user-select">
                                    <select className="browser-default" onChange={this.optionSelected}>
                                        <option value={data.userId}>Choose an option</option>
                                    </select>
                                </td>
                            </tr>
                        )
                    } else {
                        return null;
                    }

                case "DAD":
                    if (data.userId === 'q0yQCoJtduJn57KFONE1KrzJbbf6bM1O') {
                        canSign = <option value={data.userId}>Sign Document</option>
                        canClear = <option value={data.userId}>Clear File</option>;
                        canUpload = <option value={data.userId}>Re-upload Document</option>;
                        uploadIcon = <td className="mydeals-table-font"><button onClick={() => { this.props.setUpload({ id: data.userId, name: data.name }, { id: "DAD", name: "Discharge Documents" }) }} className="btn z-depth-3">Upload</button></td>
                        if (this.props.role === 3) {
                            canSign = "";
                            canUpload = "";
                            canClear = "";
                            uploadIcon = <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>;
                        }
                        if (data.dadForm === "2" || data.dadForm === "1") {
                            isSigned = <i className="material-icons prefix green-check">check</i>;
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">Group Sign</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><button onClick={this.props.showSigns} className="btn z-depth-3">Sig's</button></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Signed Document</option>
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Signed Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                            {/* <option value={data.userId}>Delete Signed Document</option> */}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">Group Sign</td>
                                    {uploadIcon}
                                    <td className="mydeals-table-font">{isSigned}</td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                        </select>
                                    </td>
                                </tr>
                            )
                        }
                    } else {
                        return null;
                    }
                case "ARD":
                    if (data.userId === 'q0yQCoJtduJn57KFONE1KrzJbbf6bM1O') {
                        canSign = <option value={data.userId}>Sign Document</option>
                        canClear = <option value={data.userId}>Clear File</option>;
                        canUpload = <option value={data.userId}>Re-upload Document</option>;
                        uploadIcon = <td className="mydeals-table-font"><button onClick={() => { this.props.setUpload({ id: data.userId, name: data.name }, { id: "ARD", name: "Assignment" }) }} className="btn z-depth-3">Upload</button></td>
                        if (this.props.role === 3) {
                            canSign = "";
                            canClear = "";
                            canUpload = "";
                            uploadIcon = <td className="mydeals-table-font"><i className="material-icons prefix red-cross">close</i></td>;
                        }
                        if (data.ardForm === "2" || data.ardForm === "1") {
                            isSigned = <i className="material-icons prefix green-check">check</i>;
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">Group Sign</td>
                                    <td className="mydeals-table-font"><i className="material-icons prefix green-check">check</i></td>
                                    <td className="mydeals-table-font"><button onClick={this.props.showSigns} className="btn z-depth-3">Sig's</button></td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                            {canSign}
                                            <option value={data.userId}>View Signed Document</option>
                                            <option value={data.userId}>View Original Document</option>
                                            <option value={data.userId}>Download Signed Document</option>
                                            <option value={data.userId}>Download Original Document</option>
                                            {canUpload}
                                            {canClear}
                                        </select>
                                    </td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr key={Math.random()} className="mydeal-table-row">
                                    <td className="mydeals-table-font">Group Sign</td>
                                    {uploadIcon}
                                    <td className="mydeals-table-font">{isSigned}</td>
                                    <td className="mydeals-table-font documents-user-select">
                                        <select className="browser-default" onChange={this.optionSelected}>
                                            <option value={data.userId}>Choose an option</option>
                                        </select>
                                    </td>
                                </tr>
                            )
                        }
                    } else {
                        return null;
                    }

                default:
                    break;
            }
            return null;

        })


    }
}

export default Users;
