import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './manageusers.css';
import { throws } from 'assert';

import Loading from '../../../loading/loading'

import Cookies from 'js-cookie';;

class ManageUsers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            searchNew: null,
            loaded: false,
            filter: "Partner",
            page: 1,
        }

        this.remainingTotal = 0;

        this.pageLimit = 12;

        this.userEditId = null;

        this.register = this.register.bind(this);
        this.searchUsers = this.searchUsers.bind(this);
        this.editUser = this.editUser.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.updateSortFilter = this.updateSortFilter.bind(this);
        this.changePage = this.changePage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.setPageAmount = this.setPageAmount.bind(this);
        this.reSort = this.reSort.bind(this);
    }

    componentDidMount() {

        //Fetch new users
        fetch(this.props.route + `/grabusers`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {

                let num = Cookies.get('perPage');
                if (num != "" && num != 0 && num != null) {
                    this.pageLimit = num;
                }

                //Reset Cap
                this.remainingTotal = 0;

                let partners = [[]];
                let partnerCount = 0;
                let partnerPage = 0;

                let legals = [[]];
                let legalPage = 0;
                let legalCount = 0;

                let loanAdmins = [[]];
                let loanAdminPage = 0;
                let loadAdminCount = 0;

                //Organize by account type with pages (Max 12 per page)
                for (var i in res.data) {
                    switch (res.data[i].role) {
                        case 1:
                        case 0:
                            if (partnerCount >= this.pageLimit) {
                                partnerCount = 0;
                                partnerPage++;
                                partners[partnerPage] = new Array();
                            }
                            partners[partnerPage].push(res.data[i]);
                            partnerCount++;
                            if (res.data[i].id != "q0yQCoJtduJn57KFONE1KrzJbbf6bM1O") {
                                this.remainingTotal += parseInt(res.data[i].capitalAvailable);
                            }
                            break;
                        case 2:
                            if (legalCount >= this.pageLimit) {
                                legalCount = 0;
                                legalPage++;
                                legals[legalPage] = new Array();
                            }
                            legals[legalPage].push(res.data[i]);
                            legalCount++;
                            break;
                        case 3:
                            if (loadAdminCount >= this.pageLimit) {
                                loadAdminCount = 0;
                                loanAdminPage++;
                                loanAdmins[loanAdminPage] = new Array();
                            }
                            loanAdmins[loanAdminPage].push(res.data[i]);
                            loadAdminCount++;
                            break;
                    }
                }

                this.setState({ partners: partners, legals: legals, loanAdmins: loanAdmins, data: res.data, loaded: true });

                var adduser = document.querySelectorAll('.modal');
                M.Modal.init(adduser);
                //Select
                M.FormSelect.init(document.querySelectorAll('select'));
            })
            .catch((err) => {
                console.log(err);
            })

    }

    deleteUser(id) {
        if (window.confirm("Delete User?")) {
            fetch(this.props.route + `/deleteuser?id=${id}`, {
                method: 'GET',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include'
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    M.toast({ html: `User Deleted!`, classes: 'rounded green' });
                    this.setState({ page: 1 });
                    this.componentDidMount();
                })
                .catch((err) => {
                    M.toast({ html: `Error!`, classes: 'rounded red' });
                    console.log(err);
                })
        }
    }

    register(e) {
        //Prevent original form submit
        e.preventDefault();

        //Get user details from form
        var data = {};
        data['username'] = e.target[0].value;
        data['capital'] = e.target[1].value;
        data['name'] = e.target[2].value;
        data['email'] = e.target[3].value;
        data['phone'] = e.target[4].value;
        data['company'] = e.target[5].value;
        data['password'] = e.target[6].value;
        data['accountType'] = document.getElementById('register-accountType').value;
        data['notes'] = e.target[8].value;

        fetch(this.props.route + `/register`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (res.reason == "exists") {
                    M.toast({ html: `Username Is Taken!`, classes: 'rounded red' });
                } else {
                    document.getElementById('close-modal-register').click();
                    M.toast({ html: `User Created!`, classes: 'rounded green' });
                    this.componentDidMount();
                }
            })
            .catch((err) => {
                M.toast({ html: `Error!`, classes: 'rounded red' });
            })
    }

    searchUsers() {
        let word = document.getElementById('register-search-users').value;
        this.setState({ searchNew: word });
    }

    editUser(data) {
        if (data != null) {

            this.userEditId = data.id;
            document.getElementById('update-username').value = data.username;
            document.getElementById('update-name').value = data.name;
            document.getElementById('update-email').value = data.email;
            document.getElementById('update-phone').value = data.phone;
            document.getElementById('update-capital').value = data.capital;
            document.getElementById('update-capitalAvailable').value = data.capitalAvailable;
            document.getElementById('update-company').value = data.company;
            document.getElementById('update-notes').value = data.notes;
            document.getElementById('update-active').value = data.active;
            document.getElementById('update-toggle').click();
        }
    }

    updateUser() {

        let data = {};
        data['id'] = this.userEditId;
        data['username'] = document.getElementById('update-username').value;
        data['name'] = document.getElementById('update-name').value;
        data['email'] = document.getElementById('update-email').value;
        data['phone'] = document.getElementById('update-phone').value;
        data['capital'] = document.getElementById('update-capital').value
        data['password'] = document.getElementById('update-password').value
        data['company'] = document.getElementById('update-company').value
        data['notes'] = document.getElementById('update-notes').value
        data['active'] = document.getElementById('update-active').value

        fetch(this.props.route + `/adminupdateuser`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (res.response) {
                    M.toast({ html: `User Updated!`, classes: 'rounded green' });
                    document.getElementById('close-modal-update').click();
                    this.componentDidMount();
                } else {
                    M.toast({ html: res.reason, classes: 'rounded red' });
                }


            })
            .catch((err) => {
                M.toast({ html: `Error!`, classes: 'rounded red' });
            })

    }

    moneyFormat(x) {
        if (x == null) {
            return;
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    updateSortFilter(event) {
        this.setState({ filter: event.target.value, page: 1 });
    }

    changePage(pageNum) {
        this.setState({ page: pageNum });
    }

    nextPage() {
        let page = this.state.page + 1;
        let max;
        switch (this.state.filter) {
            case "Partner":
                max = this.state.partners.length;
                break;
            case "Legal":
                max = this.state.legals.length;
                break;
            case "Loan Admin":
                max = this.state.loanAdmins.length;
                break;
        }
        if (page <= max) {
            this.setState({ page: page });
        }
    }
    prevPage() {
        let page = this.state.page - 1;
        if (page >= 1) {
            this.setState({ page: page });
        }
    }

    setPageAmount(event) {
        Cookies.set('perPage', event.target.value);
        this.pageLimit = parseInt(event.target.value);
        this.reSort(this.state.data);
    }

    reSort(data) {
        this.remainingTotal = 0;

        let partners = [[]];
        let partnerCount = 0;
        let partnerPage = 0;

        let legals = [[]];
        let legalPage = 0;
        let legalCount = 0;

        let loanAdmins = [[]];
        let loanAdminPage = 0;
        let loadAdminCount = 0;

        for (var i in data) {
            switch (data[i].role) {
                case 1:
                case 0:
                    if (partnerCount >= this.pageLimit) {
                        partnerCount = 0;
                        partnerPage++;
                        partners[partnerPage] = new Array();
                    }
                    partners[partnerPage].push(data[i]);
                    partnerCount++;
                    if (data[i].id != "q0yQCoJtduJn57KFONE1KrzJbbf6bM1O") {
                        this.remainingTotal += parseInt(data[i].capitalAvailable);
                    }
                    break;
                case 2:
                    if (legalCount >= this.pageLimit) {
                        legalCount = 0;
                        legalPage++;
                        legals[legalPage] = new Array();
                    }
                    legals[legalPage].push(data[i]);
                    legalCount++;
                    break;
                case 3:
                    if (loadAdminCount >= this.pageLimit) {
                        loadAdminCount = 0;
                        loanAdminPage++;
                        loanAdmins[loanAdminPage] = new Array();
                    }
                    loanAdmins[loanAdminPage].push(data[i]);
                    loadAdminCount++;
                    break;
            }
        }

        this.setState({ partners: partners, legals: legals, loanAdmins: loanAdmins, page: 1 });
    }

    render() {

        if (!this.state.loaded) {
            return <Loading />
        }

        //Available amount of all users
        let remainingTotal = 0;

        //Get all users (If searchnew is null the show all else show matched users)
        let users;
        let pages;

        let userList;

        if (this.state.data != null) {

            //Search category for user
            if (this.state.searchNew != null && this.state.searchNew != "") {
                users = this.state.data.map((data) => {
                    if (this.state.filter == "Partner" && data.role != "0") {
                        return null;
                    } else if (this.state.filter == "Legal" && data.role != "2") {
                        return null;
                    } else if (this.state.filter == "Loan Admin" && data.role != "3") {
                        return null;
                    }

                    if (data.username.toUpperCase().includes(this.state.searchNew.toUpperCase()) || data.name.toUpperCase().includes(this.state.searchNew.toUpperCase())
                        || data.email.toUpperCase().includes(this.state.searchNew.toUpperCase())) {
                        return (
                            <div key={data.id} className="col l2 m4 s6">
                                <div className="admin-deal-div z-depth-5">
                                    <div className="row">
                                        <a className="login-color user-div-font-size col l12 s12 m12">{data.name}</a>
                                    </div>
                                    <div className="row">
                                        <div className="col l6 m6 s12">
                                            <button onClick={() => { this.props.viewUser(data.id) }} className="waves-effect waves-light btn blue admin-action-btn">View</button>
                                        </div>
                                        <div className="col l6 m6 s12">
                                            <button onClick={() => { this.deleteUser(data.id) }} className="waves-effect waves-light btn red admin-action-btn">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                });
            } else { //If we are not searching then load pages

                switch (this.state.filter) {
                    case "Partner":
                        pages = this.state.partners.map((data, index) => { //Page one is 0 index in array.
                            let ind = index + 1;
                            if (ind == this.state.page) { //Set active to current page number.
                                return (
                                    <li key={Math.random()} className="waves-effect active pagination-active"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                );
                            } else {

                                return (
                                    <li key={Math.random()} className="waves-effect"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                );
                            }
                        });
                        users = this.state.partners[this.state.page - 1].map((data, index) => {
                            if (this.state.filter == "Partner" && (data.role != "0" && data.role != "1")) {
                                return null;
                            }
                            return (
                                <div key={data.id} className="col l2 m4 s6">
                                    <button onClick={() => { this.editUser(data) }} className="waves-effect waves-light btn-small blue view-user-settings-btn"><i className="material-icons prefix">settings</i></button>

                                    <div className="admin-deal-div z-depth-5">
                                        <div className="row">
                                            <a className="login-color user-div-font-size col l12 s12 m12">{data.name}</a>
                                        </div>
                                        <div className="row">
                                            <div className="col l6 m6 s12">
                                                <button onClick={() => { this.props.viewUser(data.id) }} className="waves-effect waves-light btn blue admin-action-btn">View</button>
                                            </div>
                                            <div className="col l6 m6 s12">
                                                <button onClick={() => { this.deleteUser(data.id) }} className="waves-effect waves-light btn red admin-action-btn">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );

                        });
                        break;
                    case "Legal":
                        pages = this.state.legals.map((data, index) => { //Page one is 0 index in array.
                            let ind = index + 1;
                            if (ind == this.state.page) { //Set active to current page number.
                                return (
                                    <li key={Math.random()} className="waves-effect active pagination-active"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                );
                            } else {

                                return (
                                    <li key={Math.random()} className="waves-effect"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                );
                            }
                        });
                        users = this.state.legals[this.state.page - 1].map((data, index) => {
                            if (this.state.filter == "Legal" && data.role != "2") {
                                return null;
                            }
                            return (
                                <div key={data.id} className="col l2 m4 s6">
                                    <button onClick={() => { this.editUser(data) }} className="waves-effect waves-light btn-small blue view-user-settings-btn"><i className="material-icons prefix">settings</i></button>

                                    <div className="admin-deal-div z-depth-5">
                                        <div className="row">
                                            <a className="login-color user-div-font-size col l12 s12 m12">{data.name}</a>
                                        </div>
                                        <div className="row">
                                            <div className="col l12 m12 s12">
                                                <button onClick={() => { this.deleteUser(data.id) }} className="waves-effect waves-light btn red admin-action-btn">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );

                        });
                        break;
                    case "Loan Admin":
                        pages = this.state.loanAdmins.map((data, index) => { //Page one is 0 index in array.
                            let ind = index + 1;
                            if (ind == this.state.page) { //Set active to current page number.
                                return (
                                    <li key={Math.random()} className="waves-effect active pagination-active"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                );
                            } else {

                                return (
                                    <li key={Math.random()} className="waves-effect"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                );
                            }
                        });
                        users = this.state.loanAdmins[this.state.page - 1].map((data, index) => {
                            if (this.state.filter == "Loan Admin" && data.role != "3") {
                                return null;
                            }
                            return (
                                <div key={data.id} className="col l2 m4 s6">
                                    <button onClick={() => { this.editUser(data) }} className="waves-effect waves-light btn-small blue view-user-settings-btn"><i className="material-icons prefix">settings</i></button>

                                    <div className="admin-deal-div z-depth-5">
                                        <div className="row">
                                            <a className="login-color user-div-font-size col l12 s12 m12">{data.name}</a>
                                        </div>
                                        <div className="row">
                                            <div className="col l12 m12 s12">
                                                <button onClick={() => { this.deleteUser(data.id) }} className="waves-effect waves-light btn red admin-action-btn">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );

                        });
                        break;
                }
            }

            //Create list with name and capital available.

            let arr = this.state.data;
            arr.sort((a, b) => {
                return parseInt(b.capitalAvailable) - parseInt(a.capitalAvailable);
            });
            userList = this.state.data.map((data) => {
                if (data.role != "0") {
                    return;
                }
                return (
                    <tr key={Math.random()} className="mydeal-table-row" >
                        <td className="mydeals-table-font">{data.name}</td>
                        <td className="mydeals-table-font">${this.moneyFormat(data.capitalAvailable)}</td>
                        <td className="mydeals-table-font">${this.moneyFormat(data.totalFunded)}</td>
                    </tr>
                );
            })
        }



        remainingTotal = this.moneyFormat(this.remainingTotal);


        return (

            <div>
                <div id="modal3" className="modal modal-large register-modal" style={{ width: "70%", height: "80%" }}>
                    <div className="modal-content">
                        <button data-target="modal3" className="btn modal-close waves-effect waves-light red right">X</button><br /><br />
                        <div style={{ height: '65vh', width: '100%' }}>
                            <table className="centered manage-entries-table z-depth-5">
                                <thead>
                                    <tr className="viewdeal-tabel-th">
                                        <th>Name</th>
                                        <th>Capital Available</th>
                                        <th>Total Funded</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {userList}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div id="modal2" className="modal register-modal modal-large" style={{ width: "80%", height: "auto" }}>
                    <div className="modal-content">
                        <h4 className="center white-text">Edit Account</h4>
                        <div className="login-inputs row">
                            <div className="input-field col l4 m6 s12">
                                <i className="material-icons prefix green-check">account_circle</i>
                                <input id="update-username" type="text" className="login-color" required />
                                <label htmlFor="update-username" className="active">Username</label>
                            </div>
                            <div className="input-field col l4 m6 s12">
                                <i className="material-icons prefix green-check">work</i>
                                <input className="login-color" id="update-company" type="text" className="login-color" required />
                                <label htmlFor="update-company" className="active">Company</label>
                            </div>
                            <div className="input-field col l4 m6 s12">
                                <i className="material-icons prefix green-check">person</i>
                                <input className="login-color" id="update-name" type="text" className="login-color" required />
                                <label htmlFor="update-name" className="active">Full Name</label>
                            </div>
                            <div className="input-field col l4 m6 s12">
                                <i className="material-icons prefix green-check">mail</i>
                                <input className="login-color" id="update-email" type="text" className="login-color" required />
                                <label htmlFor="update-email" className="active">Email</label>
                            </div>
                            <div className="input-field col l4 m6 s12">
                                <i className="material-icons prefix green-check">phone</i>
                                <input className="login-color" id="update-phone" type="text" className="validate login-color" required />
                                <label htmlFor="update-phone" className="active">Phone</label>
                            </div>
                            <div className="input-field col l4 m6 s12">
                                <i className="material-icons prefix green-check">lock</i>
                                <input className="login-color" id="update-password" type="text" className="login-color" />
                                <label htmlFor="update-password">Password</label>
                            </div>
                            <div className="input-field col l6 m6 s12">
                                <i className="material-icons prefix green-check">attach_money</i>
                                <input className="login-color" id="update-capital" type="number" className="login-color" required />
                                <label htmlFor="update-capital" className="active">Total Capital</label>
                            </div>
                            <div className="input-field col l6 m6 s12">
                                <i className="material-icons prefix green-check">attach_money</i>
                                <input className="login-color" id="update-capitalAvailable" type="text" className="login-color" required />
                                <label htmlFor="update-capitalAvailable" className="active">Capital Available</label>
                            </div>
                            <div className="col l6 m6 s12">
                                <div className="input-field settings-input-display">
                                    <i className="material-icons prefix green-check">attach_money</i>
                                    <input className="login-color" id="update-active" type="text" className="login-color" required />
                                    <label htmlFor="update-active" className="active">is Active?</label>
                                </div>
                            </div>
                            <div className="col l6 m6 s12">
                                <div className="input-field settings-input-display">
                                    <i className="material-icons prefix green-check">speaker_notes</i>
                                    <textarea id="update-notes" className="materialize-textarea login-color" defaultValue={""}></textarea>
                                    <label htmlFor="update-notes" className="active">Notes</label>
                                </div>
                            </div>
                        </div>

                        <div className="login-btn-div">
                            <button onClick={this.updateUser} className="btn waves-effect waves-light btn-large green login-btn" >Update
                            <i className="material-icons right">send</i>
                            </button>
                            <a id="close-modal-update" className="hidden modal-close waves-effect waves-green btn-flat"></a>
                        </div>
                    </div>
                </div>
                <button id="update-toggle" data-target="modal2" className="modal-trigger hidden"></button>
                <div id="modal1" className="modal register-modal modal-large" style={{ width: "80%", height: "auto" }}>
                    <div className="modal-content">
                        <h4 className="center white-text">Create New Account</h4>
                        <form onSubmit={this.register}>

                            <div className="login-inputs row">
                                <div className="input-field col l4 m6 s12">
                                    <i className="material-icons prefix green-check">account_circle</i>
                                    <input id="register-username" type="text" className="login-color" required />
                                    <label htmlFor="register-username" >Username</label>
                                </div>
                                <div className="input-field col l4 m6 s12">
                                    <i className="material-icons prefix green-check">attach_money</i>
                                    <input className="login-color" id="register-capital" type="number" className="login-color" required />
                                    <label htmlFor="register-capital">Total Capital</label>
                                </div>
                                <div className="input-field col l4 m6 s12">
                                    <i className="material-icons prefix green-check">person</i>
                                    <input className="login-color" id="register-name" type="text" className="login-color" required />
                                    <label htmlFor="register-name">Full Name</label>
                                </div>
                                <div className="input-field col l4 m6 s12">
                                    <i className="material-icons prefix green-check">mail</i>
                                    <input className="login-color" id="register-email" type="text" className="login-color" required />
                                    <label htmlFor="register-email">Email</label>
                                </div>
                                <div className="input-field col l4 m6 s12">
                                    <i className="material-icons prefix green-check">phone</i>
                                    <input className="login-color" id="register-phone" type="text" className="login-color" required />
                                    <label htmlFor="register-phone">Phone</label>
                                </div>
                                <div className="input-field col l4 m6 s12">
                                    <i className="material-icons prefix green-check">work</i>
                                    <input className="login-color" id="register-company" type="text" className="login-color" required />
                                    <label htmlFor="register-company">Company</label>
                                </div>
                                <div className="input-field col l4 m6 s12">
                                    <i className="material-icons prefix green-check">lock</i>
                                    <input className="login-color" id="register-password" type="text" className="login-color" required />
                                    <label htmlFor="register-password" >Password</label>
                                </div>
                                <div className="input-field col l4 m6 s12">
                                    <select id="register-accountType">
                                        <option value="0" >Partner</option>
                                        <option value="2">Legal</option>
                                        <option value="3">Loan Admin</option>
                                    </select>
                                    <label>Account Type</label>
                                </div>
                                <div className="col l4 m12 s12">
                                    <div className="input-field settings-input-display">
                                        <i className="material-icons prefix green-check">speaker_notes</i>
                                        <textarea id="register-notes" className="materialize-textarea login-color" defaultValue={""}></textarea>
                                        <label htmlFor="register-notes">Notes</label>
                                    </div>
                                </div>
                            </div>

                            <div className="login-btn-div">
                                <button className="btn waves-effect waves-light btn-large green login-btn" type="submit" name="action">Register
                            <i className="material-icons right">send</i>
                                </button>
                                <a id="close-modal-register" className="hidden modal-close waves-effect waves-green btn-flat"></a>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col l3 m3 s4">
                        <div className="input-field">
                            <i className="material-icons prefix">person</i>
                            <select onChange={this.updateSortFilter}>
                                <option defaultValue="1">Partner</option>
                                <option defaultValue="2">Legal</option>
                                <option defaultValue="3">Loan Admin</option>
                            </select>
                            <label>Account Type</label>
                        </div>
                    </div>
                    <div className="col l5 m3 s5">
                        <div className="user-search-div">
                            <div className="input-field">
                                <i className="material-icons prefix">search</i>
                                <input onKeyUp={this.searchUsers} className="login-color" id="register-search-users" type="text" className="login-color" required />
                                <label htmlFor="register-search-users">Search..</label>
                            </div>
                        </div>
                    </div>
                    <div className="col l1 m2 s2">
                        <div className="admin-user-add-div">
                            <button id="register-toggle" data-target="modal1" className="waves-effect waves-light btn btn-large modal-trigger green z-depth-3 admin-add-user-padding"><i className="material-icons prefix">person_add</i></button>
                        </div>
                    </div>
                    <div className="col l3 m4 s6 hide-on-small-only">
                        <div className="admin-user-add-div">
                            <button data-target="modal3" className="waves-effect waves-light btn btn-large modal-trigger teal lighten-1 z-depth-3 admin-add-user-padding users-total-remaining-btn"><i className="material-icons prefix left">network_check</i>${remainingTotal}</button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {users}
                </div>
                <br />
                <br />
                <div className="row row-no-margin">
                    <ul className="col l10 m10 s9 pagination users-page-select">
                        <li className="waves-effect"><a href="javascript:" onClick={this.prevPage}><i className="material-icons">chevron_left</i></a></li>
                        {pages}
                        <li className="waves-effect"><a href="javascript:" onClick={this.nextPage}><i className="material-icons">chevron_right</i></a></li>
                    </ul>
                    <div className="input-field col l1 m2 s3">
                        <select defaultValue={this.pageLimit} onChange={this.setPageAmount}>
                            <option value="6">6</option>
                            <option value="12">12</option>
                            <option value="18">18</option>
                            <option value="24">24</option>
                            <option value="30">30</option>
                        </select>
                        <label>Per Page</label>
                    </div>
                </div>
            </div>
        );
    }
}

export default ManageUsers;