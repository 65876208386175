import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min.js';
import { Redirect } from 'react-router-dom';
import './App.css';

import io from 'socket.io-client';

import Navbar from './components/navbar/navbar'
import Login from './components/login/login'
import AdminPanel from './components/adminpanel/adminpanel'
import Home from './components/home/home'
import Deal from './components/deal/deal'
import MyDeals from './components/mydeals/mydeals'
import Settings from './components/settings/settings'
import Chat from './components/chat/chat'
import Loading from './components/loading/loading'
import NotFound from './components/notfound/notfound'
import Disclaimer from './components/disclaimer/disclaimer';
import Documents from './components/documents/documents';
import NonPartners from './components/nonpartners/home';
import CreateSignature from './components/createsignature/createsignature';

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      route: "https://partners.homeownershipsolutions.org/api",
      socket: "https://partners.homeownershipsolutions.org",
      signatureRoute: "https://partners.homeownershipsolutions.org/signatures",
      pdfRoute: "https://partners.homeownershipsolutions.org/api/pdf",
      // route: "http://localhost:3002/api",
      // socket: "http://localhost:3002",
      // pdfRoute: "http://localhost:3002/api/pdf",
      isAuthed: false,
      data: {},
      loaded: false
    }

    this.changeAuth = this.changeAuth.bind(this);
    this.logout = this.logout.bind(this);
  }

  changeAuth(res) {
    if (res.isAuthed) {
      M.toast({ html: `Welcome ${res.data.name}!`, classes: 'rounded green' });
      //document.getElementById('homeBtn').click();
      this.setState(res);
    } else {
      this.setState(res);
      M.toast({ html: `Incorrect Login!`, classes: 'rounded red' });
    }
  }

  logout() {
    document.cookie = "id=";
    this.setState({ isAuthed: false });
    if (this.socket !== null && this.state.data.role === 9) {
      this.socket.emit("adminLogout");
    }
  }

  componentDidMount() {
    fetch(this.state.route + `/checksession`, {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
      credentials: 'include'
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        this.setState({ loaded: true });
        this.setState(res);
      })
      .catch((err) => {
        console.log(err);
      })

    //Materialize Modals remove scrolling when close (Current Bug) 
    //Temp fix
    setInterval(() => {
      document.body.style.overflow = "visible";
    }, 5000)
  }

  render() {

    if (!this.state.loaded) {
      return (
        <BrowserRouter>
          <div>
            <Navbar />
            <Loading />
          </div>
        </BrowserRouter>
      )
    }

    if (!this.state.isAuthed) {
      return (
        <BrowserRouter>
          <div>
            <Navbar />
            <Route path="/" render={() => <Login route={this.state.route} changeAuth={this.changeAuth} />} />
          </div>
        </BrowserRouter>
      )
    } else {
      if (this.state.data.setup) { //First time login, setup account.
        return (
          <BrowserRouter>
            <div>
              <Route path="/" render={() => <Navbar isLoggedIn={this.state.isAuthed} logout={this.logout} role={this.state.data.role} name={this.state.data.name} notifications={this.state.data.notifications} route={this.state.route} />} />
              <Route path="/" render={() => <Settings logout={this.logout} isNewUser={true} route={this.state.route} role={this.state.data.role} name={this.state.data.name} reload={true} />} />
            </div>
          </BrowserRouter>
        )
      } else {

        this.socket = io(this.state.socket);
        return (
          <BrowserRouter>
            {/* <Chat socket={this.socket} name={this.state.data.name} role={this.state.data.role} /> */}
            <Route path="/" render={() => <Navbar isLoggedIn={this.state.isAuthed} logout={this.logout} role={this.state.data.role} name={this.state.data.name} notifications={this.state.data.notifications} route={this.state.route} />} />
            <div>
              <Switch>
                <Route path="/AdminPanel" render={() => <AdminPanel route={this.state.route} pdfRoute={this.state.pdfRoute} role={this.state.data.role} socket={this.socket} />} exact />
                <Route path="/Home" render={() => <Home route={this.state.route} role={this.state.data.role} />} exact />
                <Route path="/ViewDeal" render={() => <Deal route={this.state.route} role={this.state.data.role} pdfRoute={this.state.pdfRoute} />} exact />
                <Route path="/MyDeals" render={() => <MyDeals route={this.state.route} role={this.state.data.role} />} exact />
                <Route path="/Settings" render={() => <Settings route={this.state.route} role={this.state.data.role} name={this.state.data.name} />} exact />
                <Route path="/Documents" render={() => <Documents route={this.state.route} role={this.state.data.role} hasSig={this.state.data.hasSig} link={this.state.redirect} userId={this.state.data.id} sigRoute={this.state.signatureRoute} />} exact />
                <Route path="/Disclaimer" render={() => <Disclaimer />} exact />
                <Route path="/DealView" render={() => <NonPartners route={this.state.route} role={this.state.data.role} />} exact />
                <Route path="/CreateSignature" render={() => <CreateSignature route={this.state.route} />} exact />
                <Route path="/" render={() => <Redirect to="/Home" />} exact />
                <Route component={NotFound} />
              </Switch>
            </div>
          </BrowserRouter>
        )
      }
    }
  }
}

export default App;
