import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './managechats.css';


import Loading from '../../../loading/loading'

class Managechats extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
            allChats: []
        }

        this.currentChat = "";

        this.viewChat = this.viewChat.bind(this);
        this.deleteChat = this.deleteChat.bind(this);
        this.allSockets = this.allSockets.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
    }

    allSockets() {
        this.props.socket.on('chatUpdated', (data) => {
            let exists = false;
            for (var i in this.state.allChats) {
                let chat = this.state.allChats[i];
                if (chat.clientId === data.id) {
                    chat.messages = data.msg;
                    exists = true;
                    if (data.id === this.currentChat) { //is equal to current chat being viewed (live updates)
                        let messages = chat.messages;
                        if (typeof chat.messages == 'string') {
                            messages = JSON.parse(chat.messages)
                        }
                        let html = "";
                        for (var t in messages) {
                            html += `<div className="chat-msg" style="text-align:center;background-color: rgba(0,0,0,.2);padding: 0.2em 0 0.2em 0;word-wrap: break-word; width: 95%; margin: 0.5em auto;border-radius:5px;">
                            <a className="center">${messages[t].name}</a>
                            <p className="word-wrap">${messages[t].msg}</p>
                        </div>`;
                        }
                        document.getElementById('chat-modal-msg-div').innerHTML = html;

                        let div = document.getElementById('chat-modal-textarea');
                        div.scrollTop = div.scrollHeight;
                    } else {
                        chat.updated = true;
                    }
                    break;
                }
            }
            if (!exists) {
                data.updated = true;
                data.clientId = data.id;
                this.state.allChats.push(data);
            }

            this.setState({ loaded: true, allChats: this.state.allChats });
        });
    }

    componentDidMount() {
        fetch(this.props.route + `/getchats`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.setState({ loaded: true, allChats: res });
                this.allSockets();
                M.Modal.init(document.querySelectorAll('.modal'), {
                    onCloseEnd: () => {
                        this.currentChat = "";
                    }
                });
            })
            .catch((err) => {
                M.toast({ html: `Error!`, classes: 'rounded red' });
            })
    }

    viewChat(id, isNew) {
        //If chat has a notificationn, remove it when viewing
        if (isNew) {
            let data = {};
            data['chatId'] = id;

            fetch(this.props.route + `/viewchat`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)

            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    for (var i in this.state.allChats) {
                        let chat = this.state.allChats[i];
                        if (chat.clientId === id) {
                            chat.updated = false;
                            chat.needsAttention = 0;
                            break;
                        }
                    }

                    this.setState({ loaded: true, allChats: this.state.allChats });

                })
                .catch((err) => {
                    M.toast({ html: `Error!`, classes: 'rounded red' });
                })
        }

        this.currentChat = id;
        for (var i in this.state.allChats) {
            let chat = this.state.allChats[i];
            let html = "";
            if (chat.clientId === id) {
                let messages = "";
                if (chat.msg != null) {
                    messages = chat.msg;
                }
                if (chat.messages != null) {
                    messages = chat.messages;
                }

                if (typeof messages == 'string') {
                    messages = JSON.parse(messages)
                }
                for (var t in messages) {
                    html += `<div className="chat-msg" style="text-align:center;background-color: rgba(0,0,0,.2);padding: 0.2em 0 0.2em 0;word-wrap: break-word; width: 95%; margin: 0.5em auto;border-radius:5px;">
                    <a className="center">${messages[t].name}</a>
                    <p className="word-wrap">${messages[t].msg}</p>
                </div>`;
                }
                document.getElementById('chat-modal-msg-div').innerHTML = html;

                setTimeout(() => {
                    let div = document.getElementById('chat-modal-textarea');
                    div.scrollTop = div.scrollHeight;
                }, 100)
                break;
            }
        }

        document.getElementById('chat-modal-show').click(); //show chat modal
    }

    deleteChat(id) {
        if (window.confirm("Delete Chat?")) {
            let data = {};
            data['chatId'] = id;

            fetch(this.props.route + `/deletechat`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)

            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    for (var i in this.state.allChats) {
                        let chat = this.state.allChats[i];
                        if (chat.clientId === id) {
                            this.state.allChats.splice(i, i + 1);
                            break;
                        }
                    }
                    this.setState({ loaded: true, allChats: this.state.allChats });

                })
                .catch((err) => {
                    M.toast({ html: `Error!`, classes: 'rounded red' });
                })
        }
    }

    sendMessage() {
        let message = document.getElementById('chat-modal-msg-input').value;
        if ((message === "" || message === null) || this.currentChat === "") {
            return;
        }
        this.props.socket.emit('sendMessageAdmin', { id: this.currentChat, name: "Home Ownership Solutions", msg: message });
        document.getElementById('chat-modal-msg-input').value = "";
    }

    componentWillUnmount() {
        this.props.socket.removeListener('chatUpdated');
    }

    handleEnter = (event) => {
        if (event.key === 'Enter') {
            this.sendMessage();
        }
    }

    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        let chats = this.state.allChats.map((data) => {
            let classStyle = "admin-deal-div z-depth-5";
            let isNew = false;
            if (data.updated || data.needsAttention === 1) {
                classStyle = "admin-deal-div z-depth-5 pulse green";
                isNew = true;
            }
            return (
                <div key={Math.random()} className="col l2 m4 s6">
                    <div className={classStyle}>

                        <div className="row">
                            <i className="login-color user-div-font-size">{data.clientName}</i>
                        </div>
                        <div className="row">
                            <div className="col l6 m6 s12">
                                <button onClick={() => { this.viewChat(data.clientId, isNew) }} className="waves-effect waves-light btn blue admin-action-btn">View</button>
                            </div>
                            <div className="col l6 m6 s12">
                                <button onClick={() => { this.deleteChat(data.clientId) }} className="waves-effect waves-light btn red admin-action-btn">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        return (
            <div>
                <a className="waves-effect waves-light btn modal-trigger hidden" id="chat-modal-show" href="#modal1">Modal</a>

                <div id="modal1" className="modal chat-modal-bg">
                    <div className="row">
                        <div className="modal-content col l12 m12 s12">
                            <div id="chat-modal-textarea" className="chat-modal-textarea">
                                <div id="chat-modal-msg-div" className="">

                                </div>
                            </div>
                            <div className="chat-modal-input-text">
                                <div className="text-area-input left">
                                    <input onKeyPress={this.handleEnter} placeholder="Type your message..." id="chat-modal-msg-input" type="text" className="chat-input-width"></input>
                                </div>
                                <button onClick={this.sendMessage} className="btn waves-effect waves-light btn-floating chat-send-btn chat-modal-send right">
                                    <i className="material-icons right">send</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <br /><br />
                    {chats}
                </div>
            </div>
        );
    }
}

export default Managechats;