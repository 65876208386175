import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './adminpanel.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import { Link, Redirect } from 'react-router-dom';

import ManageUsers from './options/manageusers/manageusers'
import ManageDeals from './options/managedeals/managedeals'
import ManageOldDeals from './options/manageolddeals/manageolddeals'
import ManageChats from './options/managechats/managechats'
import CreateDeal from './options/createdeal/createdeal'
import ViewUser from './options/viewuser/viewuser'
import ViewDeal from './options/viewdeal/viewdeal'


class AdminPanel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            body: ""
        }

        this.manageDeals = this.manageDeals.bind(this);
        this.manageOldDeals = this.manageOldDeals.bind(this);
        this.manageUsers = this.manageUsers.bind(this);
        this.manageChats = this.manageChats.bind(this);
        this.createDeal = this.createDeal.bind(this);
        this.setCurrentOption = this.setCurrentOption.bind(this);
        this.viewUser = this.viewUser.bind(this);
        this.viewDeal = this.viewDeal.bind(this);
    }

    componentDidMount() {
        this.props.socket.on('newMessage', () => { //Notify admin on new message
            M.toast({ html: `New Message!`, classes: 'rounded green' });
            if (this.state.body != "ManageChats") {
                let btn = document.getElementById('admin-messages-btn');
                btn.classList.add('pulse');
                btn.classList.add('green');
            }
        });

        fetch(this.props.route + `/getchats`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                for (var i in res) {
                    let chat = res[i];
                    if (chat.needsAttention == 1) {
                        let btn = document.getElementById('admin-messages-btn');
                        btn.classList.add('pulse');
                        btn.classList.add('green');
                        break;
                    }
                }
            })
            .catch((err) => {
                M.toast({ html: `Error!`, classes: 'rounded red' });
            })

        this.manageDeals();
        this.setCurrentOption();
    }

    componentWillUnmount() {
        this.props.socket.removeListener('newMessage');
    }

    manageUsers() {
        this.setState({ body: "ManageUsers" });
    }

    manageChats() {
        let btn = document.getElementById('admin-messages-btn');
        btn.classList.remove('pulse');
        btn.classList.remove('green');
        this.setState({ body: "ManageChats" });
    }

    manageDeals() {
        this.setState({ body: "ManageDeals" });
    }

    manageOldDeals() {
        this.setState({ body: "ManageOldDeals" });
    }

    createDeal(id, broker, clientCode, address, amount, creditScore, fee, homePrice, filogix, bureau, appraisal, commitment, other, city, lastName, ltv, firstMortgage, firstOrSecond, appraisedValue, finalScore) {
        this.setState({
            body: "CreateDeal", data: {
                id: id,
                broker: broker,
                clientCode: clientCode,
                address: address,
                amount: amount,
                homePrice: homePrice,
                creditScore: creditScore,
                fee: fee,
                filogix: filogix,
                bureau: bureau,
                appraisal: appraisal,
                commitment: commitment,
                other: other,
                city: city,
                lastName: lastName,
                ltv: ltv,
                firstMortgage: firstMortgage,
                firstOrSecond: firstOrSecond,
                appraisedValue: appraisedValue,
                finalScore: finalScore
            }
        });
    }

    viewUser(id) {
        this.setState({ body: "ViewUser", data: id });
    }

    viewDeal(id) {
        this.setState({ body: "ViewDeal", data: id });
        document.body.style.overflow = "visible";
    }


    setCurrentOption() {
        switch (this.state.body) {
            case "ManageUsers":
                return <ManageUsers route={this.props.route} viewUser={this.viewUser} />;
            case "ManageChats":
                return <ManageChats route={this.props.route} socket={this.props.socket} />;
            case "ManageDeals":
                return <ManageDeals route={this.props.route} viewDeal={this.viewDeal} />;
            case "ManageOldDeals":
                return <ManageOldDeals route={this.props.route} createDeal={this.createDeal} />;
            case "CreateDeal":
                return <CreateDeal route={this.props.route} data={this.state.data} manageDeals={this.manageDeals} />;
            case "ViewUser":
                return <ViewUser route={this.props.route} data={this.state.data} />;
            case "ViewDeal":
                return <ViewDeal route={this.props.route} data={this.state.data} pdfRoute={this.props.pdfRoute} />;
        }

        return "";
    }


    render() {
        if (this.props.role != "9") {
            return <Redirect push to="/Home" />;
        }
        return (
            <div className="admin-panel-div">
                <div className="admin-options-div row">
                    <div className="col l3 m3 s3">
                        <div className="waves-effect waves-light admin-user-options z-depth-1" onClick={this.manageOldDeals}><a className="admin-text-color hide-on-small-only" >New Deals <i className="material-icons prefix right">folder</i></a><a className="hide-on-med-and-up black-text"><i className="material-icons prefix">folder</i></a></div>
                    </div>
                    <div className="col l3 m3 s3">
                        <div className="waves-effect waves-light admin-user-options z-depth-1" onClick={this.manageDeals}><a className="admin-text-color hide-on-small-only" >All Deals <i className="material-icons prefix right">folder</i></a><a className="hide-on-med-and-up black-text"><i className="material-icons prefix">folder</i></a></div>
                    </div>
                    <div className="col l3 m3 s3">
                        <div className="waves-effect waves-light admin-user-options z-depth-1" onClick={this.manageUsers}><a className="admin-text-color hide-on-small-only" >Partners <i className="material-icons prefix right">account_box</i></a><a className="hide-on-med-and-up black-text"><i className="material-icons prefix">account_box</i></a></div>
                    </div>
                    <div className="col l3 m3 s3">
                        <div id="admin-messages-btn" className="waves-effect waves-light admin-user-options z-depth-1" onClick={this.manageChats}><a className="admin-text-color hide-on-small-only" >Messages <i className="material-icons prefix right">chat</i></a><a className="hide-on-med-and-up black-text"><i className="material-icons prefix">chat</i></a></div>
                    </div>
                </div>
                <div id="admin-body" className="admin-body row">
                    {this.setCurrentOption()}
                </div>
            </div >
        );
    }
}

export default AdminPanel;