import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './managedeals.css';
import GoogleMapReact from 'google-map-react';

import Loading from '../../../loading/loading'

import Cookies from 'js-cookie';

class Managedeals extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            searchNew: null,
            filter: "pending",
            loaded: false,
            page: 1
        }

        this.pageLimit = 12;

        this.searchNewDeals = this.searchNewDeals.bind(this);
        this.deleteDeal = this.deleteDeal.bind(this);
        this.changeFilter = this.changeFilter.bind(this);
        this.changePage = this.changePage.bind(this);
        this.prevPage = this.prevPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.setPageAmount = this.setPageAmount.bind(this);
        this.reSort = this.reSort.bind(this);
    }

    componentDidMount() {

        //Fetch new deals
        fetch(this.props.route + `/grabdeals`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {

                let num = Cookies.get('perPage');
                if (num != "" && num != 0 && num != null) {
                    this.pageLimit = num;
                }

                let pending = [[]];
                let pendingCount = 0;
                let pendingPage = 0;

                let funded = [[]];
                let fundedPage = 0;
                let fundedCount = 0;

                let repaid = [[]];
                let repaidPage = 0;
                let repaidCount = 0;

                for (var i in res) {
                    switch (res[i].type) {
                        case "NA":
                            if (pendingCount >= this.pageLimit) {
                                pendingCount = 0;
                                pendingPage++;
                                pending[pendingPage] = new Array();
                            }
                            pending[pendingPage].push(res[i]);
                            pendingCount++;

                            break;
                        case "Funded":
                            if (fundedCount >= this.pageLimit) {
                                fundedCount = 0;
                                fundedPage++;
                                funded[fundedPage] = new Array();
                            }
                            funded[fundedPage].push(res[i]);
                            fundedCount++;
                            break;
                        case "Repaid":
                            if (repaidCount >= this.pageLimit) {
                                repaidCount = 0;
                                repaidPage++;
                                repaid[repaidPage] = new Array();
                            }
                            repaid[repaidPage].push(res[i]);
                            repaidCount++;
                            break;
                    }
                }

                this.setState({ data: res, pending: pending, funded: funded, repaid: repaid, loaded: true });

                var elems = document.querySelectorAll('select');
                M.FormSelect.init(elems);
                M.Tabs.init(document.getElementById('tabs'));
                var adduser = document.querySelectorAll('.modal');
                M.Modal.init(adduser);
            })
            .catch((err) => {
                console.log(err);
            })

    }

    deleteDeal(id, address) {
        if (window.confirm("Delete Deal?")) {
            fetch(this.props.route + `/deletedeal?id=${id}&address=${address}`, {
                method: 'GET',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include'
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    if (res) {
                        M.toast({ html: `Deal Deleted!`, classes: 'rounded green' });
                        this.componentDidMount();
                    } else {
                        M.toast({ html: `Failed To Delete!`, classes: 'rounded red' });
                    }
                })
                .catch((err) => {
                    M.toast({ html: `Failed To Delete!`, classes: 'rounded red' });
                    console.log(err);
                })
        }
    }

    moneyFormat(x) {
        if (x == null) {
            return;
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    searchNewDeals() {
        let word = document.getElementById('search-for-newDeal').value;
        this.setState({ searchNew: word });
    }

    changeFilter(type) {
        this.setState({ filter: type, page: 1 });
    }

    hoverOn(id) {
        document.getElementById(id).classList.remove("hidden");
        document.getElementById("first" + id).classList.add("hidden");
    }

    hoverOff(id) {
        document.getElementById("first" + id).classList.remove("hidden");
        document.getElementById(id).classList.add("hidden");
    }

    changePage(pageNum) {
        this.setState({ page: pageNum });
    }

    nextPage() {
        let page = this.state.page + 1;
        let max;
        switch (this.state.filter) {
            case "pending":
                max = this.state.pending.length;
                break;
            case "funded":
                max = this.state.funded.length;
                break;
            case "repaid":
                max = this.state.repaid.length;
                break;
        }
        if (page <= max) {
            this.setState({ page: page });
        }
    }
    prevPage() {
        let page = this.state.page - 1;
        if (page >= 1) {
            this.setState({ page: page });
        }
    }

    setPageAmount(event) {
        Cookies.set('perPage', event.target.value);
        this.pageLimit = parseInt(event.target.value);
        this.reSort(this.state.data);
    }

    reSort(data) {

        let pending = [[]];
        let pendingCount = 0;
        let pendingPage = 0;

        let funded = [[]];
        let fundedPage = 0;
        let fundedCount = 0;

        let repaid = [[]];
        let repaidPage = 0;
        let repaidCount = 0;

        for (var i in data) {
            switch (data[i].type) {
                case "NA":
                    if (pendingCount >= this.pageLimit) {
                        pendingCount = 0;
                        pendingPage++;
                        pending[pendingPage] = new Array();
                    }
                    pending[pendingPage].push(data[i]);
                    pendingCount++;

                    break;
                case "Funded":
                    if (fundedCount >= this.pageLimit) {
                        fundedCount = 0;
                        fundedPage++;
                        funded[fundedPage] = new Array();
                    }
                    funded[fundedPage].push(data[i]);
                    fundedCount++;
                    break;
                case "Repaid":
                    if (repaidCount >= this.pageLimit) {
                        repaidCount = 0;
                        repaidPage++;
                        repaid[repaidPage] = new Array();
                    }
                    repaid[repaidPage].push(data[i]);
                    repaidCount++;
                    break;
            }
        }

        this.setState({ pending: pending, funded: funded, repaid: repaid, page: 1 });
    }

    render() {

        if (!this.state.loaded) {
            return <Loading />
        }


        let activeDeals;
        let deals;
        let pages;

        if (this.state.data != null) {

            if (this.state.data != null) {

                if (this.state.searchNew != null && this.state.searchNew != "") {
                    deals = this.state.data.map((data) => {
                        if (data.lastName.toUpperCase().includes(this.state.searchNew.toUpperCase())) {
                            if (this.state.filter == "pending" && data.type != "NA") {
                                return null;
                            } else if (this.state.filter == "funded" && data.type != "Funded") {
                                return null;
                            } else if (this.state.filter == "repaid" && data.type != "Repaid") {
                                return null;
                            }
                            return (
                                <div key={Math.random()} className="col l2 m4 s6">
                                    <div className="admin-deal-div z-depth-5">
                                        <div className="row">
                                            <a className="login-color user-div-font-size col l12 s12 m12">{data.lastName}</a>
                                        </div>
                                        <div className="row">
                                            <div className="col l6 m6 s12">
                                                <button onClick={() => { this.props.viewDeal(data.id) }} className="waves-effect waves-light btn blue admin-action-btn">View</button>
                                            </div>
                                            <div className="col l6 m6 s12">
                                                <button onClick={() => { this.deleteDeal(data.id, data.address) }} className="waves-effect waves-light admin-action-btn btn red">Delete</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    });
                } else {

                    activeDeals = this.state.data.map((data) => {
                        if (data.needsAttention != "1") {
                            return;
                        }
                        return (
                            <div key={Math.random()} className="col l2 m4 s6">
                                <div className="admin-deal-div z-depth-5 green pulse">
                                    <div className="row">
                                        <a className="login-color user-div-font-size col l12 s12 m12">{data.lastName}</a>
                                    </div>
                                    <div className="row">
                                        <div className="col l6 m6 s12">
                                            <button onClick={() => { this.props.viewDeal(data.id) }} className="waves-effect waves-light btn blue admin-action-btn">View</button>
                                        </div>
                                        <div className="col l6 m6 s12">
                                            <button onClick={() => { this.deleteDeal(data.id, data.address) }} className="waves-effect waves-light admin-action-btn btn red">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    });


                    switch (this.state.filter) {
                        case "pending":
                            pages = this.state.pending.map((data, index) => { //Page one is 0 index in array.
                                let ind = index + 1;
                                if (ind == this.state.page) { //Set active to current page number.
                                    return (
                                        <li key={Math.random()} className="waves-effect active pagination-active"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                    );
                                } else {

                                    return (
                                        <li key={Math.random()} className="waves-effect"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                    );
                                }
                            });
                            deals = this.state.pending[this.state.page - 1].map((data, index) => {
                                return (
                                    <div key={Math.random()} className="col l2 m4 s6">
                                        <div className="admin-deal-div z-depth-5">
                                            <div className="row">
                                                <a className="login-color user-div-font-size col l12 s12 m12">{data.lastName}</a>
                                            </div>
                                            <div className="row">
                                                <div className="col l6 m6 s12">
                                                    <button onClick={() => { this.props.viewDeal(data.id) }} className="waves-effect waves-light btn blue admin-action-btn">View</button>
                                                </div>
                                                <div className="col l6 m6 s12">
                                                    <button onClick={() => { this.deleteDeal(data.id, data.address) }} className="waves-effect waves-light admin-action-btn btn red">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );

                            });
                            break;
                        case "funded":
                            pages = this.state.funded.map((data, index) => { //Page one is 0 index in array.
                                let ind = index + 1;
                                if (ind == this.state.page) { //Set active to current page number.
                                    return (
                                        <li key={Math.random()} className="waves-effect active pagination-active"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                    );
                                } else {

                                    return (
                                        <li key={Math.random()} className="waves-effect"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                    );
                                }
                            });
                            deals = this.state.funded[this.state.page - 1].map((data, index) => {
                                return (
                                    <div key={Math.random()} className="col l2 m4 s6">
                                        <div className="admin-deal-div z-depth-5">
                                            <div className="row">
                                                <a className="login-color user-div-font-size col l12 s12 m12">{data.lastName}</a>
                                            </div>
                                            <div className="row">
                                                <div className="col l6 m6 s12">
                                                    <button onClick={() => { this.props.viewDeal(data.id) }} className="waves-effect waves-light btn blue admin-action-btn">View</button>
                                                </div>
                                                <div className="col l6 m6 s12">
                                                    <button onClick={() => { this.deleteDeal(data.id, data.address) }} className="waves-effect waves-light admin-action-btn btn red">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );

                            });
                            break;
                        case "repaid":
                            pages = this.state.repaid.map((data, index) => { //Page one is 0 index in array.
                                let ind = index + 1;
                                if (ind == this.state.page) { //Set active to current page number.
                                    return (
                                        <li key={Math.random()} className="waves-effect active pagination-active"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                    );
                                } else {

                                    return (
                                        <li key={Math.random()} className="waves-effect"><a onClick={() => { this.changePage(ind) }} href="javascript:">{ind}</a></li>
                                    );
                                }
                            });
                            deals = this.state.repaid[this.state.page - 1].map((data, index) => {
                                return (
                                    <div key={Math.random()} className="col l2 m4 s6">
                                        <div className="admin-deal-div z-depth-5">
                                            <div className="row">
                                                <a className="login-color user-div-font-size col l12 s12 m12">{data.lastName}</a>
                                            </div>
                                            <div className="row">
                                                <div className="col l6 m6 s12">
                                                    <button onClick={() => { this.props.viewDeal(data.id) }} className="waves-effect waves-light btn blue admin-action-btn">View</button>
                                                </div>
                                                <div className="col l6 m6 s12">
                                                    <button onClick={() => { this.deleteDeal(data.id, data.address) }} className="waves-effect waves-light admin-action-btn btn red">Delete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );

                            });
                            break;
                    }
                }
            }
        }

        const MarkerComponent = ({ text, color, id, eleId, name, amount, type }) => (
            <div onMouseEnter={() => { this.hoverOn(id) }} onMouseLeave={() => { this.hoverOff(id) }}>
                <div id={eleId} style={{
                    color: 'white',
                    background: color,
                    padding: '5px 5px',
                    display: 'inline-flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50px',
                    transform: 'translate(-50%, -50%)'
                }}>
                    {text}
                </div>
                <div id={id} className="expandable-map-options hidden z-depth-5">
                    <br />
                    <p className="maps-view-text"><b>{name}</b></p>
                    <p className="maps-view-text"><b>${amount}</b></p>
                    <p className="maps-view-text"><b>{type}</b></p>

                    <button onClick={() => { this.props.viewDeal(id) }} className="waves-effect waves-light btn green z-depth-3 admin-add-user-padding maps-view-btn"><i className="material-icons prefix right">send</i>View</button>
                </div>
            </div>
        );

        const markers = this.state.data.map((data) => {
            if (data.type == "NA" && this.state.filter == "pending") {
                let color = "#ef6c00";
                if (data.type == "Funded") {
                    color = "#388e3c"
                } else if (data.type == "Repaid") {
                    color = "#1e88e5";
                }
                return (
                    <MarkerComponent
                        key={Math.random()}
                        lat={data.lat}
                        lng={data.lng}
                        text={data.lastName}
                        color={color}
                        name={data.lastName}
                        amount={this.moneyFormat(data.amount)}
                        id={data.id}
                        type={"Pending"}
                        eleId={"first" + data.id}
                    />
                );
            } else if (data.type == "Repaid" && this.state.filter == "repaid") {
                let color = "#ef6c00";
                if (data.type == "Funded") {
                    color = "#388e3c"
                } else if (data.type == "Repaid") {
                    color = "#1e88e5";
                }
                return (
                    <MarkerComponent
                        key={Math.random()}
                        lat={data.lat}
                        lng={data.lng}
                        text={data.lastName}
                        name={data.lastName}
                        amount={this.moneyFormat(data.amount)}
                        type={"Repaid"}
                        color={color}
                        id={data.id}
                        eleId={"first" + data.id}
                    />
                );
            } else if (data.type == "Funded" && this.state.filter == "funded") {
                let color = "#ef6c00";
                if (data.type == "Funded") {
                    color = "#388e3c"
                } else if (data.type == "Repaid") {
                    color = "#1e88e5";
                }
                return (
                    <MarkerComponent
                        key={Math.random()}
                        lat={data.lat}
                        lng={data.lng}
                        name={data.lastName}
                        amount={this.moneyFormat(data.amount)}
                        text={data.lastName}
                        type={"Funded"}
                        color={color}
                        id={data.id}
                        eleId={"first" + data.id}
                    />
                );
            }

        })

        return (
            <div>
                <div id="modal1" className="modal modal-large modal-background-color" style={{ width: "90%", height: "80%" }}>
                    <div className="modal-content">
                        <button data-target="modal1" className="btn modal-close waves-effect waves-light red right">X</button><br /><br />
                        <div style={{ height: '65vh', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: "AIzaSyCbFfP2TbedHVAsTWLZuZBxJ6GqdRLl81k" }}
                                defaultCenter={{ lat: 43.5890, lng: -79.6441 }}
                                defaultZoom={8}
                            >
                                {markers}
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>

                <br />
                <div className="row">
                    <div className="col s12">
                        <ul id="tabs" className="tabs z-depth-5">
                            <li className="tab col s4 orange darken-3 "><a onClick={() => { this.changeFilter("pending") }} className="active white-text" href="javascript:"><b>Pending</b></a></li>
                            <li className="tab col s4 green darken-2"><a onClick={() => { this.changeFilter("funded") }} href="javascript:" className="white-text" ><b>Funded</b></a></li>
                            <li className="tab col s4 light-blue darken-4"><a onClick={() => { this.changeFilter("repaid") }} href="javascript:" className="white-text"><b>Repaid</b></a></li>
                        </ul>
                    </div>
                </div>
                <div className="row row-no-margin">
                    <div className="deal-search-div">
                        <div className="col l8 m10 s10">
                            <div className="input-field">
                                <i className="material-icons prefix">search</i>
                                <input onKeyUp={this.searchNewDeals} id="search-for-newDeal" type="text" className="login-color" required />
                                <label htmlFor="search-for-newDeal">Search For Deal...</label>
                            </div>
                        </div>
                    </div>
                    <div className="col l2 m2 s2 hide-on-small-only">
                        <div className="admin-user-add-div">
                            <button data-target="modal1" className="waves-effect waves-light btn btn-large modal-trigger green z-depth-3 admin-add-user-padding users-total-remaining-btn"><i className="material-icons prefix left">map</i>Map</button>
                        </div>
                    </div>
                </div>
                < div className="row" >
                    {activeDeals}
                </div >

                <div className="row">
                    {deals}
                </div>
                <br />
                <br />
                <div className="row row-no-margin">
                    <ul className="col l10 m10 s9 pagination users-page-select">
                        <li className="waves-effect"><a href="javascript:" onClick={this.prevPage}><i className="material-icons">chevron_left</i></a></li>
                        {pages}
                        <li className="waves-effect"><a href="javascript:" onClick={this.nextPage}><i className="material-icons">chevron_right</i></a></li>
                    </ul>
                    <div className="input-field col l1 m2 s3">
                        <select defaultValue={this.pageLimit} onChange={this.setPageAmount}>
                            <option value="6">6</option>
                            <option value="12">12</option>
                            <option value="18">18</option>
                            <option value="24">24</option>
                            <option value="30">30</option>
                        </select>
                        <label>Per Page</label>
                    </div>
                </div>
            </div>
        );
    }
}

export default Managedeals;