import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './createdeal.css';
import { throws } from 'assert';

class CreateDeal extends Component {

    constructor(props) {
        super(props);

        //Deal id in old database
        this.oldId = null;
        this.broker = null;
        this.clientCode = null;
        this.city = null;
        this.lastName = null;

        //all users
        this.state = {

        }

        this.allowedUsers = [];

        //All pdf ids
        this.pdf = {
            filogixId: "",
            bureauId: "",
            appraisalId: "",
            commitmentId: "",
            otherId: ""
        }

        this.createDeal = this.createDeal.bind(this);
        this.changeAll = this.changeAll.bind(this);
    }

    componentDidMount() {
        var collapse = document.querySelectorAll('.collapsible');
        M.Collapsible.init(collapse);
    }

    componentWillMount() {
        fetch(this.props.route + `/grabusers`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.setState({ data: res.data });

                M.Datepicker.init(document.querySelectorAll('.datepicker'), { format: 'yyyy-mm-dd' });
            })
            .catch((err) => {
                console.log(err);
            })
    }


    setExpireTime(date, hours) {
        return new Date(date.getTime() + hours * 3600000);
    }

    createDeal() {
        let data = {};

        //Old id from other db
        data['oldId'] = this.oldId;

        //All Fields
        data["filogix"] = this.pdf.filogixId;
        data["bureau"] = this.pdf.bureauId;
        data["appraisal"] = this.pdf.appraisalId;
        data["commitment"] = this.pdf.commitmentId;
        data["other"] = this.pdf.otherId;

        data["address"] = document.getElementById('create-deal-address').value;
        data["dealAmount"] = document.getElementById('create-deal-amount').value;
        data["dealRate"] = document.getElementById('create-deal-rate').value;
        data["dealFee"] = document.getElementById('create-deal-fee').value;
        data["dealFeeAmount"] = document.getElementById('create-deal-fee-amount').value;

        data["propertyValue"] = document.getElementById('create-deal-property-value').value;
        data["loanToValue"] = document.getElementById('create-deal-ltv').value;

        data["creditScore"] = document.getElementById('create-deal-credit-score').value;

        data["retainings"] = document.getElementById('create-deal-retainings').value;
        data["offering"] = document.getElementById('create-deal-offering').value;
        data["increments"] = document.getElementById('create-deal-increments').value;
        data["minIncrement"] = document.getElementById('create-deal-incrementsMin').value;
        data['comments'] = document.getElementById('create-deal-comments').value;

        data["broker"] = this.broker;
        data['clientCode'] = this.clientCode;
        data['city'] = this.city;

        data['lastName'] = this.lastName;

        data["users"] = JSON.stringify(this.allowedUsers);

        let timeToAdd = document.getElementById('create-deal-expire').value;
        data["date"] = this.setExpireTime(new Date(), timeToAdd).toString();

        data['reminderDate'] = document.getElementById('reminder-date').value;
        data['dueDate'] = document.getElementById('final-due-date').value;
        data['maturityDate'] = document.getElementById('maturity-date').value;

        data['mortgageType'] = document.getElementById('create-deal-mortgage-type').value;

        data['mortgagePriority'] = document.getElementById('create-deal-priority-mortgage').value;

        data['finalScore'] = this.props.data.finalScore;

        if (data["retainings"] == "" || data["offering"] == "" || data["increments"] == "" || data["minIncrement"] == "" || data["dealRate"] == "" || data["dealFee"] == "") {
            M.toast({ html: `Fill In Deal Particulars!`, classes: 'rounded red' });
            return;
        } else if (data['reminderDate'] == "" || data['dueDate'] == "" || data['maturityDate'] == "") {
            M.toast({ html: `Please Include Dates!`, classes: 'rounded red' });
            return;
        }
        fetch(this.props.route + `/addDeal`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                M.toast({ html: `Deal Created!`, classes: 'rounded green' });
                this.props.manageDeals();
            })
            .catch((err) => {
                M.toast({ html: `Failed To Create!`, classes: 'rounded red' });
                console.log(err);
            })

    }

    //User checkbox
    changeViewableStatus(id) {
        for (var i in this.allowedUsers) {
            if (this.allowedUsers[i] == id) {
                this.allowedUsers.splice(i, 1);
                return;
            }
        }
        this.allowedUsers.push(id);
    }

    //Check all On/Off
    changeAll() {
        let s = document.getElementById('checkAll').checked;
        this.allowedUsers = [];

        for (var i in this.state.data) {
            var data = this.state.data[i];
            if (s) {
                document.getElementById(data.id).checked = true;
                this.allowedUsers.push(data.id);
            } else {
                document.getElementById(data.id).checked = false;
            }
        }
    }

    updateOfferings(dealAmount) {
        document.getElementById('offer-active').classList.add('active');
        document.getElementById('create-deal-offering').value = parseInt(dealAmount) - parseInt(document.getElementById('create-deal-retainings').value);
    }

    formatToPercent(amount) {
        return (amount * 100).toFixed(1);
    }

    moneyFormat(x) {
        if (x == null) {
            return;
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        let data = this.props.data;
        this.oldId = data.id;
        this.broker = data.broker;
        this.clientCode = data.clientCode;
        this.city = data.city;
        this.lastName = data.lastName;
        this.ltv = this.formatToPercent(data.ltv);
        this.firstOrSecond = data.firstOrSecond;

        //All PDF Data
        let filogix = {}, bureau = {}, appraisal = {}, commitment = {}, other = {};
        if (data.filogix != "" && data.filogix != null) {
            filogix[0] = "check";
            filogix[1] = "material-icons prefix green-check"
            this.pdf.filogixId = data.filogix;
        } else {
            filogix[0] = "clear";
            filogix[1] = "material-icons prefix red-cross"
        }
        if (data.bureau != "" && data.bureau != null) {
            bureau[0] = "check";
            bureau[1] = "material-icons prefix green-check"
            this.pdf.bureauId = data.bureau;
        } else {
            bureau[0] = "clear";
            bureau[1] = "material-icons prefix red-cross"
        }
        if (data.appraisal != "" && data.appraisal != null) {
            appraisal[0] = "check";
            appraisal[1] = "material-icons prefix green-check";
            this.pdf.appraisalId = data.appraisal;
        } else {
            appraisal[0] = "clear";
            appraisal[1] = "material-icons prefix red-cross"
        }
        if (data.commitment != "" && data.commitment != null) {
            commitment[0] = "check";
            commitment[1] = "material-icons prefix green-check";
            this.pdf.commitmentId = data.commitment;
        } else {
            commitment[0] = "clear";
            commitment[1] = "material-icons prefix red-cross"
        }
        if (data.other != "" && data.other != null) {
            other[0] = "check";
            other[1] = "material-icons prefix green-check";
            this.pdf.otherId = data.other;
        } else {
            other[0] = "clear";
            other[1] = "material-icons prefix red-cross"
        }

        let priorityMortgage = 0;
        if (data.firstOrSecond.toLowerCase() == "second") {
            priorityMortgage = parseInt(data.firstMortgage);
        }

        //Load all users
        let users;


        if (this.state.data != null) {
            users = this.state.data.map((data) => {
                return (
                    <tr key={Math.random()}>
                        <td> <p>
                            <label>
                                <input id={data.id} className="user-checkbox" onChange={() => { this.changeViewableStatus(data.id) }} type="checkbox" />
                                <span></span>
                            </label>
                        </p></td>
                        <td>{data.name}</td>
                        <td>${this.moneyFormat(data.capitalAvailable)}</td>
                    </tr>
                );

            });
        }

        return (
            <div>
                <div className="crete-deal-div row">
                    <br />
                    <div className="create-deal-btn col l12 m12 s12">
                        <button onClick={this.createDeal} className="btn btn-large green z-depth-5">Create Deal</button>
                    </div>
                    <div className="col l12 m12 s12">
                        <ul class="collapsible create-deal-dealinfo z-depth-5">
                            <li className="active">
                                <div class="collapsible-header"><i class="material-icons">filter_drama</i>Offer Summary</div>
                                <div class="collapsible-body">
                                    <div className="row">
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-check">person</i>
                                            <input id="create-deal-client-name" type="text" class="validate login-color" defaultValue={this.lastName} />
                                            <label htmlFor="create-client-name" className="active">Client Name</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-check">home</i>
                                            <input id="create-deal-address" type="text" class="validate login-color" defaultValue={data.address} />
                                            <label htmlFor="create-deal-address" className="active">Property Address</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-check">attach_money</i>
                                            <input id="create-deal-property-value" type="text" class="validate login-color" defaultValue={data.appraisedValue} />
                                            <label htmlFor="create-deal-property-value" className="active">Property Value</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-check">attach_money</i>
                                            <input id="create-deal-priority-mortgage" type="text" class="validate login-color" defaultValue={priorityMortgage} />
                                            <label htmlFor="create-deal-priority-mortgage" className="active">Priority Mortgage</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-check">attach_money</i>
                                            <input id="create-deal-amount" type="text" class="validate login-color" defaultValue={data.amount} />
                                            <label htmlFor="create-deal-amount" className="active">Total Deal Amount</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-check">format_list_numbered</i>
                                            <input id="create-deal-mortgage-type" type="text" class="validate login-color" defaultValue={this.firstOrSecond} />
                                            <label htmlFor="create-deal-mortgage-type" className="active">Mortgage Type</label>
                                        </div>
                                        <div class="input-field col l6 m6 s12">
                                            <i class="material-icons prefix green-check">border_horizontal</i>
                                            <input id="create-deal-ltv" type="text" class="validate login-color" defaultValue={this.ltv} />
                                            <label htmlFor="create-deal-ltv" className="active">Loan To Value</label>
                                        </div>
                                        <div class="input-field col l6 m6 s12">
                                            <i class="material-icons prefix green-check">account_balance</i>
                                            <input id="create-deal-credit-score" type="text" class="validate login-color" defaultValue={data.creditScore} />
                                            <label htmlFor="create-deal-credit-score" className="active">Credit Score</label>
                                        </div>
                                        <div class="input-field col l12 m12 s12">
                                            <i class="material-icons prefix green-check">keyboard</i>
                                            <textarea id="create-deal-comments" class="materialize-textarea login-color"></textarea>
                                            <label htmlFor="create-deal-comments">Comments</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header"><i class="material-icons">settings</i>Offer Particulars</div>
                                <div class="collapsible-body">
                                    <div className="row">
                                        <div class="input-field col l4 m4 s12">
                                            <i class="material-icons prefix green-check">local_offer</i>
                                            <input id="create-deal-rate" type="text" class="validate login-color" />
                                            <label htmlFor="create-deal-rate" >Deal Rate</label>
                                        </div>
                                        <div class="input-field col l4 m4 s12">
                                            <i class="material-icons prefix green-check">library_add</i>
                                            <input id="create-deal-fee-amount" type="text" class="validate login-color" />
                                            <label htmlFor="create-deal-fee-amount" >Fee Offered</label>
                                        </div>
                                        <div class="input-field col l4 m4 s12">
                                            <i class="material-icons prefix green-check">border_horizontal</i>
                                            <input id="create-deal-fee" type="text" class="validate login-color" />
                                            <label htmlFor="create-deal-fee" >Annualized Deal Fee</label>
                                        </div>
                                        <div class="input-field col l6 m6 s12">
                                            <i class="material-icons prefix green-check">attach_money</i>
                                            <input id="create-deal-retainings" onKeyUp={() => { this.updateOfferings(data.amount) }} type="text" class="validate login-color" />
                                            <label htmlFor="create-deal-retainings" >Home Ownership Participation</label>
                                        </div>
                                        <div class="input-field col l6 m6 s12">
                                            <i class="material-icons prefix green-check">attach_money</i>
                                            <input id="create-deal-offering" type="text" class="validate login-color" />
                                            <label htmlFor="create-deal-offering" id="offer-active" >Offering</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-check">dehaze</i>
                                            <input id="create-deal-increments" type="text" class="validate login-color" />
                                            <label htmlFor="create-deal-increments" >Increments Of</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix folder-color">filter_list</i>
                                            <input id="create-deal-incrementsMin" type="text" class="validate login-color" />
                                            <label htmlFor="create-deal-incrementsMin" >Minimum Investment</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-check">timer</i>
                                            <input id="create-deal-expire" type="text" class="validate login-color" defaultValue="1" />
                                            <label htmlFor="create-deal-expire" className="active">Deal Expiry Time (Hours)</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-icon">date_range</i>
                                            <input type="text" id="reminder-date" class="datepicker login-color" />
                                            <label htmlFor="reminder-date" >Reminder Funding Date</label>
                                        </div>
                                        <div class="input-field col l4 m6 s12">
                                            <i class="material-icons prefix green-icon">date_range</i>
                                            <input type="text" id="final-due-date" class="datepicker login-color" />
                                            <label htmlFor="final-due-date" >Final Funding Date</label>
                                        </div>
                                        <div class="input-field col l4 m12 s12">
                                            <i class="material-icons prefix green-icon">date_range</i>
                                            <input type="text" id="maturity-date" class="datepicker login-color" />
                                            <label htmlFor="maturity-date" >Maturity Date</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header"><i class="material-icons">folder</i>Offer Documents</div>
                                <div class="collapsible-body">
                                    <div className="row">
                                        <div className="col s6 m4 l4">
                                            <div className=" z-depth-5">
                                                <div class="card">
                                                    <div class="card-content create-deal-pdf-bg white-text">
                                                        <p class="card-stats-title"><i class="mdi-social-group-add"></i><i class="material-icons prefix folder-color">folder_open</i>&nbsp;Filogix PDF</p>
                                                        <h4 class="card-stats-number view-deal-small-txt "><i class={filogix[1]}>{filogix[0]}</i></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s6 m4 l4">
                                            <div className="z-depth-5">
                                                <div class="card">
                                                    <div class="card-content create-deal-pdf-bg white-text">
                                                        <p class="card-stats-title"><i class="mdi-social-group-add"></i><i class="material-icons prefix folder-color">folder_open</i>&nbsp;Bureau PDF</p>
                                                        <h4 class="card-stats-number view-deal-small-txt "><i class={bureau[1]}>{bureau[0]}</i></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s6 m4 l4">
                                            <div className="z-depth-5">
                                                <div class="card">
                                                    <div class="card-content create-deal-pdf-bg white-text">
                                                        <p class="card-stats-title"><i class="material-icons prefix folder-color">folder_open</i>&nbsp;Commitment PDF</p>
                                                        <h4 class="card-stats-number view-deal-small-txt "><i class={commitment[1]}>{commitment[0]}</i></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s6 m4 l4">
                                            <div className="z-depth-5">
                                                <div class="card">
                                                    <div class="card-content create-deal-pdf-bg white-text">
                                                        <p class="card-stats-title"><i class="mdi-social-group-add"></i><i class="material-icons prefix folder-color">folder_open</i>&nbsp;Appraisal PDF</p>
                                                        <h4 class="card-stats-number view-deal-small-txt "><i class={appraisal[1]}>{appraisal[0]}</i></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s6 m4 l4">
                                            <div className="z-depth-5">
                                                <div class="card">
                                                    <div class="card-content create-deal-pdf-bg white-text">
                                                        <p class="card-stats-title"><i class="mdi-social-group-add"></i><i class="material-icons prefix folder-color">folder_open</i>&nbsp;Other PDF</p>
                                                        <h4 class="card-stats-number view-deal-small-txt "><i class={other[1]}>{other[0]}</i></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col s6 m4 l4">
                                            <div className="z-depth-5">
                                                <div class="card">
                                                    <div class="card-content create-deal-pdf-bg white-text">
                                                        <p class="card-stats-title"><i class="mdi-social-group-add"></i><i class="material-icons prefix folder-color">folder_open</i>&nbsp;Other PDF</p>
                                                        <h4 class="card-stats-number view-deal-small-txt "><i class={other[1]}>{other[0]}</i></h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header"><i class="material-icons">account_circle</i>Select Partners</div>
                                <div class="collapsible-body">
                                    <div className="users-table">
                                        <table className="centered manage-deals-table">
                                            <thead>
                                                <tr className="manage-deals-headers">
                                                    <th><p>
                                                        <label>
                                                            <input id="checkAll" className="user-checkbox" onChange={this.changeAll} type="checkbox" />
                                                            <span></span>
                                                        </label>
                                                    </p></th>
                                                    <th>Name</th>
                                                    <th>Capital Available</th>
                                                </tr>
                                            </thead>

                                            <tbody id="deals-table">
                                                {users}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        );
    }
}

export default CreateDeal;