
import React, { Component } from 'react';
import '../../documents.css';
import Dropzone from 'react-dropzone'
import M from 'materialize-css/dist/js/materialize.min.js';

class Upload extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fileObj: null,
            user: null,
            file: null,
            fileName: "",
            userName: "",
            allUsers: [],
            notifications: []
        }

        this.uploadFile = this.uploadFile.bind(this);
        this.setNotifications = this.setNotifications.bind(this);
        this.checkAll = this.checkAll.bind(this);
        this.changeNotif = this.changeNotif.bind(this);
    }

    checkAll() {
        let isChecked = document.getElementById('checkAll').checked;
        this.state.notifications.map((data) => {
            data.active = isChecked;
            document.getElementById(data.id).checked = isChecked;
        });
    }

    changeNotif(id) {
        this.state.notifications.map((data) => {
            if (data.id == id) {
                let checked = document.getElementById(data.id).checked;
                data.active = checked;
            }
        });
    }

    componentDidMount() {


        M.Modal.init(document.querySelectorAll('.modal'));

        this.props.notifs.map((data) => {
            let type = "legal";
            if (data.role === 3) {
                type = "loanAdmin";
            }
            this.state.notifications.push({
                id: data.id,
                name: data.name,
                type: type,
                active: false
            });
        });

        this.props.entries.map((data) => {
            let type = "partner";
            this.state.notifications.push({
                id: data.userId,
                name: data.name,
                type: type,
                active: false
            });
        });

        if (this.props.user != null) {
            this.setState({
                user: this.props.user.id,
                userName: this.props.user.name,
                file: this.props.file.id,
                fileName: this.props.file.name,
            });
        }

    }

    setFile(file) {
        this.setState({ fileObj: file });
        document.getElementById('file-upload-check').classList.remove("hidden");
    }

    setNotifications() {
        if (this.state.fileObj === null) {
            M.toast({ html: `No file selected`, classes: 'rounded red' });
            return
        }
        document.getElementById('open-notifs').click();

    }

    uploadFile() {
        this.props.upload(this.state.fileObj, this.state.user, this.state.file, this.state.notifications, document.getElementById('sign-note').value);
    }


    render() {
        this.props.entries.map((data) => {
            this.state.allUsers.push(data.userId);
            return null;
        })

        let notifications = this.state.notifications.map((data) => {

            return (
                <tr key={Math.random()} className="mydeal-table-row" >
                    <td className="mydeals-table-font sign-table-row">{data.name}</td>
                    <td className="sign-table-row"><p><label><input id={data.id} className="user-checkbox" onChange={() => { this.changeNotif(data.id) }} type="checkbox" /> <span></span> </label></p></td>
                </tr>
            );
        });


        return (
            <div>
                <button id="open-notifs" className="hidden modal-trigger" data-target="modal2"></button>
                <div id="modal2" className="modal blue-grey darken-4">
                    <div className="modal-content">
                        <button onClick={this.uploadFile} className="modal-close waves-effect waves-green btn green right">Finish<i className="material-icons prefix right">check</i></button>
                        <h4 className="center white-text">Notify Users</h4>
                        <table className="centered manage-entries-table z-depth-5">
                            <thead>
                                <tr className="viewdeal-tabel-th">
                                    <th>Name</th>
                                    <th><p>
                                        <label>
                                            <input id="checkAll" className="user-checkbox" onChange={this.checkAll} type="checkbox" />
                                            <span></span>
                                        </label>
                                    </p></th>
                                </tr>
                            </thead>

                            <tbody id="deals-table" className="sign-notification-div">
                                {notifications}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row white-text">
                    <h5 ><i className="material-icons prefix left green-check">insert_drive_file</i>{this.state.fileName}</h5>
                    <h5 ><i className="material-icons prefix left green-check">person</i>{this.state.userName}</h5>
                    <br />
                    <div className="white-text">
                        <h5 className="center"><b>Click or drop a file below</b></h5>
                        <Dropzone onDrop={(acceptedFiles) => { this.setFile(acceptedFiles) }}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div className="drop-zone-div" {...getRootProps()}>
                                        <i id="file-upload-check" className="material-icons prefix green-text file-upload-check hidden">check</i>
                                        <input {...getInputProps()} />
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </div>
                    <div className="row col s12">
                        <textarea placeholder="Add a note" id="sign-note" class="materialize-textarea sign-note"></textarea>
                    </div>

                    <div className="row center">
                        <button onClick={this.setNotifications} className="btn btn-large green z-depth-3"><i className="material-icons prefix left">cloud_upload</i>Upload</button>
                    </div>
                    <div className="center">
                        <button onClick={this.props.back} className="btn btn-large red z-depth-3"><i className="material-icons prefix left">arrow_back</i>Back</button>
                    </div>
                </div>
            </div >

        );

    }
}

export default Upload;

