import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './settings.css';

class Settings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: []
        }

        this.disclaimerIsAccepted = false;

        this.saveSettings = this.saveSettings.bind(this);
        this.acceptDisclaimer = this.acceptDisclaimer.bind(this);
    }

    componentDidMount() {
        fetch(this.props.route + `/getsettings`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.setState({ data: res });

                //Fix for textarea
                document.getElementById('settings-notes').value = res.notes;
            })
            .catch((err) => {

                console.log(err);
            })
    }

    saveSettings() {

        let data = {};


        //Passwords match
        if (document.getElementById('settings-confPassword').value === document.getElementById('settings-password').value) {
            data["password"] = document.getElementById('settings-password').value;

        } else {
            M.toast({ html: `Passwords Dont Match!`, classes: 'rounded red' });
            return;

        }

        let capitalValue = parseInt(document.getElementById('settings-capital').value);

        if (capitalValue < 0 || capitalValue === "" || isNaN(capitalValue)) {
            M.toast({ html: `Please update Capital Commited!`, classes: 'rounded red' });
            return;
        }

        data["email"] = document.getElementById('settings-email').value;
        data["phone"] = document.getElementById('settings-phone').value;
        data["capital"] = document.getElementById('settings-capital').value;
        data['username'] = this.state.data.username;
        if (document.getElementById('settings-username') != null) {
            data["username"] = document.getElementById('settings-username').value;
        }
        data["company"] = document.getElementById('settings-company').value;
        data["notes"] = document.getElementById('settings-notes').value;
        data['disclaimerAccpeted'] = this.disclaimerIsAccepted;

        fetch(this.props.route + `/savesettings`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (!res.response) {
                    M.toast({ html: res.reason, classes: 'rounded red' });
                } else {
                    M.toast({ html: `Setting Changed!`, classes: 'rounded green' });
                    if (this.props.reload) {
                        document.location.reload();
                    } else {
                        document.getElementById('homeBtn').click();
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    acceptDisclaimer() {
        document.getElementById('settings-div').classList.remove('hidden');
        document.getElementById('disclaimer-div').classList.add('hidden');
        this.disclaimerIsAccepted = true;
    }

    render() {

        let data = this.state.data;

        let email = "";
        let phone = "";
        let capital = 0;
        let name = "";
        let username = ""
        let company = "";
        let headerMsg = "";
        let notes = "";
        let usernameChange;

        let phoneClass = "col l6 m6 s12"; //if new user phone is full length if not then half length

        let mainDivClass = 'all-settings-div';
        let disclaimer = 'disclaimer-div hidden';

        if (data != null) {
            email = data.email;
            phone = data.phone;
            capital = data.capital;
            name = data.name;
            username = data.username;
            company = data.company;
            notes = data.notes;

            //Can only change username on first login
            usernameChange = "";

            if (this.props.isNewUser) {
                phoneClass = "col l12 m12 s12"
                mainDivClass = 'all-settings-div hidden';
                disclaimer = 'disclaimer-div';

                headerMsg = "Please Setup Your Account!"
                usernameChange = <div className="col l6 m6 s12">
                    <div className="input-field settings-input-display">
                        <i className="material-icons prefix green-check">account_box</i>
                        <input id="settings-username" type="text" className="validate login-color" defaultValue={username} />
                        <label htmlFor="settings-username" className="active">Login Username</label>
                    </div>
                </div>
            }
        }

        return (
            <div>
                <br />
                <div id="disclaimer-div" className={disclaimer}>
                    <div className="disclaimer-header">
                        <h4 className="white-text"><i className="material-icons prefix settings-disclaimer-icon red-cross">warning</i>&nbsp;&nbsp;Disclaimer</h4>
                    </div>
                    <div className="disclaimer-text-div">
                        <p>
                            This platform is intended for information purposes only and does not create any legally binding obligations on the part of Home Ownership Solutions, Fourth Street Financial, its employees, members, associates and/or affiliates (“HOS”). Without limitation, this website does not constitute a recommendation to enter into any transaction.
                        </p>
                        <p>
                        This site does not encourage or recommend loan approvals and nothing contained herein, or result provided herewith shall be construed as such. All approvals require independent underwriting, analysis and due diligence which can only be conducted by you.
                        </p>
                        <p>
                            The transaction(s), product(s), or other service(s) mentioned herein may not be appropriate for all investors or users and before entering into any transaction you should take steps to ensure that you fully understand the transaction and have made an independent assessment of the appropriateness of the transaction in the light of your own objectives and circumstances, including the possible risks and benefits of entering into such transaction. You should also consider seeking advice from your own advisers in making this assessment. If you decide to enter into a business relationship with HOS, you do so in reliance on your own judgment.
                        </p>
                        <p>
                            The information contained in this website is based on material we believe to be reliable; however, we do not represent that it is accurate, current, complete, or error free. Assumptions, estimates and opinions contained in this website constitute our judgment as of the date of submission and are subject to change without notice. Any conclusions are based on a number of assumptions as to market conditions and there can be no guarantee that any particular results will be achieved. Past performance is not a guarantee of future results.
                        </p>
                        <p>
                            HOS uses a proprietary Risk Assessment algorithm herein which provides insight into HOS’s own perceived risk of the transaction. This tool provides an automated Low-to-Mid, Mid and Mid-to-High risk assessment which may be overridden by HOS from time to time due to factors affecting the risk not otherwise accounted for in the algorithm and that overridden assessment is what may appear herein. HOS is not endorsing or otherwise recommending that risk assessment to you. Your perceived risk of the transaction should be based on your own independent analysis including but not limited to, assumptions, due diligence, market reviews, property reviews, independent appraisals, counsel from your own advisors, etc.
                        </p>
                        <p>
                            The access to this website and availability of our products and services in certain jurisdictions may be restricted by law. You may not download/share contents from this website, in whole or in part, without our express written permission.
                        </p>
                        <p>
                            HOS SPECIFICALLY DISCLAIMS ALL LIABILITY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL OR OTHER LOSSES OR DAMAGES INCLUDING LOSS OF PROFITS INCURRED BY YOU OR ANY THIRD PARTY THAT MAY ARISE FROM ANY RELIANCE ON THE INFORMATION CONTAINED IN THE PLATFORM OR FOR THE RELIABILITY, ACCURACY, COMPLETENESS OR TIMELINESS THEREOF.
                        </p>
                    </div>
                    <div className="row">
                        <div className="col s12 disclaimer-btn-div">
                            <button onClick={this.acceptDisclaimer} className="btn waves-effect waves-light btn-large green z-depth-5 center" name="action">Accept</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={this.props.logout} className="btn waves-effect waves-light btn-large red z-depth-5" name="action">Decline</button>
                        </div>
                    </div>
                </div>
                <div id="settings-div" className={mainDivClass}>
                    <div className="settings-bg z-depth-5">
                        <div className="col l12 m12 s12">
                            <div className="settings-username-div">
                                <div className="settings-user-account-div z-depth-5 "></div>
                                <p className="settings-username">{name}</p>
                                <h5 className="login-color">{headerMsg}</h5>
                            </div>
                        </div>

                        <div className="row">
                            {usernameChange}
                            <div className="col l6 m6 s12">
                                <div className="input-field settings-input-display">
                                    <i className="material-icons prefix green-check">mail</i>
                                    <input id="settings-email" type="text" className="validate login-color" defaultValue={email} />
                                    <label htmlFor="settings-email" className="active">Email</label>
                                </div>
                            </div>
                            <div className="col l6 m6 s12">
                                <div className="input-field settings-input-display">
                                    <i className="material-icons prefix green-check">work</i>
                                    <input id="settings-company" type="text" className="validate login-color" defaultValue={company} />
                                    <label htmlFor="settings-company" className="active">Company</label>
                                </div>
                            </div>
                            <div className="col l6 m6 s12">
                                <div className="input-field settings-input-display">
                                    <i className="material-icons prefix green-check">attach_money</i>
                                    <input id="settings-capital" type="text" className="validate login-color" defaultValue={capital} />
                                    <label htmlFor="settings-capital" className="active">Capital Committed</label>
                                </div>
                            </div>
                            <div className={phoneClass}>
                                <div className="input-field settings-input-display">
                                    <i className="material-icons prefix green-check">phone</i>
                                    <input id="settings-phone" type="text" className="validate login-color" defaultValue={phone} />
                                    <label htmlFor="settings-phone" className="active">Phone</label>
                                </div>
                            </div>
                            <div className="col l6 m6 s12">
                                <div className="input-field settings-input-display">
                                    <i className="material-icons prefix green-check">lock</i>
                                    <input id="settings-password" type="password" className="validate login-color" />
                                    <label htmlFor="settings-password">New Password</label>
                                </div>
                            </div>
                            <div className="col l6 m6 s12">
                                <div className="input-field settings-input-display">
                                    <i className="material-icons prefix green-check">lock</i>
                                    <input id="settings-confPassword" type="password" className="validate login-color" />
                                    <label htmlFor="settings-confPassword">Confirm Password</label>
                                </div>
                            </div>
                            <div className="col l12 m12 s12">
                                <div className="input-field settings-input-display">
                                    <i className="material-icons prefix green-check">speaker_notes</i>
                                    <textarea id="settings-notes" className="materialize-textarea login-color" defaultValue={notes}></textarea>
                                    <label htmlFor="settings-notes" className="active">Notes</label>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <button onClick={this.saveSettings} className="btn waves-effect waves-light btn-large green z-depth-5" name="action">Save Settings
                            <i className="material-icons right">send</i>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Settings;