import React, { Component } from 'react';
import '../../documents.css';

class DealFiles extends Component {


    render() {
        return this.props.files.map((data) => {
            return (
                // <a key={Math.random()} onClick={canClick} href="javascript:" className="collection-item">{data.name}</a>
                <div onClick={() => { this.props.select(data.short) }} key={Math.random()} className="col s6 document-file-outer-div">
                    <div className="document-file-div blue-grey z-depth-2">
                        <p>{data.name}</p>
                    </div>
                </div>
            );

        })


    }
}

export default DealFiles;
