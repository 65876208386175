import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';

class Disclaimer extends Component {

    render() {

        //Component is Copied from settings

        let disclaimer = 'disclaimer-div';

        return (
            <div>
                <br />
                <div id="disclaimer-div" className={disclaimer}>
                    <div className="disclaimer-header">
                        <h4 className="white-text"><i className="material-icons prefix settings-disclaimer-icon red-cross">warning</i>&nbsp;&nbsp;Disclaimer</h4>
                    </div>
                    <div className="disclaimer-text-div">
                        <p>
                            This platform is intended for information purposes only and does not create any legally binding obligations on the part of Home Ownership Solutions, Fourth Street Financial, its employees, members, associates and/or affiliates (“HOS”). Without limitation, this website does not constitute a recommendation to enter into any transaction.
                        </p>
                        <p>
                        This site does not encourage or recommend loan approvals and nothing contained herein, or result provided herewith shall be construed as such. All approvals require independent underwriting, analysis and due diligence which can only be conducted by you.
                        </p>
                        <p>
                            The transaction(s), product(s), or other service(s) mentioned herein may not be appropriate for all investors or users and before entering into any transaction you should take steps to ensure that you fully understand the transaction and have made an independent assessment of the appropriateness of the transaction in the light of your own objectives and circumstances, including the possible risks and benefits of entering into such transaction. You should also consider seeking advice from your own advisers in making this assessment. If you decide to enter into a business relationship with HOS, you do so in reliance on your own judgment.
                        </p>
                        <p>
                            The information contained in this website is based on material we believe to be reliable; however, we do not represent that it is accurate, current, complete, or error free. Assumptions, estimates and opinions contained in this website constitute our judgment as of the date of submission and are subject to change without notice. Any conclusions are based on a number of assumptions as to market conditions and there can be no guarantee that any particular results will be achieved. Past performance is not a guarantee of future results.
                        </p>
                        <p>
                            HOS uses a proprietary Risk Assessment algorithm herein which provides insight into HOS’s own perceived risk of the transaction. This tool provides an automated Low-to-Mid, Mid and Mid-to-High risk assessment which may be overridden by HOS from time to time due to factors affecting the risk not otherwise accounted for in the algorithm and that overridden assessment is what may appear herein. HOS is not endorsing or otherwise recommending that risk assessment to you. Your perceived risk of the transaction should be based on your own independent analysis including but not limited to, assumptions, due diligence, market reviews, property reviews, independent appraisals, counsel from your own advisors, etc.
                        </p>
                        <p>
                            The access to this website and availability of our products and services in certain jurisdictions may be restricted by law. You may not download/share contents from this website, in whole or in part, without our express written permission.
                        </p>
                        <p>
                            HOS SPECIFICALLY DISCLAIMS ALL LIABILITY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL OR OTHER LOSSES OR DAMAGES INCLUDING LOSS OF PROFITS INCURRED BY YOU OR ANY THIRD PARTY THAT MAY ARISE FROM ANY RELIANCE ON THE INFORMATION CONTAINED IN THE PLATFORM OR FOR THE RELIABILITY, ACCURACY, COMPLETENESS OR TIMELINESS THEREOF.
                        </p>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}

export default Disclaimer;