import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import { Link } from 'react-router-dom';


class NotFound extends Component {

    render() {
        return(
            <div style={{width: '100%', textAlign: 'center'}}>
                <h1 className="white-text">Page Not Found!</h1> <br />
                <Link to="/Home" className="btn btn-large red">Go Back</Link>
            </div>
        );
    }
}

export default NotFound;