import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './manageolddeals.css';

import Loading from '../../../loading/loading'

class ManageOlddeals extends Component {

    constructor(props) {
        super(props);

        this.state = {
            oldData: null,
            searchOld: null,
            loaded: false
        }

        this.searchOldDeals = this.searchOldDeals.bind(this);
    }

    componentDidMount() {
        //Fetch old deals
        fetch(this.props.route + `/grabolddeals`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.setState({ oldData: res.data, loaded: true });
            })
            .catch((err) => {
                console.log(err);
            })
    }



    searchOldDeals() {
        let word = document.getElementById('register-search-olddeals').value;
        this.setState({ searchOld: word });
    }

    deleteOldDeal(dealId, address) {
        if (window.confirm("Remove Deal?")) {
            let data = {};
            data["dealId"] = dealId;
            data['address'] = address;

            fetch(this.props.route + `/deleteolddeal`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    M.toast({ html: `Removed Deal!`, classes: 'rounded green' });
                    this.componentDidMount();
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    moneyFormat(x) {
        if (x == null) {
            return;
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    render() {

        if (!this.state.loaded) {
            return <Loading />
        }

        //Show all other deal
        let oldDeals;
        if (this.state.oldData !== null) {
            if (this.state.searchOld !== null && this.state.searchOld !== "") {
                oldDeals = this.state.oldData.map((data) => {
                    if (data.clientCode.toUpperCase().includes(this.state.searchOld.toUpperCase()) || data.broker.toUpperCase().includes(this.state.searchOld.toUpperCase())) {
                        return (
                            <div key={data.id} className="col l2 m4 s6">
                                <div className="admin-deal-div">
                                    <div className="row">
                                        <i className="login-color user-div-font-size">{data.firstName}</i>
                                    </div>
                                    <div className="row">
                                        <div className="col l6 m6 s12">
                                            <button onClick={() => { this.props.createDeal(data.id, data.broker, data.clientCode, data.address, data.newLoanAmount, data.creditScore, data.broker_fee, data.homePurPrice, data.filogixId, data.bureauId, data.appraisalId, data.commitmentId, data.otherId, data.city, data.firstName, data.totalLtv, data.firstMortgage, data.firstOrSecond, data.appraisedValue, data.finalScore) }} className="waves-effect waves-light btn blue admin-action-btn">Create</button>
                                        </div>
                                        <div className="col l6 m6 s12">
                                            <button onClick={() => { this.deleteOldDeal(data.id, data.address) }} className="waves-effect waves-light btn red admin-action-btn">Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                });
            } else {
                oldDeals = this.state.oldData.map((data) => {
                    return (
                        <div key={data.id} className="col l2 m4 s6">
                            <div className="admin-deal-div">
                                <div className="row">
                                    <i className="login-color user-div-font-size">{data.firstName}</i>
                                </div>
                                <div className="row">
                                    <div className="col l6 m6 s12">
                                        <button onClick={() => { this.props.createDeal(data.id, data.broker, data.clientCode, data.address, data.newLoanAmount, data.creditScore, data.broker_fee, data.homePurPrice, data.filogixId, data.bureauId, data.appraisalId, data.commitmentId, data.otherId, data.city, data.firstName, data.totalLtv, data.firstMortgage, data.firstOrSecond, data.appraisedValue, data.finalScore) }} className="waves-effect waves-light btn blue admin-action-btn">View</button>
                                    </div>
                                    <div className="col l6 m6 s12">
                                        <button onClick={() => { this.deleteOldDeal(data.id, data.address) }} className="waves-effect waves-light btn red admin-action-btn">Delete</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
            }
        }

        return (
            <div>
                <div className="row">
                    <div className="col l10 m10 s10">
                        <div className="user-search-div hidden">
                            <div className="input-field">
                                <i className="material-icons prefix">search</i>
                                <input onKeyDown={this.searchOldDeals} className="login-color" id="register-search-olddeals" type="text" className="validate login-color" required />
                                <label htmlFor="register-search-olddeals">Search..</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {oldDeals}
                </div>
            </div>
        );
    }
}

export default ManageOlddeals;