import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import '../../documents.css';

import { Link } from 'react-router-dom';
import Iframe from 'react-iframe'
import SignatureCanvas from 'react-signature-canvas'

import Sign from '../sign/sign'

import Cookies from 'js-cookie';

class PartnerView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            hasSig: this.props.hasSig,
            pdfType: "",
            pdfUrl: "",
            signed: false,
            isSigning: false,
        }


        this.clearSig = this.clearSig.bind(this);
        this.uploadSig = this.uploadSig.bind(this);
        this.loadSig = this.loadSig.bind(this);
        this.editSig = this.editSig.bind(this);
        this.getPdf = this.getPdf.bind(this);
        this.signPDF = this.signPDF.bind(this);
        this.downloadDealFile = this.downloadDealFile.bind(this);
        this.selectPdfType = this.selectPdfType.bind(this);
        this.goBack = this.goBack.bind(this);
        this.setToSigned = this.setToSigned.bind(this);

    }


    componentDidMount() {

        let data = {};

        data['dealId'] = this.props.data.deal.id;

        fetch(this.props.route + `/getdealentries`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                let fs;
                for (var i in res.entries) {
                    if (res.entries[i].name === "Fourth Street") {
                        fs = i;
                    }
                }

                this.setState({ notifs: res.notifs, data: this.props.data });
                if (this.props.hasSig !== 0) {
                    this.canvas.fromDataURL(`${this.props.sigRoute}/${this.props.userId}.png`);
                }

            })
            .catch((err) => {
                console.log(err);
            })


    }

    getPdf() {
        this.setState({ pdfUrl: `${this.props.route}/getdealfile?dealId=${this.state.data.deal.id}&type=${this.state.pdfType}` });
        M.toast({ html: `Showing Original PDF`, classes: 'rounded green' });
    }

    downloadDealFile(isOriginal) {
        if (isOriginal) {
            window.open(`${this.props.route}/downloaddealfile?dealId=${this.state.data.deal.id}&type=${this.state.pdfType}`, '_blank');
            M.toast({ html: `Downloaded Original PDF`, classes: 'rounded green' });
        } else {
            window.open(`${this.props.route}/downloaddealfile?dealId=${this.state.data.deal.id}&type=${this.state.pdfType}&signed=true`, '_blank');
            M.toast({ html: `Downloaded Signed PDF`, classes: 'rounded green' });
        }
    }

    setToSigned() {
        let data = this.state.data;
        if (this.state.pdfType === "9D") {
            data.entry.nineDForm = "2";
            this.setState({ data: data });
        } else if (this.state.pdfType === "CD") {
            data.entry.cdForm = "2";
            this.setState({ data: data });
        } else if (this.state.pdfType === "DAD") {
            data.entry.dadForm = "2";
            this.setState({ data: data });
        } else if (this.state.pdfType === "ARD") {
            data.entry.ardForm = "2";
            this.setState({ data: data });
        }

        this.setState({ signed: true });
    }

    goBack() {
        this.setState({ isSigning: false, pdfUrl: `${this.props.route}/getdealfile?dealId=${this.state.data.deal.id}&type=${this.state.pdfType}` });
        window.scrollTo(0, 0);

    }

    loadSig() {
        this.canvas.fromDataURL(`${this.props.sigRoute}/${this.props.userId}.png`);
    }

    clearSig() {
        this.canvas.clear();
    }

    editSig() {
        this.setState({ hasSig: 0 });
    }

    selectPdfType(type) {
        this.setState({ pdfType: type, pdfUrl: `${this.props.route}/getdealfile?dealId=${this.state.data.deal.id}&type=${type}`, signed: false });
    }

    uploadSig() {

        if (!this.canvas.isEmpty()) {
            let data = {};

            data['sig'] = this.canvas.getTrimmedCanvas().toDataURL();

            fetch(this.props.route + `/uploadSig`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    if (res.response) {
                        window.location.reload();
                    } else {
                        M.toast({ html: `Error Saving!`, classes: 'rounded red' });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            M.toast({ html: `Please draw your signature!`, classes: 'rounded red' });
        }



    }

    signPDF(showSigned) {
        if (!showSigned) {
            if (this.state.pdfType == "9D") {
                this.setState({ isSigning: true, pdfUrl: `${this.props.route}/getfile?id=${Cookies.get('id')}&dealId=${this.state.data.deal.id}&type=${this.state.pdfType}` });
            } else {
                this.setState({ isSigning: true, pdfUrl: `${this.props.route}/getfile?id=${Cookies.get('id')}&dealId=${this.state.data.deal.id}&type=${this.state.pdfType}&signed=true` });
            }

        } else {
            this.setState({ pdfUrl: `${this.props.route}/getdealfile?dealId=${this.state.data.deal.id}&type=${this.state.pdfType}&signed=true` });
            M.toast({ html: `Showing Signed PDF`, classes: 'rounded green' });
        }
    }

    render() {

        let note = ""
        if (this.state.pdfType === "9D") {
            note = this.state.data.entry.nineDNote;
        }
        if (this.state.pdfType === "CD") {
            note = this.state.data.entry.cdNote;
        }
        if (this.state.pdfType === "DAD") {
            note = this.state.data.entry.dadNote;
        }
        if (this.state.pdfType === "ARD") {
            note = this.state.data.entry.ardNote;
        }

        if (this.state.isSigning) {

            return (
                <div>
                    <Sign url={this.state.pdfUrl} note={note} dealId={this.state.data.deal.id} route={this.props.route} sig={this.state.hasSig} back={this.goBack} type={this.state.pdfType} setSigned={this.setToSigned} dealName={this.props.data.deal.lastName} myId={this.props.userId} sigRoute={this.props.sigRoute} entries={[]} isPartner={true} notifs={this.state.notifs} />
                </div>
            );
        }


        let signBtnClass = "btn btn-large green z-depth-5 sig-deal-btns";
        let resignBtnClass = "hidden";
        let signBtnText = "Sign PDF";
        let downloadSignedClass = "hidden";
        let alreadySigned = this.state.signed;

        let nineDStatus = "Not Uploaded";
        let cdStatus = "Not Uploaded";
        let dadStatus = "Not Uploaded";
        let ardStatus = "Not Uploaded";

        let nineDClass = "card z-depth-5 blue-grey file-select";
        let cdClass = "card z-depth-5 blue-grey file-select";
        let dadClass = "card z-depth-5 blue-grey file-select";
        let ardClass = "card z-depth-5 blue-grey file-select";

        let nineDTextClass = 'center total-size deal-header-text red-text';
        let cdTextClass = 'center total-size deal-header-text red-text';
        let dadTextClass = 'center total-size deal-header-text red-text';
        let ardTextClass = 'center total-size deal-header-text red-text';


        let dealLink = "";
        if (this.state.data != null) {

            dealLink = `/viewDeal?id=${this.state.data.deal.id}`;

            switch (this.state.pdfType) {
                case "9D":
                    nineDClass += " isSelected";
                    if (this.state.data.entry.nineDForm === "2") {
                        alreadySigned = true;
                    }
                    break;
                case "CD":
                    cdClass += " isSelected";
                    if (this.state.data.entry.cdForm === "2") {
                        alreadySigned = true;
                    }
                    signBtnClass = "hidden"
                    break;
                case "DAD":
                    dadClass += " isSelected";
                    if (this.state.data.entry.dadForm === "2") {
                        alreadySigned = true;
                    }
                    break;
                case "ARD":
                    if (this.state.data.entry.ardForm === "2") {
                        alreadySigned = true;
                    }
                    ardClass += " isSelected";
                    break;
                default:
                    break;
            }

            if (alreadySigned) {
                signBtnClass = "btn btn-large  green z-depth-5 sig-deal-btns"
                signBtnText = "View Signed PDF";
                downloadSignedClass = "btn btn-large green z-depth-5 sig-deal-btns"
                resignBtnClass = "btn btn-large red z-depth-5 sig-deal-btns"
            }

            //Check Deal
            if (this.state.data.entry.nineDForm === "1") {
                nineDStatus = "Uploaded"
                nineDTextClass = "center total-size deal-header-text red-text yellow-text";
            } else if (this.state.data.entry.nineDForm === "2") {
                nineDStatus = "Signed"
                nineDTextClass = "center total-size deal-header-text red-text green-text";
            } else {
                nineDClass = "card z-depth-5 blue-grey file-select cantClick"
            }

            if (this.state.data.entry.cdForm === "1") {
                cdStatus = "Uploaded"
                cdTextClass = 'center total-size deal-header-text yellow-text';

            } else if (this.state.data.entry.cdForm === "2") {
                cdStatus = "Signed"
                cdTextClass = 'center total-size deal-header-text green-text';
            } else {
                cdClass = "card z-depth-5 blue-grey file-select cantClick"
            }

            if (this.state.data.entry.dadForm === "1") {
                dadStatus = "Uploaded"
                dadTextClass = 'center total-size deal-header-text yellow-text';
            } else if (this.state.data.entry.dadForm === "2") {
                dadStatus = "Signed"
                dadTextClass = 'center total-size deal-header-text green-text';
            } else {
                dadClass = "card z-depth-5 blue-grey file-select cantClick"
            }

            if (this.state.data.entry.ardForm === "1") {
                ardStatus = "Uploaded"
                ardTextClass = 'center total-size deal-header-text yellow-text';
            } else if (this.state.data.entry.ardForm === "2") {
                ardStatus = "Signed"
                ardTextClass = 'center total-size deal-header-text green-text';
            } else {
                ardClass = "card z-depth-5 blue-grey file-select cantClick"
            }


        }

        if (this.state.hasSig === 0) {
            return (
                <div className="row">
                    <div className="col s12">
                        <div className="setUp-signature">
                            <h4 className="hide-on-small-only">Please draw your signature</h4>
                            <br />
                            <div className="sig-canvas-bg">
                                <SignatureCanvas penColor='#3f51b5' clearOnResize={false} minWidth={2} maxWidth={2}
                                    ref={(ref) => { this.canvas = ref; }} canvasProps={{ className: 'sigCanvas' }} />
                            </div>
                            <br /><br />
                            <button onClick={this.uploadSig} className="btn btn-large  green z-depth-3"> <i className="material-icons prefix left">cloud_upload</i>Save</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={this.clearSig} className="btn btn-large red z-depth-3"><i className="material-icons prefix left">delete</i>Clear</button>
                        </div>
                    </div>
                </div>
            );
        } else {
            if (this.state.pdfType === "") {

                return (
                    <div>
                        <div className="row">

                            <div className="deal-documents-info-widgets">
                                <div className="row">
                                    <div className="col s12 l3 m6">
                                        <div onClick={() => { this.selectPdfType("9D") }} className={nineDClass}>
                                            <div className="card-content white-text deal-header-widgets">
                                                <p className="card-stats-title center deal-header-title-widgets"><b>Form 9D</b></p>
                                                <h2 className={nineDTextClass}>{nineDStatus}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s12 l3 m6">
                                        <div onClick={() => { this.selectPdfType("DAD") }} className={dadClass}>
                                            <div className="card-content white-text deal-header-widgets">
                                                <p className="card-stats-title center deal-header-title-widgets"><b>Discharge Documents</b></p>
                                                <h2 className={dadTextClass}>{dadStatus}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s12 l3 m6">
                                        <div onClick={() => { this.selectPdfType("ARD") }} className={ardClass}>
                                            <div className="card-content white-text deal-header-widgets">
                                                <p className="card-stats-title center deal-header-title-widgets"><b>Assignment</b></p>
                                                <h2 className={ardTextClass}>{ardStatus}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s12 l3 m6">
                                        <div onClick={() => { this.selectPdfType("CD") }} className={cdClass}>
                                            <div className="card-content white-text deal-header-widgets">
                                                <p className="card-stats-title center deal-header-title-widgets"><b>Closing Documents</b></p>
                                                <h2 className={cdTextClass}>{cdStatus}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="hide-on-med-and-down">
                            <div className="existings-sig-div">
                                <SignatureCanvas penColor='green' clearOnResize={false} minWidth={2} maxWidth={2}
                                    ref={(ref) => { this.canvas = ref; }} canvasProps={{ className: 'sigCanvasOld' }} />
                                <br />
                                <button onClick={this.editSig} className="btn-floating waves-effect waves-light red z-depth-5 small-edit-sig-btn"> <i className="material-icons prefix left">edit</i>Edit Signature</button>
                            </div>
                        </div>
                        <div className="row">
                            <p className="home-deal-header center"><i className="material-icons prefix choose-file-icon">file_upload</i><br />Please choose an <u>Uploaded</u> file Above</p>
                            <br />
                            <div className="center show-on-medium-and-down hidden row">
                                <button onClick={this.editSig} className="btn btn-large green darken-5 z-depth-3"> <i className="material-icons prefix left">edit</i>Edit Signature</button>
                            </div>
                            <div className="center row">
                                <Link to={dealLink} className="btn btn-large z-depth-3"> <i className="material-icons prefix left">send</i>View Deal</Link>
                            </div>

                            <p className="center white-text">Deal Name: <b>{this.props.data.deal.lastName}</b></p>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="row">
                            <div className="deal-documents-info-widgets">
                                <div className="row">
                                    <div className="col s12 l3 m6">
                                        <div id="9d-widget" onClick={() => { this.selectPdfType("9D") }} className={nineDClass}>
                                            <div className="card-content white-text deal-header-widgets">
                                                <p className="card-stats-title center deal-header-title-widgets "><b>Form 9D</b></p>
                                                <h2 className={nineDTextClass}>{nineDStatus}</h2>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col s12 l3 m6">
                                        <div onClick={() => { this.selectPdfType("DAD") }} className={dadClass}>
                                            <div className="card-content white-text deal-header-widgets">
                                                <p className="card-stats-title center deal-header-title-widgets"><b>Discharge Documents</b></p>
                                                <h2 className={dadTextClass}>{dadStatus}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s12 l3 m6">
                                        <div onClick={() => { this.selectPdfType("ARD") }} className={ardClass}>
                                            <div className="card-content white-text deal-header-widgets">
                                                <p className="card-stats-title center deal-header-title-widgets"><b>Assignment</b></p>
                                                <h2 className={ardTextClass}>{ardStatus}</h2>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col s12 l3 m6">
                                        <div onClick={() => { this.selectPdfType("CD") }} className={cdClass} >
                                            <div className="card-content white-text deal-header-widgets">
                                                <p className="card-stats-title center deal-header-title-widgets"><b>Closing Documents</b></p>
                                                <h2 className={cdTextClass}>{cdStatus}</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col l3 m12 s12">
                                        <br /><br />
                                        <div className="row">
                                            <div className="col s12">
                                                <button className={resignBtnClass} onClick={() => { this.signPDF(false) }}><i className="material-icons prefix left">edit</i>Re-sign</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12">
                                                <button onClick={this.editSig} className="btn btn-large red darken-5 z-depth-3 sig-deal-btns"> <i className="material-icons prefix left">edit</i>Edit Signature</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12">
                                                <button onClick={this.getPdf} className="btn btn-large blue z-depth-5 sig-deal-btns"> <i className="material-icons prefix left">pageview</i>View Original PDF</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12">
                                                <button className="btn btn-large  blue z-depth-5 sig-deal-btns" onClick={() => { this.downloadDealFile(true) }}><i className="material-icons prefix left">file_download</i>Download Original PDF</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12">
                                                <button className={signBtnClass} onClick={() => { this.signPDF(alreadySigned) }}><i className="material-icons prefix left">pageview</i>{signBtnText}</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col s12">
                                                <button className={downloadSignedClass} onClick={() => { this.downloadDealFile(false) }}><i className="material-icons prefix left">file_download</i>Download Signed PDF</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="document-sign-note z-depth-5">
                                                <div className="document-sign-note-header">
                                                    <h6 className="white-text center"><b>COMMENTS</b></h6>
                                                </div>
                                                <div className="document-sign-note-text">
                                                    <p style={{ wordWrap: "break-word" }} className="white-text">{note}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="col l9 m12 s12">
                                        <div className="z-depth-5">
                                            <Iframe url={this.state.pdfUrl}
                                                id="myId"
                                                className="document-pdf-view"
                                                display="initial"
                                                position="relative" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }

        }
    }
}

export default PartnerView;
