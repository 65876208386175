import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import 'materialize-css/dist/css/materialize.min.css';
import { Link } from 'react-router-dom';

import './navbar.css';

class Navbar extends Component {

    constructor(props) {
        super(props);

        this.state = {
            notifications: this.props.notifications
        }


        this.deleteNotification = this.deleteNotification.bind(this);
        this.reloadNotifications = this.reloadNotifications.bind(this);
    }

    componentDidMount() {
        //Init mobile nav
        var nav = document.querySelectorAll('.sidenav');
        M.Sidenav.init(nav, { closeOnClick: true });

        //Init drop down1
        var navDropdown1 = document.querySelectorAll('.dropdown-trigger');
        M.Dropdown.init(navDropdown1, { coverTrigger: false, constrainWidth: false, alignment: "right", onOpenStart: this.changeArrowUp, onCloseStart: this.changeArrowDown });

        //Init dropdown2
        var navDropdown2 = document.querySelectorAll('.dropdown-trigger2');
        M.Dropdown.init(navDropdown2, { coverTrigger: false, constrainWidth: false, alignment: "right" });


    }

    reloadNotifications() {
        fetch(this.props.route + `/checksession`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.setState({ notifications: res.data.notifications });
            })
            .catch((err) => {
                console.log(err);
            })
    }


    showAdminBtn() {
        return <li className="user-options-btns"><Link to="/AdminPanel" className="">Admin Panel<i className="material-icons prefixt">vpn_key</i></Link></li>;
    }
    showAdminiBtnSide() {
        return <li className=""><Link to="/AdminPanel" className="btn sidenav-close">Admin Panel<i className="material-icons prefix">vpn_key</i></Link></li>;
    }

    changeArrowUp() {
        let ele = document.getElementById('drop-down-arrow');
        if (ele != null) {
            document.getElementById('drop-down-arrow').innerHTML = 'arrow_drop_up';
        }
    }
    changeArrowDown() {
        let ele = document.getElementById('drop-down-arrow');
        if (ele != null) {
            document.getElementById('drop-down-arrow').innerHTML = 'arrow_drop_down';
        }
    }

    deleteNotification(id) {
        let data = {};

        data['id'] = id;

        
        let notifs = JSON.parse(this.state.notifications);

        for (var i in notifs) {
            if (notifs[i].id === data.id) {
                notifs.splice(i, 1);
                break;
            }
        }
        this.setState({ notifications: JSON.stringify(notifs) });

        fetch(this.props.route + `/removenotification`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {

            })
            .catch((err) => {
                console.log(err);
            })
    }


    render() {
        if (this.props.isLoggedIn) {

            let notifsClass = "hidden";
            let notifications;


            //Display Notifications if any
            if (this.state.notifications !== "[]" && this.state.notifications !== "") {
                let notifs = JSON.parse(this.state.notifications);

                if (notifs.length > 0) {
                    notifsClass = "dropdown-trigger2 btn-large waves-effect waves-light green pulse z-depth-2 notification-open-btn"
                }

                notifications = notifs.map((data) => {
                    return (
                        <Link key={data.id} to={data.link} className="notification-outer-div" >
                            <div className="notification-div">
                                <button onClick={(event) => { this.deleteNotification(data.id); event.stopPropagation(); event.preventDefault(); }} className="btn waves-effect waves-light btn-small red z-depth-1 notification-close-btn">X</button>
                                <div className="notification-header">
                                    <b>{data.header} Deal</b>
                                </div>
                                <br />
                                <div className="notification-message-div">
                                    {data.msg}
                                </div>
                            </div>
                        </Link>
                    );
                });
            }


            let AdminPanel;
            let adminPanelSide;
            let myDeals = <li className="user-options-btns"><Link to="/MyDeals" >My Deals <i className="material-icons prefix">folder</i></Link></li>;
            let myDealsSide = <li><Link className="btn sidenav-close" to="/MyDeals" >My Deals<i className="material-icons prefix">folder</i></Link></li>;

            //Carousel fix, disable home btn when already home. 
            let homeRedirect = <Link id="homeBtn" to="/Home" className="brand-logo" >Home Ownership Solutions Partner Platform</Link>;
            let smallHomeRedirect = <li className="user-options-btns"><Link to="/Home" >Home<i className="material-icons prefix">home</i></Link></li>;
            let sideSmallHomeRedirect = <li><Link to="/Home" className="btn sidenav-close" >Home <i className="material-icons prefix">home</i></Link></li>;
            if (window.location.href.indexOf("Home") !== -1) {
                smallHomeRedirect = <li className="user-options-btns"><a >Home<i className="material-icons prefix">home</i></a></li>;
                sideSmallHomeRedirect = <li><a className="btn sidenav-close" >Home <i className="material-icons prefix">home</i></a></li>;
                homeRedirect = <a id="homeBtn" href="javascript:" className="brand-logo" >Home Ownership Solutions Partner Platform</a>
            }

            if (this.props.role == 9) {
                AdminPanel = this.showAdminBtn();
                adminPanelSide = this.showAdminiBtnSide();
                myDeals = "";
                myDealsSide = "";
                smallHomeRedirect = "";
                sideSmallHomeRedirect = "";
            } else if (this.props.role == 3 || this.props.role == 2) {
                myDeals = "";
                myDealsSide = "";
            }

            return (
                <div>
                    <div className="navbar-fixed">
                        <Link to="/AdminPanel" className="hidden" id="adminPanelBtn"></Link>
                        <button id="reload-state" onClick={this.reloadNotifications} className="hidden"></button>
                        <nav >
                            <div className="nav-wrapper">
                                <img className="nav-logo-div hide-on-med-and-down" src="/images/logo.png" />&nbsp;&nbsp;&nbsp;&nbsp;
                                {homeRedirect}
                                <a href="javascript:" data-target="mobile" className="sidenav-trigger"><i className="material-icons">menu</i></a>
                                <ul className="right hide-on-med-and-down">
                                    <div className='dropdown-trigger' href='#' data-target='user-options'>
                                        <i id="drop-down-arrow" className="material-icons prefix right right-override">arrow_drop_down</i>
                                        <div className="user-account-div z-depth-5 right">
                                            <ul id='user-options' className='dropdown-content nav-dropdown-size'>
                                                {smallHomeRedirect}
                                                {AdminPanel}
                                                {myDeals}
                                                <li className="user-options-btns"><Link to="/Settings" >Settings <i className="material-icons prefix">settings</i></Link></li>
                                                <li className="user-options-btns"><Link to="/Disclaimer" >Disclaimer <i className="material-icons prefix">warning</i></Link></li>
                                                <li className="user-options-btns"><Link onClick={this.props.logout} to="/" >Logout <i className="material-icons prefix">exit_to_app</i></Link></li>
                                            </ul>
                                        </div>
                                        &nbsp;&nbsp;
                                    <div className="nav-username left">
                                            {this.props.name}
                                        </div>
                                    </div>
                                </ul>
                                <a className={notifsClass} href='javascript:' data-target='dropdown2'><i className="material-icons">border_color</i></a>
                                <ul id='dropdown2' className='dropdown-content notification-drop-down'>
                                    <li className="all-notifications-div" >
                                        {notifications}
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <ul className="sidenav" id="mobile">
                        {sideSmallHomeRedirect}
                        {adminPanelSide}
                        {myDealsSide}
                        <li><Link className="btn sidenav-close" to="/Settings" >Settings<i className="material-icons prefix">settings</i></Link></li>
                        <li><Link to="/Disclaimer" className="btn sidenav-close" >Disclaimer <i className="material-icons prefix">warning</i></Link></li>
                        <li><Link onClick={this.props.logout} className="btn sidenav-close" to="/" >Logout <i className="material-icons prefix">exit_to_app</i></Link></li>
                    </ul>
                </div>
            );
        }
        return (
            <div>
                <Link to="/AdminPanel" className="hidden" id="adminPanelBtn"></Link>
                <div className="nav-logo-div"></div>
                <nav className="navbar-fixed">
                    <div className="nav-wrapper">
                        <img className="nav-logo-div hide-on-med-and-down" src="/images/logo.png" />&nbsp;&nbsp;&nbsp;&nbsp;
                        <Link id="homeBtn" to="/Home" className="brand-logo" >Home Ownership Solutions Partner Platform</Link>
                        <ul className="right hide-on-med-and-down">

                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;