import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';

import '../../documents.css';
import SignatureCanvas from 'react-signature-canvas'
import Iframe from 'react-iframe'

import AllFiles from './files'
import AllEntries from './users'
import Upload from './upload'
import Sign from '../sign/sign'

import Cookies from 'js-cookie';

class AdminView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fileType: "",
            pdfUrl: "",
            shouldUpload: false,
            isSigning: false,
        }


        this.clearSig = this.clearSig.bind(this);
        this.editSig = this.editSig.bind(this);
        this.loadSig = this.loadSig.bind(this);
        this.goBack = this.goBack.bind(this);
        this.leaveSignPage = this.leaveSignPage.bind(this);
        this.setFileUpload = this.setFileUpload.bind(this);
        this.uploadSig = this.uploadSig.bind(this);
        this.uploadFile = this.uploadFile.bind(this);
        this.selectPdfType = this.selectPdfType.bind(this);
        this.getPdf = this.getPdf.bind(this);
        this.downloadDealFile = this.downloadDealFile.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.downloadEntireDeal = this.downloadEntireDeal.bind(this);
        this.setSelectedUser = this.setSelectedUser.bind(this);
        this.setUpload = this.setUpload.bind(this);
        this.back = this.back.bind(this);
        this.startSigning = this.startSigning.bind(this);
        this.changeFsDocument = this.changeFsDocument.bind(this);
        this.getSignedEntries = this.getSignedEntries.bind(this);
        this.clearFile = this.clearFile.bind(this);


    }


    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    componentDidUpdate() {
        let modal = document.querySelectorAll('.modal');
        M.Modal.init(modal);
    }


    componentDidMount() {

        let data = {};

        data['dealId'] = this.props.data.deal.id;

        fetch(this.props.route + `/getdealentries`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                let fs;
                for (var i in res.entries) {
                    if (res.entries[i].userId === 'q0yQCoJtduJn57KFONE1KrzJbbf6bM1O') {
                        fs = i;
                    }
                }

                this.setState({ entries: res.entries, notifs: res.notifs, data: this.props.data, hasSig: this.props.hasSig, fs: fs }); // fs = fouthstreet account
                if (this.props.hasSig !== 0 && this.canvas !== null) {
                    this.canvas.fromDataURL(`${this.props.sigRoute}/${this.props.userId}.png`);
                }

                let modal = document.querySelectorAll('.modal');
                M.Modal.init(modal);
            })
            .catch((err) => {
                console.log(err);
            })

    }

    setUpload(user, file) {
        this.setState({ shouldUpload: true, uploadUser: user, uploadFile: file, isSigning: false, fileType: "" });
    }

    uploadFile(files, userId, type, notifs, note) {
        if (files[0].type !== "application/pdf") {
            M.toast({ html: `File must be a PDF`, classes: 'rounded red' });
            return;
        }



        let form = new FormData();
        form.append('file', files[0]);
        form.append('type', type);
        form.append('userId', userId); //Target user
        form.append('dealId', this.state.data.deal.id);
        form.append('notifs', JSON.stringify(notifs));
        form.append('dealName', this.props.data.deal.lastName);
        form.append('note', note);

        fetch(this.props.route + `/uploadfile`, {
            method: 'POST',
            credentials: 'include',
            body: form
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.componentDidMount();
                this.setState({ isSigning: false, shouldUpload: false, fileType: type });
                M.toast({ html: `File uploaded!`, classes: 'rounded green' });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    downloadEntireDeal() {
        window.open(`${this.props.route}/downloadentiredeal?dealId=${this.state.data.deal.id}&name=${this.state.data.deal.lastName}`, '_blank');
    }

    editSig() {
        this.setState({ hasSig: 0 });
    }


    clearSig() {
        this.canvas.clear();
    }

    back() {
        this.setState({ shouldUpload: false, fileUpload: "" });

        setTimeout(() => {
            this.canvas.fromDataURL(`${this.props.sigRoute}/${this.props.userId}.png`);
        }, 100)
    }

    setFileUpload(event) {
        this.setState({ fileUpload: event.target.value });

    }

    setSelectedUser(event) {
        this.setState({ selectedUser: event.target.value });
    }

    mobileView() {
        alert();
    }

    downloadDealFile(isOriginal, userId) {
        if (isOriginal) {
            window.open(`${this.props.route}/downloaddealfile?dealId=${this.state.data.deal.id}&userId=${userId}&type=${this.state.fileType}`, '_blank');
            M.toast({ html: `Downloaded Original PDF`, classes: 'rounded green' });
        } else {
            window.open(`${this.props.route}/downloaddealfile?dealId=${this.state.data.deal.id}&userId=${userId}&type=${this.state.fileType}&signed=true`, '_blank');
            M.toast({ html: `Downloaded Signed PDF`, classes: 'rounded green' });
        }
    }

    deleteFile(userId) {
        if (window.confirm("Remove signature?")) {
            let data = {};

            data['dealId'] = this.state.data.deal.id;
            data['userId'] = userId;
            data['type'] = this.state.fileType;

            fetch(this.props.route + `/deletefile`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    this.setState({ fileType: "", fileUrl: "" });
                    this.componentDidMount();
                    M.toast({ html: `Document Unsigned!`, classes: 'rounded green' });
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    uploadSig() {

        if (!this.canvas.isEmpty()) {
            let data = {};

            data['sig'] = this.canvas.getTrimmedCanvas().toDataURL();

            fetch(this.props.route + `/uploadSig`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    if (res.response) {
                        window.location.reload();
                    } else {
                        M.toast({ html: `Error Saving!`, classes: 'rounded red' });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            M.toast({ html: `Please draw your signature!`, classes: 'rounded red' });
        }

    }

    startSigning(userId, alreadySigned) {
        if (alreadySigned) {
            this.setState({ isSigning: true, pdfUrl: `${this.props.route}/getfile?id=${Cookies.get('id')}&dealId=${this.state.data.deal.id}&userId=${userId}&type=${this.state.fileType}&signed=true`, selectedUser: userId });
        } else {
            this.setState({ isSigning: true, pdfUrl: `${this.props.route}/getfile?id=${Cookies.get('id')}&dealId=${this.state.data.deal.id}&userId=${userId}&type=${this.state.fileType}`, selectedUser: userId });
        }
    }

    selectPdfType(type) {
        if (type === "CD") {
            this.setState({ fileType: type, pdfUrl: `${this.props.route}/getdealfile?dealId=${this.state.data.deal.id}&type=${type}` });
            return;
        }
        this.setState({ fileType: type, pdfUrl: "" });

    }

    goBack() {
        this.setState({ isSigning: false, shouldUpload: false, fileType: "" });

        setTimeout(() => {
            this.canvas.fromDataURL(`${this.props.sigRoute}/${this.props.userId}.png`);
        }, 100)
    }

    leaveSignPage() {
        this.setState({ isSigning: false, pdfUrl: "" });
    }

    loadSig() {
        this.canvas.fromDataURL(`${this.props.sigRoute}/${this.props.userId}.png`);
    }

    clearFile(userId) {

        if (window.confirm("Remove File?")) {
            let data = {};

            data['dealId'] = this.state.data.deal.id;
            data['type'] = this.state.fileType;
            data['userId'] = userId;

            fetch(this.props.route + `/clearfiles`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    this.componentDidMount();
                    M.toast({ html: `File Cleared!`, classes: 'rounded green' });
                })
                .catch((err) => {
                    console.log(err);
                })
        }



    }

    getPdf(isOriginal, userId) {
        if (isOriginal) {
            this.setState({ pdfUrl: `${this.props.route}/getdealfile?dealId=${this.state.data.deal.id}&userId=${userId}&type=${this.state.fileType}` });
            M.toast({ html: `Showing Original PDF`, classes: 'rounded green' });
        } else {
            this.setState({ pdfUrl: `${this.props.route}/getdealfile?dealId=${this.state.data.deal.id}&userId=${userId}&type=${this.state.fileType}&signed=true` });
            M.toast({ html: `Showing Signed PDF`, classes: 'rounded green' });
        }
    }

    changeFsDocument(type) {
        let data = this.state.entries;
        switch (type) {
            case "DS":
                data[this.state.fs].dsForm = "2";
                this.setState({ entries: data });
                break;
            case "RN":
                data[this.state.fs].rnForm = "2";
                this.setState({ entries: data });
                break;
            case "LD":
                data[this.state.fs].ldForm = "2";
                this.setState({ entries: data });
                break;
            case "LDR":
                data[this.state.fs].ldrForm = "2";
                this.setState({ entries: data });
                break;
        }
    }

    showSignsModal() {
        document.getElementById('toggle-signs-modal').click();
    }

    getSignedEntries() {
        return this.state.entries.map((data) => {
            if (this.state.fileType == "DAD") {
                if (data.dadForm === "2") {
                    return (
                        <tr key={Math.random()} className="mydeal-table-row" >
                            <td className="mydeals-table-font notify-table-row">{data.name}</td>
                            <td className="notify-table-row"><button className="btn-small green"><i className="material-icons prefix">check</i></button></td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={Math.random()} className="mydeal-table-row notify-table-row" >
                            <td className="mydeals-table-font notify-table-row">{data.name}</td>
                            <td className="notify-table-row"><button className="btn-small red"><i className="material-icons prefix">close</i></button></td>
                        </tr>
                    );
                }
            } else if (this.state.fileType == "ARD") {
                if (data.ardForm === "2") {
                    return (
                        <tr key={Math.random()} className="mydeal-table-row" >
                            <td className="mydeals-table-font notify-table-row">{data.name}</td>
                            <td className="notify-table-row"><button className="btn-small green"><i className="material-icons prefix">check</i></button></td>
                        </tr>
                    );
                } else {
                    return (
                        <tr key={Math.random()} className="mydeal-table-row notify-table-row" >
                            <td className="mydeals-table-font notify-table-row">{data.name}</td>
                            <td className="notify-table-row"><button className="btn-small red"><i className="material-icons prefix">close</i></button></td>
                        </tr>
                    );
                }
            }

        })
    }

    render() {

        let currentNote = ""

        for (var i in this.state.entries) {
            const entry = this.state.entries[i];
            if (this.state.fileType === 'DS' && entry.dsForm === "1") {
                currentNote = entry.dsNote;
            } else if (this.state.fileType === 'LDR' && entry.ldrForm === "1") {
                currentNote = entry.ldrNote;
            } else if (this.state.fileType === 'DR' && entry.ldForm === "1") {
                currentNote = entry.drNote;
            } else if (this.state.fileType === 'RN' && entry.rnForm === "1") {
                currentNote = entry.rnNote;
            }

        }

        //Names have changed but short should not change the same.
        let legalFiles = [
            {
                name: "Form 9D",
                short: "9D",
                type: "legal"
            },
            {
                name: "Closing Documents",
                short: "CD",
                type: "legal"
            },
            {
                name: "Discharge Documents",
                short: "DAD",
                type: "legal"
            },
            {
                name: "Assignment",
                short: "ARD",
                type: "legal"
            }

        ]

        let otherFiles = [
            {
                name: "Discharge Statement",
                short: "DS",
                type: "other"
            },
            {
                name: "Renewal",
                short: "RN",
                type: "other"
            },
            {
                name: "Lender Disclosure Renewal",
                short: "LDR",
                type: "other"
            },
            {
                name: "Lender Disclosure",
                short: "LD",
                type: "other"
            }
        ]

        let currentFile;


        switch (this.state.fileType) {
            case "LD":
                currentFile = "Lender Disclosure"
                break;
            case "LDR":
                currentFile = "Lender Disclosure Renewal"
                break;
            case "RN":
                currentFile = "Renewal"
                break;
            case "DS":
                currentFile = "Discharge Statement"
                break;
            case "9D":
                currentFile = "Form 9D"
                break;
            case "CD":
                currentFile = "Closing Documents"
                break;
            case "DAD":
                currentFile = "Discharge Documents"
                break;
            case "ARD":
                currentFile = "Assignment"
                break;
            default:
                break;
        }

        if (this.state.isSigning) {
            return (
                <div>
                    <Sign url={this.state.pdfUrl} note={currentNote} updateFS={this.changeFsDocument} dealId={this.state.data.deal.id} route={this.props.route} sig={this.state.hasSig} back={this.leaveSignPage} userId={this.state.selectedUser} type={this.state.fileType} dealName={this.props.data.deal.lastName} myId={this.props.userId} sigRoute={this.props.sigRoute} entries={this.state.entries} notifs={this.state.notifs} />
                </div>
            );
        }



        //Needs Signature
        if (this.state.hasSig === 0) {
            return (
                <div>
                    <div className="row">
                        <div className="col s12">
                            <div className="setUp-signature">
                                <h4 className="hide-on-small-only">Please draw your signature</h4>
                                <br />
                                <div className="sig-canvas-bg">
                                    <SignatureCanvas penColor='#3f51b5' clearOnResize={false} minWidth={2} maxWidth={2}
                                        ref={(ref) => { this.canvas = ref; }} canvasProps={{ className: 'sigCanvas' }} />
                                </div>
                                <br /><br />
                                <button onClick={this.uploadSig} className="btn btn-large  green z-depth-3"> <i className="material-icons prefix left">cloud_upload</i>Save</button>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={this.clearSig} className="btn btn-large red z-depth-3"><i className="material-icons prefix left">delete</i>clear</button>

                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            if (this.state.shouldUpload) {
                return (
                    <div className="document-upload-div">
                        <Upload entries={this.state.entries} back={this.back} file={this.state.uploadFile} user={this.state.uploadUser} upload={this.uploadFile} notifs={this.state.notifs} />
                    </div>
                );
            }
            if (this.state.fileType === "") {
                return (
                    <div>
                        <div id="modal2" className="modal blue-grey darken-4">
                            <div className="modal-content">
                                <button className="modal-close waves-effect red btn right"><i className="material-icons prefix">close</i></button>
                                <br /><br />
                                <table className="centered manage-entries-table z-depth-5">
                                    <thead>
                                        <tr className="viewdeal-tabel-th">
                                            <th>Sign Status</th>
                                            <th>Name</th>
                                        </tr>
                                    </thead>

                                    <tbody id="deals-table" className="sign-notification-div">

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col l6 m6 s12">
                                <h5 className="center white-text"><b>Legal Documents</b></h5>
                                <AllFiles select={this.selectPdfType} files={legalFiles} goBack={this.goBack} />
                            </div>
                            <div className="col l6 m6 s12">
                                <h5 className="center white-text"><b>Admin Documents</b></h5>
                                <AllFiles select={this.selectPdfType} files={otherFiles} goBack={this.goBack} />
                            </div>
                        </div>
                        <div className="hide-on-med-and-down">
                            <div className="existings-sig-div">
                                <SignatureCanvas penColor='#3f51b5' clearOnResize={false} minWidth={2} maxWidth={2}
                                    ref={(ref) => { this.canvas = ref; }} canvasProps={{ className: 'sigCanvasOld' }} />
                                <br />
                                <button onClick={this.editSig} className="btn-floating waves-effect waves-light red z-depth-5 small-edit-sig-btn"> <i className="material-icons prefix left">edit</i>Edit Signature</button>
                            </div>
                        </div>
                        <div className="center">
                            <p className="home-deal-header center"><i className="material-icons prefix choose-file-icon">file_upload</i><br />Please choose a file above</p>
                            <br /><br />
                            <button onClick={this.downloadEntireDeal} className="btn btn-large  green z-depth-3"> <i className="material-icons prefix left">cloud_download</i>Download Documents</button>
                            <br />
                            <br />
                            <div className="show-on-medium-and-down hidden center">
                                <button onClick={this.editSig} className="btn btn-large waves-effect waves-light red z-depth-3"> <i className="material-icons prefix left">edit</i>Edit Signature</button>
                            </div>

                            <p className="center white-text">Deal Name: <b>{this.props.data.deal.lastName}</b></p>

                        </div>
                    </div>
                );
            }

            return (
                <div>
                    <div id="modal2" className="modal blue-grey darken-4">
                        <div className="modal-content">
                            <button className="modal-close waves-effect red btn right"><i className="material-icons prefix">close</i></button>
                            <br /><br />
                            <table className="centered manage-entries-table z-depth-5">
                                <thead>
                                    <tr className="viewdeal-tabel-th">
                                        <th>Name</th>
                                        <th>Sign Status</th>
                                    </tr>
                                </thead>

                                <tbody id="deals-table" className="sign-notification-div">
                                    {this.getSignedEntries()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <button id="toggle-signs-modal" className="hidden modal-trigger" data-target="modal2"></button>
                    <div className="document-files-div">
                        <div className="row">
                            <div className="col l6 m6 s12">
                                <h5 className="center white-text"><b>Legal Documents</b></h5>
                                <AllFiles select={this.selectPdfType} files={legalFiles} goBack={this.goBack} />
                            </div>
                            <div className="col l6 m6 s12">
                                <h5 className="center white-text"><b>Admin Documents</b></h5>
                                <AllFiles select={this.selectPdfType} files={otherFiles} goBack={this.goBack} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col l5 m12 s12">
                                <div className="row">
                                    <div className="col s2">
                                        <button onClick={this.goBack} className="btn btn-large red z-depth-3"><i className="material-icons prefix">arrow_back</i></button>
                                    </div>
                                    <div className="col s8">
                                        <h5 className="white-text center">{currentFile}</h5>
                                    </div>
                                    <div className="col s2">

                                    </div>
                                </div>
                                <table className="centered manage-entries-table z-depth-5">
                                    <thead>
                                        <tr className="viewdeal-tabel-th">
                                            <th>Name</th>
                                            <th>Uploaded</th>
                                            <th>Signed</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody id="deals-table">
                                        <AllEntries role={this.props.role} setUpload={this.setUpload} entries={this.state.entries} download={this.downloadDealFile} getPdf={this.getPdf} uploadPdf={this.setUpload} deleteFile={this.deleteFile} type={this.state.fileType} sign={this.startSigning} showSigns={this.showSignsModal} clear={this.clearFile} />
                                    </tbody>
                                </table>
                                <br />
                                <div className="document-sign-note z-depth-5">
                                    <div className="document-sign-note-header">
                                        <h6 className="white-text center"><b>COMMENTS</b></h6>
                                    </div>
                                    <div className="document-sign-note-text">
                                        <p style={{ wordWrap: "break-word" }} className="white-text">{currentNote}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col l7 m12 s12">
                                <div className="z-depth-5">
                                    <Iframe url={this.state.pdfUrl}
                                        id="myId"
                                        className="document-pdf-view"
                                        display="initial"
                                        position="relative" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            );
        }
    }
}

export default AdminView;
