import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './deal.css';
import { Redirect } from 'react-router-dom';

import Loading from '../loading/loading'

class Deal extends Component {

    constructor(props) {
        super(props);

        this.timer = null;

        this.amount = 0;

        this.closed = false;

        this.hasPdfs = false;

        this.state = {
            allowed: null,
            data: null,
            loaded: false
        }

        this.next = this.next.bind(this);
        this.prev = this.prev.bind(this);
        this.set = this.set.bind(this);
        this.joinDeal = this.joinDeal.bind(this);
        this.updateDeal = this.updateDeal.bind(this)
        this.showPDF = this.showPDF.bind(this);
        this.increaseAmount = this.increaseAmount.bind(this);
        this.decreaseAmount = this.decreaseAmount.bind(this);
    }



    componentDidMount() {

        fetch(`${this.props.route}/getdeal?id=${window.location.href.split("?id=")[1]}`, {
            credentials: 'include',
        })
            .then((res) => {
                return res.json();
            }).then((res) => {
                if (res.response) {
                    //Check if deal is closed
                    var countDownDate = new Date(res.data.expires).getTime();
                    var now = new Date().getTime();
                    if (countDownDate - now < 0) {
                        this.closed = true;
                    }

                    this.amount = parseInt(res.data.minIncrement) + parseInt(res.data.inFor);

                    this.setState({ allowed: true, data: res.data, loaded: true });

                    //Init modal
                    var modal = document.querySelectorAll('.modal');
                    M.Modal.init(modal);

                    //Init carousel
                    if (this.hasPdfs) {
                        this.instance = M.Carousel.init(document.getElementById('carousel'), { fullWidth: true });
                    }

                    //set Timer 
                    this.setTimer(res.data.expires);

                    document.getElementById('show-mini-map').click();


                } else {
                    this.setState({ allowed: false });
                }
            }).catch((err) => {
                console.log(err);

            })
    }

    set(i) {
        if (this.hasPdfs) {
            this.instance.set(i);
        }

    }
    next() {
        if (this.hasPdfs) {
            this.instance.next();
        }

    }

    prev() {
        if (this.hasPdfs) {
            this.instance.prev();
        }
    }

    showPDF(route, id) {
        //Use same modal as map
        if (route === "funding") {
            document.getElementById('map').innerHTML = `<iframe height="95%" width="100%" src="https://partners.homeownershipsolutions.org/docs/kh43an22433dliua83274723h43j.pdf"></iframe>`;
            document.getElementById('pdf-btn-click').click();
        } else {
            document.getElementById('map').innerHTML = `<iframe height="95%" width="100%" src="${this.props.pdfRoute}/${route}/${id}.pdf"></iframe>`;
            document.getElementById('pdf-btn-click').click();
        }
    }

    increaseAmount() {
        if (this.amount + parseInt(this.state.data.increments) <= this.state.data.offering + this.state.data.inFor) {
            document.getElementById('user-amount').innerHTML = this.moneyFormat(this.amount + parseInt(this.state.data.increments));
            this.amount = this.amount + parseInt(this.state.data.increments);
        } else if (this.amount + parseInt(this.state.data.increments) > this.state.data.offering) {
            document.getElementById('user-amount').innerHTML = this.moneyFormat(parseInt(this.state.data.offering) + parseInt(this.state.data.inFor));
            this.amount = parseInt(this.state.data.offering) + parseInt(this.state.data.inFor);
        }
    }

    decreaseAmount() {
        if (this.amount > parseInt(this.state.data.inFor) + parseInt(this.state.data.increments)) {
            document.getElementById('user-amount').innerHTML = this.moneyFormat(this.amount - parseInt(this.state.data.increments));
            this.amount = this.amount - parseInt(this.state.data.increments);
        }
    }

    moneyFormat(x) {
        if (x == null) { return }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    joinDeal() {

        let data = {};

        data['dealId'] = this.state.data.id;
        data['amount'] = this.amount;
        data['address'] = this.state.data.originalAddress;
        data['email'] = this.state.data.email;
        data['lastName'] = this.state.data.clientName;
        data['maturityDate'] = this.state.data.maturityDate;

        fetch(this.props.route + `/joindeal`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (res.response) {
                    M.toast({ html: `Joined Deal!`, classes: 'rounded green' });
                    document.getElementById('homeBtn').click();
                } else {
                    M.toast({ html: res.reason, classes: 'rounded red' });
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    updateDeal() {

        let data = {};

        data['dealId'] = this.state.data.id;
        data['amount'] = this.amount;
        data['adding'] = this.amount - parseInt(this.state.data.inFor);

        fetch(this.props.route + `/updatedeal`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (res.response) {
                    M.toast({ html: `Deal Updated!`, classes: 'rounded green' });
                    document.getElementById('homeBtn').click();
                } else {
                    M.toast({ html: res.reason, classes: 'rounded red' });
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    setTimer(dateString) {
        var countDownDate = new Date(dateString).getTime();

        var now = new Date().getTime();

        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        //var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        let daysDiv = document.getElementById('days');
        let hoursDiv = document.getElementById('hours');
        let minutesDiv = document.getElementById('minutes');
        //let secondsDiv = document.getElementById('seconds');

        daysDiv.innerHTML = days;
        hoursDiv.innerHTML = hours;
        minutesDiv.innerHTML = minutes;
        //secondsDiv.innerHTML = seconds;

        if (distance < 0) {
            document.getElementById('timer-div-expired').innerHTML = `EXPIRED`;
            clearInterval(this.timer);
            return;
        }

        this.timer = setInterval(() => {
            var now = new Date().getTime();

            var distance = countDownDate - now;

            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            //var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            daysDiv.innerHTML = days;
            hoursDiv.innerHTML = hours;
            minutesDiv.innerHTML = minutes;
            //secondsDiv.innerHTML = seconds;

            if (distance < 0) {
                document.getElementById('timer-div-expired').innerHTML = `EXPIRED`;
                clearInterval(this.timer);
                return;
            }

        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        if (this.state.allowed != null && !this.state.allowed) {
            return <Redirect push to="/Home" />;
        }
        if (!this.state.loaded) {
            return <Loading />
        }

        let data = this.state.data;
        let address = ""
        let requestAmount = 0;
        let offering = 0;
        let retainings = 0;
        let dealAmount = 0;
        let dealRate = 0;
        let dealFee = 0;
        let propertyAmount = 0;
        let LTV = 0;
        let creditScore = 0;
        let dealType;
        let priorityMortgage;
        let comments;


        let filogix;
        let commitment;
        let appraisal;
        let bureau;
        let other;
        let payment;

        let joinDealDiv;
        let yourRetainings = 0;

        let isAdmin;

        let externalMap;

        let clientName = "";

        let riskRatingColor = "green";
        let riskRatingText = "LOW-to-MID";

        let monthlyPayment;
        let dealFeeAmount;

        if (data !== null) {
            address = data.address;
            requestAmount = this.moneyFormat(this.amount);
            offering = this.moneyFormat(this.state.data.offering);
            retainings = this.moneyFormat(this.state.data.retainings);
            dealAmount = this.moneyFormat(this.state.data.dealAmount);
            dealRate = this.state.data.dealRate
            dealFee = this.moneyFormat(this.state.data.dealFee);
            propertyAmount = this.moneyFormat(this.state.data.propertyValue);
            if (this.state.data.loanToValue != "") {
                LTV = this.state.data.loanToValue;
            }

            externalMap = data.externalMap;

            clientName = data.clientName;

            creditScore = this.state.data.creditScore;

            comments = data.comments;

            dealType = data.dealType;
            priorityMortgage = this.moneyFormat(data.priorityMortgage);

            isAdmin = data.isAdmin;

            let mP = (dealRate / 100) * parseInt(data.inFor) / 12;
            let dA = parseFloat(this.state.data.dealFeeAmount / 100) * parseInt(data.inFor);


            monthlyPayment = this.moneyFormat(mP.toFixed(2));
            dealFeeAmount = this.moneyFormat(dA.toFixed(2));


            if (data.finalScore < 4.5) {
                riskRatingColor = "card z-depth-5 green";
                riskRatingText = "LOW-to-MID";
            } else if (data.finalScore >= 4.5 && data.finalScore <= 6.1) {
                riskRatingColor = "card z-depth-5 yellow";
                riskRatingText = "MID";
            } else {
                riskRatingColor = "card z-depth-5 red";
                riskRatingText = "MID-to-HIGH";
            }

            if (true) { //payment pdf, always true
                payment = <div className="carousel-item"><p>Funding Details</p><br /><button onClick={() => { this.showPDF("funding") }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div>
                this.hasPdfs = true;
            }
            if (data.filogix !== "" && data.filogix !== null) {
                filogix = <div className="carousel-item"><p>Filogix PDF</p><br /><button onClick={() => { this.showPDF("filogix", data.filogix) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div>
                this.hasPdfs = true;
            }
            if (data.commitment !== "" && data.commitment !== null) {
                commitment = <div className="carousel-item"><p>Commitment PDF</p><br /><button onClick={() => { this.showPDF("commitment", data.commitment) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div>
                this.hasPdfs = true;
            }
            if (data.appraisal !== "" && data.appraisal !== null) {
                appraisal = <div className="carousel-item"><p>Appraisal PDF</p><br /><button onClick={() => { this.showPDF("appraisal", data.appraisal) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div>
                this.hasPdfs = true;
            }
            if (data.bureau !== "" && data.bureau !== null) {
                bureau = <div className="carousel-item"><p>Bureau PDF</p><br /><button onClick={() => { this.showPDF("bureau", data.bureau) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div>
                this.hasPdfs = true;
            }
            if (data.other !== "" && data.other !== null) {
                other = <div className="carousel-item"><p>Other PDF</p><br /><button onClick={() => { this.showPDF("other", data.other) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div>
                this.hasPdfs = true;
            }

            //if is not admin
            if (!isAdmin) {
                //If deal is not full
                if (this.state.data.offering > 0) {
                    //If user is not in this deal 
                    if (!data.inDeal) {
                        if (!this.closed) {
                            //Check if enough funds are left for mininum amount else requestedAmount = what is left.


                            joinDealDiv =
                                <div>
                                    <div className="deal-user-input-div">
                                        <div className="deal-set-bet">
                                            <p className="login-color red-cross deal-display-font">Your Request</p>
                                            <a onClick={this.decreaseAmount} className="btn-floating waves-effect waves-light red left z-depth-3"><i className="material-icons">remove</i></a>
                                            <i className="deal-side-size">$<i id="user-amount">{requestAmount}</i></i>
                                            <a onClick={this.increaseAmount} className="btn-floating waves-effect waves-light green right z-depth-3"><i className="material-icons">add</i></a>
                                            <div className="deal-join-div">
                                                <button onClick={this.joinDeal} className="btn btn-lg deal-join-btn green z-depth-3">Join Deal</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        } else {
                            joinDealDiv = <p className="center"><i className="material-icons prefix red-cross left error-font-size">error</i>Sorry, This Deal Has Expired!<i className="material-icons prefix red-cross right error-font-size">error</i></p>
                        }
                    } else {

                        yourRetainings = this.moneyFormat(parseInt(data.inFor));

                        if (!this.closed) {
                            //Check if enough funds are left for mininum amount else requestedAmount = what is left.

                            joinDealDiv =
                                <div>
                                    <div className="deal-user-input-div">
                                        <div className="deal-set-bet">
                                            <p className="login-color red-cross deal-display-font">Increase Participation To</p>
                                            <a onClick={this.decreaseAmount} className="btn-floating waves-effect waves-light red left z-depth-3"><i className="material-icons">remove</i></a>
                                            <i className="deal-side-size">$<i id="user-amount">{requestAmount}</i></i>
                                            <a onClick={this.increaseAmount} className="btn-floating waves-effect waves-light green right z-depth-3"><i className="material-icons">add</i></a>
                                            <div className="deal-join-div">
                                                <button onClick={this.updateDeal} className="btn btn-lg deal-join-btn green z-depth-3">Update Deal</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        } else {
                            joinDealDiv = <p className="center"><i className="material-icons prefix red-cross left error-font-size">error</i>Sorry, This Deal Has Expired!<i className="material-icons prefix red-cross right error-font-size">error</i></p>
                        }


                    }
                } else {
                    yourRetainings = this.moneyFormat(parseInt(data.inFor));

                    joinDealDiv = <p className="center"><i className="material-icons prefix red-cross left error-font-size">error</i>Sorry, This Deal Is Full!<i className="material-icons prefix red-cross right error-font-size">error</i></p>

                }
            } else {
                joinDealDiv = <p className="center"><i className="material-icons prefix red-cross left error-font-size">error</i>Admin View<i className="material-icons prefix red-cross right error-font-size">error</i></p>
            }

        }

        return (
            <div>

                <input id="show-deal-address" type="text" className="validate login-color hidden" value={address} readOnly /> {/*USED FOR GOOGLE MAPS*/}
                <a id="show-mini-map" className="hidden" href="javascript:showMiniMap();"></a>

                <button id="pdf-btn-click" data-target="pdf-modal" className="btn modal-trigger hidden ">Modal</button>
                <div id="pdf-modal" className="modal modal-background-color " style={{ width: "95%", height: "88%" }}>
                    <div className="modal-content pdf-modal-content">
                        <button data-target="pdf-modal" className="btn modal-close waves-effect waves-light red right">X</button><br /><br />
                        <div id="map" className="google-map">

                        </div>
                    </div>
                </div>
                <div id="modal3" className="modal modal-background-color " style={{ width: "60%", height: "auto" }}>
                    <div className="modal-content pdf-modal-content">
                        <button data-target="modal3" className="btn modal-close waves-effect waves-light red right">X</button>
                        <div className="deal-desclaimer-text">
                            <h5 className="center red-cross">Disclaimer</h5>
                            <p>
                                HOS uses a proprietary Risk Assessment algorithm herein which provides insight into HOS’s own perceived risk of the transaction. This tool provides an automated Low-to-Mid, Mid and Mid-to-High risk assessment which may be overridden by HOS from time to time due to factors affecting the risk not otherwise accounted for in the algorithm and that overridden assessment is what may appear herein. HOS is not endorsing or otherwise recommending that risk assessment to you. Your perceived risk of the transaction should be based on your own independent analysis including but not limited to, assumptions, due diligence, market reviews, property reviews, independent appraisals, counsel from your own advisors, etc.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row"></div>
                <div className="row">
                    <div className="col s12 l4 m6">
                        <div className="col s12 l12 m12">
                            <div className="card z-depth-5 blue-grey">
                                <div className="card-content white-text deal-header-widgets">
                                    <p className="card-stats-title center deal-header-title-widgets"><b>Client Name</b></p>
                                    <h2 className="center total-size deal-header-text">{clientName}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l12 m12">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Property Address</b></p>
                                    <h2 className="center total-size card-text-gap">{address}</h2>
                                    <div className="center">
                                        <a target="_blank" href={externalMap} className="btn z-depth-3 center listing-map-btn"><i className="material-icons prefix left">directions</i>Nearby Listings</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><b>Property Value</b></p>
                                    <h2 className="center total-size ">${propertyAmount}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><b>Total Loan Amount</b></p>
                                    <h2 className="center total-size ">${dealAmount}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><b>Loan To Value</b></p>
                                    <h2 className="center total-size ">{LTV}%</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><b>Credit Score</b></p>
                                    <h2 className="center total-size ">{creditScore}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l12 m12">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content  pdf-padding">
                                    <h5 className="center">Deal Documents</h5>
                                    <br />
                                    <div id="carousel" className="carousel carousel-slider center pdf-carousel">
                                        <div className="carousel-arrow-right">
                                            <a onClick={this.next} className="btn-floating btn-large waves-effect waves-light right red">
                                                <i className="material-icons prefix login-color right">chevron_right</i>
                                            </a>
                                        </div>
                                        <div className="carousel-arrow-left">
                                            <a onClick={this.prev} className="btn-floating btn-large waves-effect waves-light left red">
                                                <i className="material-icons prefix login-color left">chevron_left</i>
                                            </a>
                                        </div>
                                        {payment}
                                        {filogix}
                                        {commitment}
                                        {appraisal}
                                        {bureau}
                                        {other}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 l4 m6">
                        <div className="col s12 l12 m12">
                            <div className="card z-depth-5 yellow amber accent-4">
                                <div className="card-content deal-timer-div">
                                    <p className="card-stats-title center deal-header-title-widgets"><i className="mdi-social-group-add"></i><b>Time Remaining</b></p>
                                    <ul id="timer-div-expired">
                                        <li className="timer-list"><span id="days" className="timer-font-size">0</span>days</li>
                                        <li className="timer-list"><span id="hours" className="timer-font-size">0</span>Hours</li>
                                        <li className="timer-list"><span id="minutes" className="timer-font-size">0</span>Minutes</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="view-property-btn-div row">
                            <div className="col l6 m6 s6">
                                <a href="javascript:showAddress()" className="btn waves-effect waves-light z-depth-3 google-map-btn">Enlarge Map <i className="material-icons prefix left">location_city</i></a>
                            </div>
                            <div className="col l6 m6 s6">
                                <a href="javascript:streetView()" className="btn waves-effect waves-light z-depth-3 google-map-btn">Street View <i className="material-icons prefix left">location_on</i></a>
                            </div>
                        </div>
                        <div className="row">
                            <div id="google-map-div" className="google-map-div z-depth-5 col l12 m12 s12">

                            </div>
                        </div>
                        <div className="col s12 l12 m12">
                            <div className={riskRatingColor}>
                                <div className="card-content deal-timer-div">
                                    <button onClick={this.showListings} data-target="modal3" className="btn modal-trigger red z-depth-2 right deal-disclaimer-btn"><i className="material-icons prefix">warning</i></button>
                                    <p className="card-stats-title center deal-header-title-widgets"><i className="mdi-social-group-add"></i><b>HOS Risk Assessment</b></p>
                                    <h5 className="center total-size">{riskRatingText}</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l12 m12">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card deal-comments-div">
                                    <h5 className="center">Deal Comments</h5>
                                    <p className="center">
                                        {comments}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 l4 m12">
                        <div className="col s12 l12 m12">
                            <div className="card z-depth-5 blue-grey">
                                <div className="card-content white-text deal-header-widgets">
                                    <p className="card-stats-title center deal-header-title-widgets"><b>Home Ownership Retaining</b></p>
                                    <h2 className="center login-color total-size deal-header-text">${retainings}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Mortgage Offered</b></p>
                                    <h2 className="center total-size card-text-gap">{dealType}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Priority Mortgage</b></p>
                                    <h2 className="center total-size card-text-gap">${priorityMortgage}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Deal Rate</b></p>
                                    <h2 className="center total-size card-text-gap">{dealRate}%</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Deal Fee $</b></p>
                                    <h2 className="center total-size card-text-gap">${dealFeeAmount}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Monthly Payment</b></p>
                                    <h2 className="center total-size card-text-gap">${monthlyPayment}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s6 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Annualized Fee</b></p>
                                    <h2 className="center total-size card-text-gap">{dealFee}%</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><b>Available to Offer</b></p>
                                    <h2 className="center total-size card-text-gap">${offering}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l6 m6">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content smaller-card">
                                    <p className="card-stats-title center"><b>Your Participation</b></p>
                                    <h2 className="center total-size card-text-gap">${yourRetainings}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col s12 l12 m12">
                            <div className="card z-depth-5 deal-info-widgets">
                                <div className="card-content deal-user-input-padding">
                                    {joinDealDiv}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Deal;