import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './viewdeal.css';
import { Pie } from 'react-chartjs-2';
import { Link } from 'react-router-dom';

import Loading from '../../../loading/loading';
import EditDeal from './editDeal';

class ViewDeal extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loaded: false,
            editDeal: false,
        }

        this.emails = [];


        this.addTime = this.addTime.bind(this);
        this.setNewTime = this.setNewTime.bind(this);
        this.updateDates = this.updateDates.bind(this);
        this.updateUserAmount = this.updateUserAmount.bind(this);
        this.addUserToDeal = this.addUserToDeal.bind(this);
        this.updateDealAmount = this.updateDealAmount.bind(this);
        this.removeFromDeal = this.removeFromDeal.bind(this);
        this.syncDeal = this.syncDeal.bind(this);
        this.addExistingDealUser = this.addExistingDealUser.bind(this);
        this.updateDealSettings = this.updateDealSettings.bind(this);
        this.showListings = this.showListings.bind(this);
        this.removeFromEmail = this.removeFromEmail.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.editDeal = this.editDeal.bind(this);
    }



    componentDidMount() {
        clearInterval(this.timer);
        let data = {};

        data['id'] = this.props.data;

        fetch(this.props.route + `/admingetdeal`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.setState({ data: res, loaded: true });
                this.setTimer(res.deal[0].expires);

                //Modal for pdfs
                var modal = document.querySelectorAll('.modal');
                M.Modal.init(modal);

                //Date picker
                M.Datepicker.init(document.querySelectorAll('.datepicker'), { format: 'yyyy-mm-dd' });

                //user drop down
                M.FormSelect.init(document.querySelectorAll('select'));

                //Set reminder date
                if (res.deal[0].reminderDate === "0000-00-00") {
                    document.getElementById('reminder-date').value = 'Email Sent!';
                } else {
                    document.getElementById('reminder-date').value = res.deal[0].reminderDate.split("T")[0];
                }

                //set due date
                if (res.deal[0].dueDate === "0000-00-00") {
                    document.getElementById('final-due-date').value = 'Email Sent!';
                } else {
                    document.getElementById('final-due-date').value = res.deal[0].dueDate.split("T")[0];
                }

                //set maturity date
                if (res.deal[0].maturityDate === "0000-00-00") {
                    document.getElementById('maturity-date').value = 'Not Set';
                } else {
                    document.getElementById('maturity-date').value = res.deal[0].maturityDate.split("T")[0];
                }

            })
            .catch((err) => {
                console.log(err);
            })
    }

    editDeal() {
        this.setState({ editDeal: true });
    }

    moneyFormat(x) {
        if (x == null) {
            return;
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    createChart(data) {



        let colors = ["red", "blue", "green", "yellow", "pink", "purple", "brown", "black", "white"];

        let dataSet = {
            labels: [],
            datasets: [
                {
                    data: [],
                    backgroundColor: [],
                    hoverBackgroundColor: []
                }
            ]
        };

        for (var i in data.users) {
            let user = data.users[i];
            dataSet.labels.push(user.name);
            dataSet.datasets[0].data.push(user.amount);
            dataSet.datasets[0].backgroundColor.push(colors[i]);
            dataSet.datasets[0].hoverBackgroundColor.push(colors[i]);
        }

        return dataSet;
    }


    setExpireTime(date, hours) {
        if (typeof date === 'string') {
            return new Date(new Date(date).getTime() + hours * 3600000);
        }
        return new Date(date.getTime() + hours * 3600000);
    }

    setNewTime(dateString) {
        let date = this.addTime(dateString);

        let data = {};
        data['dealId'] = this.state.data.deal[0].id;
        data['newDate'] = dateString;

        if (date != null) {
            data['newDate'] = date.toString();
        } else {
            return;
        }

        fetch(this.props.route + `/addtime`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                M.toast({ html: `Time Added!`, classes: 'rounded green' });
                this.componentDidMount();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    //Check if expired or not
    addTime(dateString) {

        let hours = prompt("Add Time (Hours)", "");

        if (hours === "" || hours === null || isNaN(hours)) {
            return null;
        }

        var countDownDate = new Date(dateString).getTime();

        var now = new Date().getTime();

        if (countDownDate - now < 0) {
            return this.setExpireTime(new Date(), hours);
        }

        return this.setExpireTime(dateString, hours);
    }

    setTimer(dateString) {
        var countDownDate = new Date(dateString).getTime();

        let timeDiv = document.getElementById('deal-time');

        var now = new Date().getTime();

        var distance = countDownDate - now;

        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        timeDiv.innerHTML = `${days}D : ${hours}H : ${minutes}M : ${seconds}S`;


        if (distance < 0) {
            timeDiv.innerHTML = `EXPIRED`;
            clearInterval(this.timer);
            return;
        }

        this.timer = setInterval(() => {
            var now = new Date().getTime();

            var distance = countDownDate - now;

            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            timeDiv.innerHTML = `${days}D : ${hours}H : ${minutes}M : ${seconds}S`;

            if (distance < 0) {
                timeDiv.innerHTML = `EXPIRED`;
                clearInterval(this.timer);
                return;
            }

        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    showPDF(route, id) {
        //Use same modal as map
        document.getElementById('map').innerHTML = `<iframe height="95%" width="100%" src="${this.props.pdfRoute}/${route}/${id}.pdf"></iframe>`;
        document.getElementById('pdf-btn-click').click();
    }


    removeUser(userId, dealId) {

        if (window.confirm("Remove User?")) {
            let data = {};
            data['userId'] = userId;
            data['dealId'] = dealId;

            fetch(this.props.route + `/removeuser`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    M.toast({ html: `User Removed!`, classes: 'rounded red' });
                    this.componentDidMount();
                })
                .catch((err) => {
                    console.log(err);
                })
        }

    }

    acceptUser(userId, dealId, amount, name) {

        if (window.confirm("Accept Partner?")) {
            let data = {};
            data['userId'] = userId;
            data['dealId'] = dealId;
            data['amount'] = amount;
            data['name'] = name;
            data['date'] = this.state.data.deal[0].dueDate;

            fetch(this.props.route + `/acceptuser`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    M.toast({ html: `User Accepted!`, classes: 'rounded green' });
                    this.componentDidMount();
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    updateDates() {
        let data = {};
        data['reminderDate'] = document.getElementById('reminder-date').value;
        data['dueDate'] = document.getElementById('final-due-date').value;
        data['maturityDate'] = document.getElementById('maturity-date').value;
        data['dealId'] = this.state.data.deal[0].id;

        fetch(this.props.route + `/updatedealdates`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                M.toast({ html: `Updated Dates!`, classes: 'rounded green' });
            })
            .catch((err) => {
                console.log(err);
            })
    }

    updateUserAmount(userId, dealId) {
        let amount = window.prompt("Enter New Amount!");
        if (amount == "" || amount == null) {
            return;
        }

        let data = {}
        data['userId'] = userId;
        data['dealId'] = dealId;
        data['amount'] = amount;
        data['dealRate'] = this.state.data.deal[0].dealRate;
        data['dealFee'] = this.state.data.deal[0].dealFee;

        fetch(this.props.route + `/updateuseramount`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                if (res.response) {
                    M.toast({ html: `Updated Amount!`, classes: 'rounded green' });
                    this.componentDidMount();
                } else {
                    M.toast({ html: res.reason, classes: 'rounded red' });
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    updateDealAmount() {
        let amount = window.prompt("Enter New Amount!");
        if (amount == "" || amount == null) {
            return;
        }

        let data = {}
        data['dealId'] = this.state.data.deal[0].id;
        data['amount'] = amount;

        fetch(this.props.route + `/adminupdatedealamount`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                M.toast({ html: `Updated Amount!`, classes: 'rounded green' });
                this.componentDidMount();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    updateDealSettings(data) {

        fetch(this.props.route + `/adminupdatedeal`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                M.toast({ html: `Updated Deal!`, classes: 'rounded green' });
                this.setState({ editDeal: false })
                this.componentDidMount();
            })
            .catch((err) => {
                console.log(err);
            })
    }

    removeFromDeal(userId) {
        if (window.confirm("Remove User?")) {
            let data = {}
            data['userId'] = userId;
            data['dealId'] = this.state.data.deal[0].id;

            fetch(this.props.route + `/removefromdeal`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    M.toast({ html: `User Removed!`, classes: 'rounded green' });
                    this.componentDidMount();

                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    addUserToDeal(userId, email, name) {
        let data = {}
        data['userId'] = userId;
        data['email'] = email;
        data['name'] = name;
        data['dealId'] = this.state.data.deal[0].id;

        fetch(this.props.route + `/addusertodeal`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.componentDidMount();
                document.body.style.overflow = "visible";

            })
            .catch((err) => {
                console.log(err);
            })
    }

    showAddUser() {
        document.getElementById('add-user-btn-click').click()
    }

    syncDeal() {
        let data = {}
        data['dealId'] = this.state.data.deal[0].id;

        fetch(this.props.route + `/syncdeal`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                M.toast({ html: `Deal Synced!`, classes: 'rounded green' });
                this.componentDidMount();

            })
            .catch((err) => {
                console.log(err);
            })
    }

    addExistingDealUser() {
        if (window.confirm("Add User to deal?")) {
            let amount = document.getElementById('partnerAmount').value;
            let userId = document.getElementById('add-user-selected').value;
            if (amount == "" || amount == null || userId == "" || userId == null) {
                return;
            }
            let data = {}
            data['userId'] = userId;
            data['dealId'] = this.state.data.deal[0].id;
            data['amount'] = amount;
            data['lastName'] = this.state.data.deal[0].lastName;


            fetch(this.props.route + `/addexistingdealuser`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    if (res.response) {
                        M.toast({ html: `User added to deal!`, classes: 'rounded green' });
                        this.componentDidMount();
                    } else {
                        M.toast({ html: res.reason, classes: 'rounded red' });
                    }


                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    removeFromEmail(id) {
        for (var i in this.emails) {
            if (this.emails[i] == id) {
                this.emails.splice(i, 1);
                break;
            }
        }
    }

    showListings() {
        document.getElementById('relator-map').setAttribute('src', this.state.data.externalMap);
    }

    sendEmail() {
        if (window.confirm("Send Email?")) {
            let sub = document.getElementById('email-subject').value;
            let msg = document.getElementById('email-msg').value;
            if (sub == "" || sub == null || msg == "" || msg == null) {
                return;
            }
            let data = {}
            data['id'] = this.state.data.deal[0].id;
            data['sub'] = sub;
            data['msg'] = msg;
            data['emails'] = this.emails; //Actaully ids :S

            fetch(this.props.route + `/adminsendemail`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    if (res) {
                        M.toast({ html: `Email Sent!`, classes: 'rounded green' });

                    } else {
                        M.toast({ html: res.reason, classes: 'rounded red' });
                    }


                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    render() {

        if (!this.state.loaded) {
            return <Loading />
        }

        if (this.state.editDeal) {
            return <EditDeal update={this.updateDealSettings} data={this.state.data.deal[0]} />
        }

        let data = this.state.data;

        let dealFee;
        let dealRate;
        let increments;
        let minIncrement;

        let participants;
        let allUsers;
        let userList;
        let userDropdown;

        let dataChart = this.createChart(data);

        let owner;
        let fs;

        let amountLeft = 0;
        let totalInvested = 0;
        let offering = 0;

        let filogix;
        let commitment;
        let appraisal;
        let bureau;
        let other;

        let lastName;
        let dealId;

        let reminderDate;
        let dueDate;

        let externalMap;

        let dealAmount = 0;

        let userChips;

        let listingModal = "";

        if (data != null) {



            dealFee = data.deal[0].dealFee;
            dealRate = data.deal[0].dealRate;
            increments = data.deal[0].increments;
            minIncrement = data.deal[0].minIncrement;


            dealId = data.deal[0].id;

            lastName = data.deal[0].lastName;

            dealAmount = data.deal[0].dealAmount;

            externalMap = data.externalMap;

            let total = 0;
            for (var i in data.users) {
                total += parseInt(data.users[i].amount);
                totalInvested += parseInt(data.users[i].amount);
            }

            //totalInvested += parseInt(data.deal[0].retainings);
            amountLeft = parseInt(data.deal[0].offering) - total;
            offering = parseInt(data.deal[0].offering);


            if (data.deal[0].filogix != "" && data.deal[0].filogix != null) {
                filogix = <div className="col s6 m6 l6"><div className="deal-pdf-box z-depth-5 "><p className="login-color">Filogix PDF</p><button onClick={() => { this.showPDF("filogix", data.deal[0].filogix) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div></div>

            } else {
                filogix = <div className="col s6 m6 l6"><div className="deal-pdf-box z-depth-5"><p className="login-color">Filogix PDF</p><button onClick={() => { this.showPDF("filogix", data.deal[0].filogix) }} className="btn btn-large waves-effect waves-light z-depth-3 disabled"> <i className="material-icons prefix">folder</i></button></div></div>
            }
            if (data.deal[0].commitment != "" && data.deal[0].commitment != null) {
                commitment = <div className="col s6 m6 l6"><div className="deal-pdf-box z-depth-5"><p className="login-color">Commitment PDF</p><button onClick={() => { this.showPDF("commitment", data.deal[0].commitment) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div></div>

            } else {
                commitment = <div className="col s6 m6 l6"><div className="deal-pdf-box z-depth-5"><p className="login-color">Commitment PDF</p><button onClick={() => { this.showPDF("commitment", data.deal[0].commitment) }} className="btn btn-large waves-effect waves-light z-depth-3 disabled"> <i className="material-icons prefix">folder</i></button></div></div>
            }
            if (data.deal[0].appraisal != "" && data.deal[0].appraisal != null) {
                appraisal = <div className="col s6 m6 l6"><div className="deal-pdf-box z-depth-5"><p className="login-color">Appraisal PDF</p><button onClick={() => { this.showPDF("appraisal", data.deal[0].appraisal) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div></div>

            } else {
                appraisal = <div className="col s6 m6 l6"><div className="deal-pdf-box z-depth-5"><p className="login-color">Appraisal PDF</p><button onClick={() => { this.showPDF("appraisal", data.deal[0].appraisal) }} className="btn btn-large waves-effect waves-light z-depth-3 disabled"> <i className="material-icons prefix">folder</i></button></div></div>
            }
            if (data.deal[0].bureau != "" && data.deal[0].bureau != null) {
                bureau = <div className="col s6 m6 l6"><div className="deal-pdf-box z-depth-5"><p className="login-color">Bureau PDF</p><button onClick={() => { this.showPDF("bureau", data.deal[0].bureau) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div></div>

            } else {
                bureau = <div className="col s6 m6 l6"><div className="deal-pdf-box z-depth-5"><p className="login-color">Bureau PDF</p><button onClick={() => { this.showPDF("bureau", data.deal[0].bureau) }} className="btn btn-large waves-effect waves-light z-depth-3 disabled"> <i className="material-icons prefix">folder</i></button></div></div>
            }
            if (data.deal[0].other != "" && data.deal[0].other != null) {
                other = <div className="col s12 m12 l12"><div className="deal-pdf-box z-depth-5"><p className="login-color">Other PDF</p><button onClick={() => { this.showPDF("other", data.deal[0].other) }} className="btn btn-large waves-effect waves-light z-depth-3"> <i className="material-icons prefix">folder</i></button></div></div>
            } else {
                other = <div className="col s12 m12 l12"><div className="deal-pdf-box z-depth-5"><p className="login-color">Other PDF</p><button onClick={() => { this.showPDF("other", data.deal[0].other) }} className="btn btn-large waves-effect waves-light z-depth-3 disabled"> <i className="material-icons prefix">folder</i></button></div></div>
            }


            //HomeOwnership
            // owner = <tr key={Math.random()} className="mydeal-table-row" >
            //     <td className="mydeals-table-font">Home Ownership</td>
            //     <td className="mydeals-table-font">${this.moneyFormat(this.state.data.deal[0].retainings)}&nbsp;&nbsp;<button onClick={this.updateDealAmount} className="btn-small z-depth-3 blue"><i className="material-icons prefix">edit</i></button></td>
            //     <td className="mydeals-table-font green-check">Accepted</td>
            //     <td><button className="btn z-depth-3 red"><i className="material-icons prefix">clear</i></button></td>
            // </tr>

            //All others
            participants = this.state.data.users.map((data) => {
                let status;
                let actions;

                if (data.userId == "q0yQCoJtduJn57KFONE1KrzJbbf6bM1O") {
                   
                    return (
                        <tr key={Math.random()} className="mydeal-table-row" >
                            <td className="mydeals-table-font">{data.name}</td>
                            <td className="mydeals-table-font">${this.moneyFormat(data.amount)}&nbsp;&nbsp;<button  onClick={this.updateDealAmount} className="btn-small z-depth-3 blue"><i className="material-icons prefix">edit</i></button></td>
                            <td className="mydeals-table-font green-check">Accepted</td>
                            <td><button className="btn z-depth-3 red"><i className="material-icons prefix">clear</i></button></td>
                        </tr>
                    );
                }
                if (data.status == "Under Review") {
                    status = <td className="mydeals-table-font yellow-text">Under Review</td>
                    actions = <td><button onClick={() => { this.acceptUser(data.userId, data.dealId, data.amount, lastName) }} className="btn z-depth-3 green"><i className="material-icons prefix">check</i></button></td>
                } else {
                    status = <td className="mydeals-table-font green-check">Accepted</td>
                    actions = <td><button onClick={() => { this.removeUser(data.userId, data.dealId) }} className="btn z-depth-3 red"><i className="material-icons prefix">clear</i></button></td>
                }
                return (
                    <tr key={Math.random()} className="mydeal-table-row" >
                        <td className="mydeals-table-font">{data.name}</td>
                        <td className="mydeals-table-font">${this.moneyFormat(data.amount)}&nbsp;&nbsp;<button onClick={() => { this.updateUserAmount(data.userId, data.dealId) }} className="btn-small z-depth-3 blue"><i className="material-icons prefix">edit</i></button></td>
                        {status}
                        {actions}
                    </tr>
                );
            })

            //All users that have access to deal
            allUsers = this.state.data.allUsers.map((data) => {
                let inDeal = false;
                for (var i in this.state.data.inDeal) {
                    if (this.state.data.inDeal[i] == data.id) {
                        inDeal = true;
                        break;
                    }
                }
                if (!inDeal) {
                    return null;
                }
                return (
                    <tr key={Math.random()} className="mydeal-table-row" >
                        <td className="mydeals-table-font">{data.name}</td>
                        <td className="mydeals-table-font"><button onClick={() => { this.removeFromDeal(data.id) }} className="btn z-depth-3 red"><i className="material-icons prefix">clear</i></button></td>
                    </tr>
                );
            })

            //Show all users
            userList = this.state.data.allUsers.map((data) => {
                for (var i in this.state.data.inDeal) {
                    if (this.state.data.inDeal[i] == data.id) {
                        return null;
                    }
                }

                return (
                    <tr key={Math.random()} className="mydeal-table-row" >
                        <td className="mydeals-table-font">{data.name}</td>
                        <td className="mydeals-table-font">${this.moneyFormat(data.capitalAvailable)}</td>
                        <td className="mydeals-table-font"><button onClick={() => { this.addUserToDeal(data.id, data.email, data.name) }} className="btn z-depth-3 green"><i className="material-icons prefix">person_add</i></button></td>
                    </tr>
                );
            })

            userDropdown = this.state.data.allUsers.map((data) => {
                for (var i in this.state.data.users) {
                    if (this.state.data.users[i].userId == data.id) {
                        return null;
                    }
                }
                return (
                    <option key={Math.random()} value={data.id}>{data.name}</option>
                );
            })

            userChips = this.state.data.allUsers.map((data) => {
                let inDeal = false;
                for (var i in this.state.data.inDeal) {
                    if (this.state.data.inDeal[i] == data.id) {
                        this.emails.push(data.id);
                        inDeal = true;
                    }
                }
                if (!inDeal) {
                    return null;
                }
                return (
                    <div key={Math.random()} className="chip">
                        {data.name}
                        <i className="close material-icons" onClick={() => { this.removeFromEmail(data.id) }}>close</i>
                    </div>
                );
            })


        }

        //Set up chart
        let legend = {
            "display": true,
            "position": "right",
            "fullWidth": true,
            "reverse": false,
            "labels": {
                "fontColor": "white"
            }
        }

        return (

            <div>
                <div id="modal4" className="modal modal-background-color " style={{ width: "95%", height: "88%" }}>
                    <div id="listings-modal" className="modal-content pdf-modal-content">
                        <button data-target="modal4" className="btn modal-close waves-effect waves-light red right">X</button>
                        <a target="_blank" href={externalMap} className="btn modal-close waves-effect waves-light blue left">View On Realtor.ca</a><br /><br />
                        <iframe id="relator-map" style={{ width: "100%", height: "90%" }} ></iframe>
                    </div>
                </div>

                <button id="add-user-btn-click" data-target="modal1" className="btn modal-trigger hidden "></button>
                <div id="modal1" className="modal register-modal modal-large">
                    <div className="modal-content">
                        <table className="centered manage-entries-table z-depth-5">
                            <thead>
                                <tr className="viewdeal-tabel-th">
                                    <th>Name</th>
                                    <th>Capital Available</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody id="deals-table">
                                {userList}
                            </tbody>
                        </table>
                    </div>
                </div>

                <button id="pdf-btn-click" data-target="pdf-modal" className="btn modal-trigger hidden "></button>
                <div id="pdf-modal" className="modal modal-background-color " style={{ width: "96%", height: "80%" }}>
                    <div className="modal-content pdf-modal-content">
                        <button data-target="pdf-modal" className="btn modal-close waves-effect waves-light red right">X</button><br /><br />
                        <div id="map" className="google-map">

                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m6 l3">
                        <div className="viewdeal-top-widgets z-depth-5">
                            <div className="card">
                                <div className="card-content blue-grey white-text view-deal-lastname view-deal-widget-height">
                                    <p className="card-stats-title"><i className="mdi-social-group-add"></i>Last Name</p>
                                    <h4 className="card-stats-number view-deal-small-txt ">{lastName}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m6 l3">
                        <div className="viewdeal-top-widgets z-depth-5">
                            <div className="card">
                                <div className="card-content blue-grey white-text">
                                    <p className="card-stats-title"><i className="mdi-social-group-add"></i>Total Loan Amount</p>
                                    <h4 className="card-stats-number">${this.moneyFormat(dealAmount)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m6 l3">
                        <div className="viewdeal-top-widgets z-depth-5">
                            <div className="card">
                                <div className="card-content blue-grey white-text">
                                    <p className="card-stats-title"><i className="mdi-social-group-add"></i>Amount Offered</p>
                                    <h4 className="card-stats-number">${this.moneyFormat(offering)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col s12 m6 l3">
                        <div className="viewdeal-top-widgets z-depth-5">
                            <div className="card">
                                <div className="card-content blue-grey white-text">
                                    <p className="card-stats-title"><i className="mdi-social-group-add"></i>Total Subscribed</p>
                                    <h4 className="card-stats-number">${this.moneyFormat(totalInvested)}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m12 l8">
                        <div className="viewdeal-offers-div ">
                            <table className="centered manage-entries-table z-depth-5">
                                <thead>
                                    <tr className="viewdeal-tabel-th">
                                        <th>Name</th>
                                        <th>Amount</th>
                                        <th>Acceptance Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>

                                <tbody id="deals-table">
                                    {owner}
                                    {fs}
                                    {participants}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="col l4 s12 m12">
                        <div className="card z-depth-5 mydeal-info-bg">
                            <div className="card-content white-text mydeal-thin-div">
                                <h5 className="card-stats-title center white-text"><b>Allocation</b></h5>
                            </div>
                        </div>
                        <div className="chart-div z-depth-5">
                            <Pie data={dataChart} legend={legend} />
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="col l4 s12 m12">
                        <div className="deal-pdf-box z-depth-5">
                            <p className="login-color">Email Partners</p>
                            <div className="viewdeal-email-div">
                                {userChips}
                            </div>
                            <div className="row">
                                <div className="email-form-padding">
                                    <div className="input-field col s12">
                                        <i className="material-icons prefix green-check">subject</i>
                                        <input id="email-subject" type="text" className="login-color" />
                                        <label htmlFor="email-subject">Subject</label>
                                    </div>
                                    <div className="input-field col s12">
                                        <i className="material-icons prefix green-check">email</i>
                                        <textarea id="email-msg" className="materialize-textarea login-color"></textarea>
                                        <label htmlFor="email-msg">Message</label>
                                    </div>
                                    <button onClick={this.sendEmail} className="waves-effect waves-light btn green z-depth-3"><i className="material-icons prefix right">send</i>Send</button>
                                </div>
                            </div>
                        </div>
                        <div className="viewdeal-pdf-div">
                            {filogix}
                            {commitment}
                            {appraisal}
                            {bureau}
                            {other}
                        </div>
                    </div>
                    <div className="col l4 s12 m12">
                        <div className="viewdeal-allusers-div">
                            <table className="centered manage-entries-table z-depth-5">
                                <thead>
                                    <tr className="viewdeal-tabel-th">
                                        <th>Name</th>
                                        <th><button onClick={this.showAddUser} className="waves-effect waves-light btn green z-depth-3 admin-add-user-padding"><i className="material-icons prefix">person_add</i></button></th>
                                    </tr>
                                </thead>
                                <tbody id="deals-table">
                                    {allUsers}
                                </tbody>
                            </table>
                        </div>
                        <div className="deal-admin-add-user z-depth-5">
                            <p className="white-text center"><b>Add Partner (No Notifications)</b></p>
                            <div className="input-field col l6 m6 s12">
                                <select id="add-user-selected">
                                    {userDropdown}
                                </select>
                                <label>Add Existing Partner</label>
                            </div>
                            <div className="col l6 m6 s12">
                                <div className="input-field col s12">
                                    <input id="partnerAmount" type="text" className="validate white-text" />
                                    <label htmlFor="partnerAmount">Amount</label>
                                </div>
                            </div>
                            <div className="row center">
                                <button onClick={this.addExistingDealUser} className="waves-effect waves-light btn green z-depth-3">Add to deal</button>
                            </div>
                        </div>
                    </div>
                    <div className="col l4 s12 m12">
                        <div className="col l12 s12 m12">
                            <div className="viewdeal-deal-options view-deal-top-spacing z-depth-5 row">
                                <div className="col l6 m6 s12">
                                    <Link to={`/ViewDeal?id=${dealId}`} className="btn waves-effect waves-light green z-depth-2 view-deal-option-btn">View Deal</Link>
                                </div>
                                <div className="col l6 m6 s12">
                                    <Link to={`/documents?id=${dealId}`} className="btn waves-effect waves-light blue z-depth-2 view-deal-option-btn">View Docs</Link>
                                </div>
                                <div className="col l6 m6 s12">
                                    <button onClick={this.syncDeal} className="btn waves-effect waves-light teal z-depth-2 view-deal-option-btn">Sync Deal</button>
                                </div>
                                <div className="col l6 m6 s12">
                                    <button onClick={this.editDeal} className="btn modal-trigger z-depth-2 waves-light orange darken-3 view-deal-option-btn">Edit Deal</button>
                                </div>
                            </div>
                        </div>
                        <div className="col l12 s12 m12">
                            <div className="viewdeal-deal-options z-depth-5 row">
                                <p className="card-stats-title login-color">Time Remaining</p>
                                <h4 className="card-stats-number timer-font-size login-color" id="deal-time">0:0:0:0</h4>
                                <button onClick={() => { this.setNewTime(data.deal[0].expires) }} className="btn waves-effect waves-light z-depth-3"> <i className="material-icons prefix right">add</i>Add Time</button>
                            </div>
                        </div>
                        <div className="col l12 s12 m12">
                            <div className="viewdeal-deal-options z-depth-5 row">
                                <div className="input-field">
                                    <i className="material-icons prefix green-icon">date_range</i>
                                    <input type="text" id="reminder-date" className="datepicker login-color" />
                                    <label htmlFor="reminder-date" className="active">Reminder Due Date</label>
                                </div>
                                <div className="input-field">
                                    <i className="material-icons prefix green-icon">date_range</i>
                                    <input type="text" id="final-due-date" className="datepicker login-color" />
                                    <label htmlFor="final-due-date" className="active">Final Due Date</label>
                                </div>
                                <div className="input-field">
                                    <i className="material-icons prefix green-icon">date_range</i>
                                    <input type="text" id="maturity-date" className="datepicker login-color" />
                                    <label htmlFor="maturity-date" className="active">Maturity Date</label>
                                </div>
                                <button onClick={this.updateDates} className="btn waves-effect waves-light z-depth-3">Update Dates</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewDeal;