import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './home.css';
import { Link, Redirect } from 'react-router-dom';

import ShowDeals from './showdeals'
import Loading from '../loading/loading'

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            loaded: false
        }

        this.carousel = null;
        this.removeFromDeal = this.removeFromDeal.bind(this);
    }



    componentDidMount() {
        fetch(this.props.route + `/grabhomedeals`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.setState({ data: res.data, loaded: true });
                if (res.data.length !== 0) {
                    if (res.data.length === 2) {
                        let elems = document.getElementById('carousel');
                        this.carousel = M.Carousel.init(elems, { indicators: true, dist: -50, noWrap: true });
                    } else {
                        let elems = document.getElementById('carousel');
                        this.carousel = M.Carousel.init(elems, { indicators: true, dist: -50 });
                    }
                } else {
                    document.getElementById('carousel').style.display = "none";
                }
            })
            .catch((err) => {
                
            })

    }

    removeFromDeal(dealId) {
        if (window.confirm("Remove Deal?")) {
            let data = {};
            data['dealId'] = dealId;

            fetch(this.props.route + `/removeuserfromdeal`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    window.location.reload();

                })
                .catch((err) => {
                    M.toast({ html: `Error!`, classes: 'rounded red' });
                })

        }
    }


    render() {
        if (!this.state.loaded) {
            return <Loading />
        }

        if (this.props.role === 9) {
            return <Redirect push to="/AdminPanel" />;
        } else if (this.props.role === 3 || this.props.role === 2) {
            return <Redirect push to="/DealView" />;
        } 
        
        let message = "No New Deals Available, Check Back Soon!";
        let header = <p className="home-deal-header"><i className="material-icons prefix home-no-deals-icon">query_builder</i><br />{message}</p>
        if (this.state.data != null) {
            if (this.state.data.length !== 0) {
                message = `Available Deals (${this.state.data.length})`
                header = <p className="home-deal-header-big">{message}</p>
            }
        }
        return (
            <div>
                <div className="home-available-deals-div">
                    {header}
                    <div id="carousel"className="carousel">
                        <ShowDeals removeUser={this.removeFromDeal} deals={this.state.data} />
                    </div>
                    <div className="view-self-deals-div">
                        <Link className="btn btn-large sidenav-close green z-depth-3" to="/MyDeals" >View Your Deals<i className="material-icons prefix right">folder</i></Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;