import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './viewuser.css';
import { Link } from 'react-router-dom';
import { Pie } from 'react-chartjs-2';
import GoogleMapReact from 'google-map-react';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import M from 'materialize-css/dist/js/materialize.min.js';

import Loading from '../../../loading/loading';

class ViewUser extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            loaded: false,
            filterType: "date"
        }

        this.exportData = this.exportData.bind(this);
        this.sortFilter = this.sortFilter.bind(this);
    }



    componentDidMount() {

        fetch(this.props.route + `/getmydeals?id=${this.props.data}`, {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include'
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                this.setState({ data: res.data, capital: res.capital, capitalAvailable: res.capitalAvailable, name: res.name, loaded: true });
                //Select
                M.FormSelect.init(document.querySelectorAll('select'));
            })
            .catch((err) => {
                console.log(err);
            })
    }


    createChart(pending, repaid, funded) {
        let dataSet = {
            labels: ["Pending", "Funded", "Repaid"],
            datasets: [
                {
                    data: [pending, funded, repaid],
                    backgroundColor: ["#ef6c00", "#388E3C", "#01579b"],
                    hoverBackgroundColor: ["#ef6c00", "#388E3C", "#01579b"]
                }
            ]
        };

        return dataSet;
    }

    moneyFormat(x) {
        if (x == null) {
            return;
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    showDetails(id) {
        document.getElementById(id).click();
    }

    hoverOn(id) {
        document.getElementById(id).classList.remove("hidden");
        document.getElementById("first" + id).classList.add("hidden");
    }

    hoverOff(id) {
        document.getElementById("first" + id).classList.remove("hidden");
        document.getElementById(id).classList.add("hidden");
    }

    exportData(type) {

        let tableData = [];

        for (var i in this.state.data) {
            const data = this.state.data[i];
            if (data === null || data.type != type) { continue }
            tableData.push({
                "Last Name": data.lastName,
                "Amount": data.amount,
                "Type": data.firstOrSecond,
                "Monthly Payment": data.monthlyPayment,
                "Maturity Date": new Date(data.maturityDate).toDateString(),
                "YTM%": data.dealFeeAmount,
                "Status": data.status
            });
        }

        var ws = XLSX.utils.json_to_sheet(tableData);

        var csv = XLSX.utils.sheet_to_csv(ws);

        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, type);

        function s2ab(s) {
            var buf = new ArrayBuffer(s.length);
            var view = new Uint8Array(buf);
            for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
            return buf;
        }

        saveAs(new Blob([s2ab(csv)], { type: "application/octet-stream" }), `${this.state.name}_${type}.csv`);
    }

    sortFilter() {
        const data = this.state.data;
        switch (this.state.filterType) {
            case "date":
                data.sort((a, b) => {
                    if (new Date(a.date) > new Date(b.date)) {
                        return -1;
                    }
                    return 1
                })
                break;
            case "amount":
                data.sort((a, b) => {
                    if (parseFloat(a.amount) > parseFloat(b.amount)) {
                        return -1;
                    }
                    return 1
                })
                break;
            case "monthlyPayment":
                data.sort((a, b) => {
                    if (parseFloat(a.monthlyPayment) > parseFloat(b.monthlyPayment)) {
                        return -1;
                    }
                    return 1
                })
                break;
            case "maturityDate":
                data.sort((a, b) => {
                    if (new Date(a.maturityDate) > new Date(b.maturityDate)) {
                        return -1;
                    }
                    return 1
                })
                break;
            case "lastName":
                data.sort((a, b) => a.lastName.localeCompare(b.lastName))
                break;
            default:

                break
        }

        return data
    }

    render() {

        if (!this.state.loaded) {
            return <Loading />
        }

        let data = this.sortFilter()

        let dataChart;

        let totalFunded = 0;
        let totalPending = 0;
        let totalRepaid = 0;
        let capitalAvailable = 0;
        let capitalCommitted = 0;

        let clientName = ""

        let fundedDeals;
        let pendingDeals;
        let repaidDeals;

        let totalMonthly = 0;
        let totalPendingMonthly = 0;


        if (data != null) {

            clientName = this.state.name;

            capitalAvailable = this.state.capitalAvailable;
            capitalCommitted = this.state.capital;


            for (var i in data) {
                switch (data[i].type.toUpperCase()) {
                    case "NA":
                        totalPending += parseInt(data[i].amount);
                        break;
                    case "FUNDED":
                        totalFunded += parseInt(data[i].amount);
                        break;
                    case "REPAID":
                        totalRepaid += parseInt(data[i].amount);
                        break;
                    default:
                        break;
                }
            }

            dataChart = this.createChart(totalPending, totalRepaid, totalFunded);

            //Funded Deals
            repaidDeals = this.state.data.map((data) => {
                if (data.type !== "Repaid") {
                    return null;
                }

                let status;
                if (data.status === "Under Review") {
                    status = <td className="mydeals-table-font">Under Review</td>
                } else {
                    status = <td className="mydeals-table-font">Accepted</td>
                }
                return (
                    <tr key={Math.random()} className="mydeal-table-row" >
                        <td className="mydeals-table-font">{data.lastName}</td>
                        <td className="mydeals-table-font">${this.moneyFormat(data.amount)}</td>
                        <td className="mydeals-table-font">{data.firstOrSecond}</td>
                        {status}
                        <td><Link to={`/ViewDeal?id=${data.dealId}`} className="btn btn-small z-depth-3">View Deal</Link>&nbsp;&nbsp;&nbsp;&nbsp;<Link to={`/documents?id=${data.dealId}`} className="btn btn-small z-depth-3">View Docs</Link></td>
                    </tr>
                );
            })

            //Pending Deals
            pendingDeals = this.state.data.map((data) => {
                if (data.type !== "NA") {
                    return null;
                }
                let status;
                if (data.status === "Under Review") {
                    status = <td className="mydeals-table-font">Under Review</td>
                } else {
                    status = <td className="mydeals-table-font">Accepted</td>
                }
                let mDate = data.maturityDate.split("T")[0];
                let date = new Date(mDate).toString().split(" ");;
                let newDate = `${date[1]} ${date[3]}`;

                if (!isNaN(parseInt(data.monthlyPayment))) {
                    totalPendingMonthly += parseFloat(data.monthlyPayment);
                }
                return (
                    <tr key={Math.random()} className="mydeal-table-row" >
                        <td className="mydeals-table-font">{data.lastName}</td>
                        <td className="mydeals-table-font">${this.moneyFormat(data.amount)}</td>
                        <td className="mydeals-table-font">{data.firstOrSecond}</td>
                        <td className="mydeals-table-font">${this.moneyFormat(data.monthlyPayment)}</td>
                        <td className="mydeals-table-font">{newDate}</td>
                        <td className="mydeals-table-font">{data.dealFeeAmount}%</td>
                        {status}
                        <td><Link to={`/ViewDeal?id=${data.dealId}`} className="btn btn-small z-depth-3">View Deal</Link>&nbsp;&nbsp;&nbsp;&nbsp;<Link to={`/documents?id=${data.dealId}`} className="btn btn-small z-depth-3">View Docs</Link></td>
                    </tr>
                );
            })

            //Funded Deals
            fundedDeals = this.state.data.map((data) => {
                if (data.type !== "Funded") {
                    return null;
                }
                let status;
                if (data.status === "Under Review") {
                    status = <td className="mydeals-table-font">Under Review</td>
                } else {
                    status = <td className="mydeals-table-font">Accepted</td>
                }
                if (!isNaN(parseInt(data.monthlyPayment))) {
                    totalMonthly += parseInt(data.monthlyPayment);
                }
                let mDate = data.maturityDate.split("T")[0];
                let date = new Date(mDate).toString().split(" ");;
                let newDate = `${date[1]} ${date[3]}`;
                return (
                    <tr key={Math.random()} className="mydeal-table-row" >
                        <td className="mydeals-table-font">{data.lastName}</td>
                        <td className="mydeals-table-font">${this.moneyFormat(data.amount)}</td>
                        <td className="mydeals-table-font">{data.firstOrSecond}</td>
                        <td className="mydeals-table-font">${this.moneyFormat(data.monthlyPayment)}</td>
                        <td className="mydeals-table-font">{newDate}</td>
                        <td className="mydeals-table-font">{data.dealFeeAmount}%</td>
                        {status}
                        <td><Link to={`/ViewDeal?id=${data.dealId}`} className="btn btn-small z-depth-3">View Deal</Link>&nbsp;&nbsp;&nbsp;&nbsp;<Link to={`/documents?id=${data.dealId}`} className="btn btn-small z-depth-3">View Docs</Link></td>
                    </tr>
                );
            })
        }

        totalMonthly = totalMonthly.toFixed(2);
        totalPendingMonthly = totalPendingMonthly.toFixed(2);

        let legend = {
            "display": true,
            "position": "right",
            "fullWidth": true,
            "reverse": false,
            "labels": {
                "fontColor": "white"
            }
        }

        const MarkerComponent = ({ text, color, id, eleId, name, amount, type }) => (
            <div onMouseEnter={() => { this.hoverOn(id) }} onMouseLeave={() => { this.hoverOff(id) }}>
                <div id={eleId} style={{
                    color: 'white',
                    background: color,
                    padding: '5px 5px',
                    display: 'inline-flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '50px',
                    transform: 'translate(-50%, -50%)'
                }}>
                    {text}
                </div>
                <div id={id} className="mydeals-expandable-map-options hidden z-depth-5">
                    <br />
                    <p className="maps-view-text"><b>{name}</b></p>
                    <p className="maps-view-text"><b>${amount}</b></p>

                    <Link to={`viewDeal?id=${id}`} className="waves-effect waves-light btn btn-small green z-depth-3 admin-add-user-padding"><i className="material-icons prefix right">send</i>View</Link>

                </div>
            </div>
        );

        const markers = this.state.data.map((data) => {
            let color = "#ef6c00";
            let name = "Pending"
            if (data.type === "Funded") {
                color = "#388e3c"
                name = "Funded"
            } else if (data.type === "Repaid") {
                color = "#1e88e5";
                name = "Repaid";
            }
            return (
                <MarkerComponent
                    key={Math.random()}
                    lat={data.lat}
                    lng={data.lng}
                    name={data.lastName}
                    amount={this.moneyFormat(data.amount)}
                    text={data.lastName}
                    type={name}
                    color={color}
                    id={data.dealId}
                    eleId={"first" + data.dealId}
                />
            );
        })

        return (
            <div className="mydeals-div">
                <div className="row">
                    <div className="flexbox">
                        <div className="col l4 s12 m6">
                            <div className="card z-depth-5 mydeal-info-bg">
                                <div className="card-content white-text mydeal-thin-div">
                                    <h5 className="card-stats-title center white-text"><b>{clientName}</b></h5>
                                </div>
                            </div>
                            <div className="view-deal-chart-div z-depth-5">
                                <Pie data={dataChart} legend={legend} />
                            </div>
                        </div>
                        <div className="col l8 s12 m6">
                            <br />
                            <div style={{ height: '42vh', width: '100%' }}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: "AIzaSyCbFfP2TbedHVAsTWLZuZBxJ6GqdRLl81k" }}
                                    defaultCenter={{ lat: 43.5890, lng: -79.6441 }}
                                    defaultZoom={8}
                                >
                                    {markers}
                                </GoogleMapReact>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col l12 s12 m12">
                        <div className="col m6 s12 l6">
                            <div className="card z-depth-5 mydeal-info-bg mydeal-radius">
                                <div className="card-content white-text mydeal-thin-div">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Total Capital Available</b></p>
                                    <h2 className="center login-color total-size mydeal-thin-text">${this.moneyFormat(capitalCommitted)}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col m6 s12 l6">
                            <div className="card z-depth-5 mydeal-info-bg mydeal-radius">
                                <div className="card-content white-text mydeal-thin-div">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Remaining Capital</b></p>
                                    <h2 className="center login-color total-size mydeal-thin-text">${this.moneyFormat(capitalAvailable)}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col m4 s6 l4">
                            <div className="card z-depth-5 orange darken-3 mydeal-radius">
                                <div className="card-content white-text mydeal-thin-div">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Total Pending</b></p>
                                    <h2 className="center login-color total-size mydeal-thin-text">${this.moneyFormat(totalPending)}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col m4 s6 l4">
                            <div className="card z-depth-5 green darken-2 mydeal-radius">
                                <div className="card-content white-text mydeal-thin-div">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Total Funded</b></p>
                                    <h2 className="center login-color total-size mydeal-thin-text">${this.moneyFormat(totalFunded)}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col m4 s12 l4">
                            <div className="card z-depth-5 light-blue darken-4 mydeal-radius">
                                <div className="card-content white-text mydeal-thin-div">
                                    <p className="card-stats-title center"><i className="mdi-social-group-add"></i><b>Total Repaid</b></p>
                                    <h2 className="center login-color total-size mydeal-thin-text">${this.moneyFormat(totalRepaid)}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col m12 s12 l12">
                        <div className="mydeal-table-div z-depth-5 orange darken-3">
                            <p className="center login-color mydeals-header-size">Pending Deals</p>
                            <table className="centered manage-deals-table responsive-table">
                                <thead>
                                    <tr className="manage-deals-headers">
                                        <th>Last Name</th>
                                        <th>Amount</th>
                                        <th>Type</th>
                                        <th>Monthly Payment <br />(${this.moneyFormat(totalPendingMonthly)})</th>
                                        <th>Maturity Date</th>
                                        <th>YTM%</th>
                                        <th>Acceptance Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <tbody id="deals-table">
                                    {pendingDeals}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="col m12 s12 l12">
                        <div className="mydeal-table-div z-depth-5 green darken-2">
                            <div className="row">
                                <div className="col s2">
                                    <button onClick={() => { this.exportData("Funded") }} className="btn z-depth-3">Export</button>
                                </div>
                                <div className="col l8 m7 s6">
                                    <p className="center login-color mydeals-header-size">Funded Deals</p>
                                </div>
                                <div className="input-field right col l2 m3 s4">
                                    <select defaultValue={this.state.filterType} onChange={(e) => { this.setState({ filterType: e.target.value }) }}>
                                        <option value="date" >Latest</option>
                                        <option value="amount" >Amount</option>
                                        <option value="maturityDate" >Maturity Date</option>
                                        <option value="monthlyPayment" >Monthly Payment</option>
                                        <option value="lastName" >Last Name</option>
                                    </select>
                                    <label>Sort By</label>
                                </div>
                            </div>
                            <table className="centered manage-deals-table responsive-table">
                                <thead>
                                    <tr className="manage-deals-headers">
                                        <th>Last Name</th>
                                        <th>Amount</th>
                                        <th>Type</th>
                                        <th>Monthly Payment <br />(${this.moneyFormat(totalMonthly)})</th>
                                        <th>Maturity Date</th>
                                        <th>YTM%</th>
                                        <th>Acceptance Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>

                                <tbody id="deals-table">
                                    {fundedDeals}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="flexbox">
                        <div className="col m12 s12 l12">
                            <div className="mydeal-table-div z-depth-5 light-blue darken-4">
                                <div className="row">
                                    <div className="col s2">
                                        <button onClick={() => { this.exportData("Repaid") }} className="btn z-depth-3">Export</button>
                                    </div>
                                    <div className="col l8 m7 s6">
                                        <p className="center login-color mydeals-header-size">Repaid Deals</p>
                                    </div>
                                    <div className="input-field right col l2 m3 s4">
                                        <select defaultValue={this.state.filterType} onChange={(e) => { this.setState({ filterType: e.target.value }) }}>
                                            <option value="date" >Latest</option>
                                            <option value="amount" >Amount</option>
                                            <option value="lastName" >Last Name</option>
                                        </select>
                                        <label>Sort By</label>
                                    </div>
                                </div>
                                <table className="centered manage-deals-table responsive-table">
                                    <thead>
                                        <tr className="manage-deals-headers">
                                            <th>Last Name</th>
                                            <th>Amount</th>
                                            <th>Type</th>
                                            <th>Acceptance Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody id="deals-table">
                                        {repaidDeals}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewUser;