import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './documents.css';

import Loading from '../loading/loading'
import PartnerView from './views/partners/partners'
import AdminView from './views/admins/admins'

class Documents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loaded: false

        }
    }


    componentDidMount() {
        fetch(`${this.props.route}/getdealfiles?id=${window.location.href.split("?id=")[1]}`, {
            credentials: 'include',
        })
            .then((res) => {
                return res.json();
            }).then((res) => {
                this.setState({ allowed: res.isAuthed, data: res, loaded: true })
            }).catch((err) => {
                console.log(err);

            })
    }

    render() {


        if (this.state.allowed != null && !this.state.allowed) {
            return <Redirect push to="/Home" />;
        }

        if (!this.state.loaded) {
            return <Loading />
        }

        if (!this.state.data.isAdmin) {
            return (
                <div>
                    <PartnerView hasSig={this.props.hasSig} route={this.props.route} data={this.state.data} link={this.props.link} sigRoute={this.props.sigRoute} userId={this.props.userId} />
                </div>
            );
        } else {
            return (
                <div>
                    <AdminView role={this.props.role} hasSig={this.props.hasSig} route={this.props.route} data={this.state.data} link={this.props.link} userId={this.props.userId} sigRoute={this.props.sigRoute} />
                </div>
            );
        }
    }
}

export default Documents;
