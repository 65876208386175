import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';
import './chat.css';

import Cookies from 'js-cookie';

class Chat extends Component {

    constructor(props) {
        super(props);

        this.state = {
            minimized: true,
        }

        this.socket = this.props.socket;

        this.currentChat = null;
        this.chatStatus = null;

        this.collapsible = null;
        this.socketListener = this.socketListener.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.closeChat = this.closeChat.bind(this);
        this.showChat = this.showChat.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        
    }

    componentDidMount() {
        let ele = M.Collapsible.init(document.querySelectorAll('.collapsible'));
        this.socketListener();
    }

    socketListener() {

        this.socket.on('requestToken', () => {
            this.socket.emit('sendToken', { token: Cookies.get('id') });
        });

        if (this.props.role !== 9) { //For non admins only

            this.socket.on('emailSent', () => {
                M.toast({ html: `Email Sent!`, classes: 'rounded green' });
            })

            this.socket.on('chatData', (data, isOnline) => {
                if (isOnline) {
                    let ele =  document.getElementById('live-chat-title');
                    if (ele != null) {
                        document.getElementById('live-chat-title').innerHTML = 'Chat Live!'
                    }
                    this.chatStatus = "Chat Live!";

                    if (data == null || data[0].messages == "") {
                        return null;
                    }
                    let html = "";
                    let messages = JSON.parse(data[0].messages);
                    for (var i in messages) {
                        html += `<div className="chat-msg" style="text-align:center;background-color: rgba(0,0,0,.3);padding: 0.2em 0 0.2em 0;word-wrap: break-word; width: 95%; margin: 0.5em auto;border-radius:5px;">
                        <a className="center">${messages[i].name}</a>
                        <p className="word-wrap">${messages[i].msg}</p>
                    </div>`;
                    }
                    this.currentChat = html; //save chat for when minimized

                    let chatDiv = document.getElementById('chat-msg-div');
                    if (chatDiv != null) {
                        document.getElementById('chat-msg-div').innerHTML = html;
                    }
    
                    let div = document.getElementById('chat-msg-div');
                    if (div != null) {
                        div.scrollTop = div.scrollHeight;
                    }
                } else {
                    this.chatStatus = "Offline!";
                    if (data == null || data[0].messages == "") {
                        return null;
                    }
                    let html = "";
                    let messages = JSON.parse(data[0].messages);
                    for (var i in messages) {
                        html += `<div className="chat-msg" style="text-align:center;background-color: rgba(0,0,0,.3);padding: 0.2em 0 0.2em 0;word-wrap: break-word; width: 95%; margin: 0.5em auto;border-radius:5px;">
                        <a className="center">${messages[i].name}</a>
                        <p className="word-wrap">${messages[i].msg}</p>
                    </div>`;
                    }

                    this.currentChat = html; //save chat for when minimized

                    let chatDiv = document.getElementById('chat-msg-div');
                    if (chatDiv != null) {
                        document.getElementById('chat-msg-div').innerHTML = html;
                    }

                    let div = document.getElementById('chat-msg-div');
                    if (div != null) {
                        div.scrollTop = div.scrollHeight;
                    }
                }
            });

            this.socket.on('messageReceivedAdmin', () => {
                M.toast({ html: `New Message!`, classes: 'rounded green' });
            })

            this.socket.on('messageReceived', (data) => {
                let html = "";

                for (var i in data.msg) {
                    html += `<div className="chat-msg" style="text-align:center;background-color: rgba(0,0,0,.3);padding: 0.2em 0 0.2em 0;word-wrap: break-word; width: 95%; margin: 0.5em auto;border-radius:5px;">
                    <a className="center">${data.msg[i].name}</a>
                    <p className="word-wrap">${data.msg[i].msg}</p>
                </div>`;
                }

                this.currentChat = html; //save chat for when minimized

                let chatDiv = document.getElementById('chat-msg-div');
                if (chatDiv != null) {
                    document.getElementById('chat-msg-div').innerHTML = html;
                }

                let div = document.getElementById('chat-msg-div');
                if (div != null) {
                    div.scrollTop = div.scrollHeight;
                }
            })
        } else {
            this.socket.on('checkStatus', () => {
                this.socket.emit('isOnline');
            });
        }

    }

    sendMessage() {
        let message = document.getElementById('chat-msg-input').value;
        if (message == "" || message == null) {
            return null;
        }
        this.socket.emit('sendMessage', { token: Cookies.get('id'), name: this.props.name, msg: message });
        document.getElementById('chat-msg-input').value = "";
    }

    scrollBottom() {
        let div = document.getElementById('chat-msg-div');
        if (div != null) {
            div.scrollTop = div.scrollHeight;
        }
    }

    showChat() {
        this.setState({ minimized: false })
        this.socket.emit('sendToken', { token: Cookies.get('id') });
    }

    closeChat() {
        this.setState({ minimized: true })
    }

    handleEnter = (event) => {
        if(event.key == 'Enter'){
            this.sendMessage();
        }
      }

    render() {
        if (this.props.role == "9") {
            return null;
        }
        if (this.state.minimized) {
            return (
                <div>
                    <div id="chat-div" className="chat-div-min">
                        <ul className="collapsible">
                            <div onClick={this.showChat}>
                                <div className="collapsible-header">
                                    <i className="material-icons blue-text">chat</i>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <div id="chat-div" className="chat-div">
                        <ul className="collapsible">
                            <li onClick={this.scrollBottom} className="chat-bottom">
                                <div className="collapsible-header chat-div-padding">
                                    <i className="material-icons blue-text">chat</i><a id="live-chat-title" className="black-text">Chat Offline</a>
                                    <button onClick={this.closeChat} className="chat-exit-btn btn-small">X</button>
                                </div>
                                <div className="collapsible-body chat-padding">
                                    <div id="chat-msg-div" className="chat-inner-div">
              
                                    </div>
                                    <div className="chat-enter-msg">
                                        <div className="chat-enter-div">
                                            <div className="text-area-input left">
                                                <input onKeyPress={this.handleEnter} placeholder="Type your message..." id="chat-msg-input" type="text" className="chat-input-width"></input>
                                            </div>
                                            <button onClick={this.sendMessage} className="btn waves-effect waves-light btn-floating chat-send-btn right">
                                                <i className="material-icons right">send</i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            );
        }
    }
}

export default Chat;