import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css/dist/js/materialize.min.js';

class EditDeal extends Component {

    constructor(props) {
        super(props);

        //Deal id in old database
        this.broker = null;
        this.city = null;
        this.lastName = null;

        //all users
        this.state = {

        }

        this.editDeal = this.editDeal.bind(this);
    }

    componentDidMount() {

        document.getElementById('update-deal-comments').value = this.props.data.comments;

        var collapse = document.querySelectorAll('.collapsible');
        M.Collapsible.init(collapse);
    }

    editDeal() {
        let data = {};

        data["dealId"] = this.props.data.id;
        data["address"] = document.getElementById('update-deal-address').value;
        data["amount"] = document.getElementById('update-deal-amount').value;
        data["rate"] = document.getElementById('update-deal-rate').value;
        data["fee"] = document.getElementById('update-deal-fee').value;

        data["propertyValue"] = document.getElementById('update-deal-property-value').value;
        data["loanToValue"] = document.getElementById('update-deal-ltv').value;

        data["creditScore"] = document.getElementById('update-deal-credit-score').value;

        data["retainings"] = document.getElementById('update-deal-retainings').value;
        data["offering"] = document.getElementById('update-deal-offering').value;
        data["increments"] = document.getElementById('update-deal-increments').value;
        data["minIncrement"] = document.getElementById('update-deal-incrementsMin').value;
        data['comments'] = document.getElementById('update-deal-comments').value;

        data['dealFeeAmount'] = document.getElementById('update-deal-fee-amount').value;

        data['lastName'] = document.getElementById('update-deal-client-name').value;

        data['mortgageType'] = document.getElementById('update-deal-mortgage-type').value;

        data['mortgagePriority'] = document.getElementById('update-deal-priority-mortgage').value;

        this.props.update(data);

    }


    updateOfferings(dealAmount) {
        document.getElementById('offer-active').classList.add('active');
        document.getElementById('update-deal-offering').value = parseInt(dealAmount) - parseInt(document.getElementById('update-deal-retainings').value);
    }

    moneyFormat(x) {
        if (x == null) {
            return;
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    render() {
        let data = this.props.data;

        let clientName;
        let address;
        let creditScore;
        let dealAmount;
        let dealFee;
        let dealFeeAmount;
        let dealRate;
        let dealType;
        let increments;
        let ltv;
        let minIncrement;
        let offering;
        let propertyValue;
        let priorityMortgage;
        let retainings;


        if (data != null) {
            clientName = data.lastName;
            address = data.address;
            creditScore = data.creditScore;
            dealAmount = data.dealAmount;
            dealFee = data.dealFee;
            dealRate = data.dealRate;
            dealType = data.dealType;
            increments = data.increments;
            ltv = data.loanToValue;
            minIncrement = data.minIncrement;
            offering = data.offering;
            propertyValue = data.propertyValue;
            priorityMortgage = data.priorityMortage;
            retainings = data.retainings;
            dealFeeAmount = data.dealFeeAmount;

        }

        return (
            <div>
                <div className="crete-deal-div row">
                    <br />
                    <div className="create-deal-btn col l12 m12 s12">
                        <button onClick={this.editDeal} className="btn btn-large green z-depth-5">Edit Deal</button>
                    </div>
                    <div className="col l12 m12 s12">
                        <ul className="collapsible create-deal-dealinfo z-depth-5">
                            <li className="active">
                                <div className="collapsible-header"><i className="material-icons">filter_drama</i>Offer Summary</div>
                                <div className="collapsible-body">
                                    <div className="row">
                                        <div className="input-field col l4 m6 s12">
                                            <i className="material-icons prefix green-check">person</i>
                                            <input id="update-deal-client-name" type="text" className="validate login-color" defaultValue={clientName} />
                                            <label htmlFor="update-deal-client-name" className="active">Client Name</label>
                                        </div>
                                        <div className="input-field col l4 m6 s12">
                                            <i className="material-icons prefix green-check">home</i>
                                            <input id="update-deal-address" type="text" className="validate login-color" defaultValue={address} />
                                            <label htmlFor="update-deal-address" className="active">Property Address</label>
                                        </div>
                                        <div className="input-field col l4 m6 s12">
                                            <i className="material-icons prefix green-check">attach_money</i>
                                            <input id="update-deal-property-value" type="text" className="validate login-color" defaultValue={propertyValue} />
                                            <label htmlFor="update-deal-property-value" className="active">Property Value</label>
                                        </div>
                                        <div className="input-field col l4 m6 s12">
                                            <i className="material-icons prefix green-check">attach_money</i>
                                            <input id="update-deal-priority-mortgage" type="text" className="validate login-color" defaultValue={priorityMortgage} />
                                            <label htmlFor="update-deal-priority-mortgage" className="active">Priority Mortgage</label>
                                        </div>
                                        <div className="input-field col l4 m6 s12">
                                            <i className="material-icons prefix green-check">attach_money</i>
                                            <input id="update-deal-amount" type="text" className="validate login-color" defaultValue={dealAmount} />
                                            <label htmlFor="update-deal-amount" className="active">Total Deal Amount</label>
                                        </div>
                                        <div className="input-field col l4 m6 s12">
                                            <i className="material-icons prefix green-check">format_list_numbered</i>
                                            <input id="update-deal-mortgage-type" type="text" className="validate login-color" defaultValue={dealType} />
                                            <label htmlFor="update-deal-mortgage-type" className="active">Mortgage Type</label>
                                        </div>
                                        <div className="input-field col l6 m6 s12">
                                            <i className="material-icons prefix green-check">border_horizontal</i>
                                            <input id="update-deal-ltv" type="text" className="validate login-color" defaultValue={ltv} />
                                            <label htmlFor="update-deal-ltv" className="active">Loan To Value</label>
                                        </div>
                                        <div className="input-field col l6 m6 s12">
                                            <i className="material-icons prefix green-check">account_balance</i>
                                            <input id="update-deal-credit-score" type="text" className="validate login-color" defaultValue={creditScore} />
                                            <label htmlFor="update-deal-credit-score" className="active">Credit Score</label>
                                        </div>
                                        <div className="input-field col l12 m12 s12">
                                            <i className="material-icons prefix green-check">keyboard</i>
                                            <textarea id="update-deal-comments" className="materialize-textarea login-color"></textarea>
                                            <label htmlFor="update-deal-comments" className="active">Comments</label>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="collapsible-header"><i className="material-icons">settings</i>Offer Particulars</div>
                                <div className="collapsible-body">
                                    <div className="row">
                                        <div className="input-field col l4 m4 s12">
                                            <i className="material-icons prefix green-check">local_offer</i>
                                            <input id="update-deal-rate" type="text" className="validate login-color" defaultValue={dealRate} />
                                            <label htmlFor="update-deal-rate" className="active">Deal Rate</label>
                                        </div>
                                        <div className="input-field col l4 m4 s12">
                                            <i className="material-icons prefix green-check">library_add</i>
                                            <input id="update-deal-fee-amount" type="text" className="validate login-color" defaultValue={dealFeeAmount}/>
                                            <label htmlFor="update-deal-fee-amount" className="active" >Fee Offered</label>
                                        </div>
                                        <div className="input-field col l4 m4 s12">
                                            <i className="material-icons prefix green-check">border_horizontal</i>
                                            <input id="update-deal-fee" type="text" className="validate login-color" defaultValue={dealFee} />
                                            <label htmlFor="update-deal-fee" className="active">Annualized Deal Fee</label>
                                        </div>
                                        <div className="input-field col l6 m6 s12">
                                            <i className="material-icons prefix green-check">attach_money</i>
                                            <input id="update-deal-retainings" onKeyUp={() => { this.updateOfferings(dealAmount) }} type="text" className="validate login-color" defaultValue={retainings} />
                                            <label htmlFor="update-deal-retainings" className="active" >Home Ownership Participation</label>
                                        </div>
                                        <div className="input-field col l6 m6 s12">
                                            <i className="material-icons prefix green-check">attach_money</i>
                                            <input id="update-deal-offering" type="text" className="validate login-color" defaultValue={offering} />
                                            <label htmlFor="update-deal-offering" id="offer-active" className="active" >Offering</label>
                                        </div>
                                        <div className="input-field col l6 m6 s12">
                                            <i className="material-icons prefix green-check">dehaze</i>
                                            <input id="update-deal-increments" type="text" className="validate login-color" defaultValue={increments} />
                                            <label htmlFor="update-deal-increments" className="active">Increments Of</label>
                                        </div>
                                        <div className="input-field col l6 m6 s12">
                                            <i className="material-icons prefix folder-color">filter_list</i>
                                            <input id="update-deal-incrementsMin" type="text" className="validate login-color" defaultValue={minIncrement} />
                                            <label htmlFor="update-deal-incrementsMin" className="active">Minimum Investment</label>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>

                </div>
            </div>
        );
    }
}

export default EditDeal;