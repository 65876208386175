import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './loading.css';

class Loading extends Component {

    constructor(props) {
        super(props);

        this.loader = null;

    }

    render() {
        return (
            <div className="loading-div">
                <i id="loadingText" className="loading-font">Fetching Data</i>
                <div className="progress">
                    <div className="indeterminate"></div>
                </div>
            </div>
        );
    }
}

export default Loading;