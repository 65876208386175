import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import { Link } from 'react-router-dom';

class ShowDeals extends Component {



    startTimers(id, dateString) {
        var countDownDate = new Date(dateString).getTime();
        setTimeout(() => {
            var now = new Date().getTime();

            var distance = countDownDate - now;

            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);


            if (distance < 0) {
                let timeLeft = `EXPIRED`;
                this.updateTime(id, timeLeft);
                clearInterval(int);
                return;
            }

            let timeLeft = `${days}:${hours}:${minutes}:${seconds}`;
            this.updateTime(id, timeLeft);

        }, 100);

        let int = setInterval(() => {
            var now = new Date().getTime();

            var distance = countDownDate - now;

            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);


            if (distance < 0) {
                let timeLeft = `EXPIRED`;
                this.updateTime(id, timeLeft);
                clearInterval(int);
                return;
            }

            let timeLeft = `${days}:${hours}:${minutes}:${seconds}`;
            this.updateTime(id, timeLeft);


        }, 1000);
    }

    updateTime(id, time) {
        let ele = document.getElementById(id);
        if (ele != null) {
            ele.innerHTML = time;
        }
    }

    moneyFormat(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    render() {
        let carouselClass = "carousel-item home-deal z-depth-5";
        let body;
        if (this.props.deals != null) {
            if (this.props.deals.length === 2) {
                carouselClass = "carousel-item home-deal z-depth-5 carousel-two";
            }
            body = this.props.deals.map((data) => {
                let id = Math.random();
                this.startTimers(id, data.expires);
                return (
                    <div key={Math.random()} className={carouselClass} >
                        <p className="home-deal-text"><i className="material-icons prefix green-check">timer</i>&nbsp;<i className="login-color" id={id}></i></p>
                        <p className="home-deal-text"><i className="material-icons prefix green-check">person</i>&nbsp;{data.lastName}</p>
                        <p className="home-deal-text"><i className="material-icons prefix green-check">attach_money</i>{this.moneyFormat(data.dealAmount)}</p>
                        <br />
                        <Link to={`/ViewDeal?id=${data.id}`} className="btn btn-primary waves-effect waves-light green z-depth-3">View Deal<i className="material-icons right">send</i></Link>
                        <button onClick={() => {this.props.removeUser(data.id)}} className="btn btn-small waves-effect waves-light red z-depth-3 home-trash-btn"><i className="material-icons">delete</i></button>
                    </div>
                );
            })
        }
        return body;
    }
}

export default ShowDeals;