import React, { Component } from 'react';
import M from 'materialize-css/dist/js/materialize.min.js';
import SignatureCanvas from 'react-signature-canvas'
import { Redirect } from 'react-router-dom';

class CreateSignature extends Component {

    constructor(props) {
        super(props);

        this.state = {
            saved: false
        }

        this.clearSig = this.clearSig.bind(this);
        this.uploadSig = this.uploadSig.bind(this);
    }

    clearSig() {
        this.canvas.clear();
    }

    uploadSig() {

        if (!this.canvas.isEmpty()) {
            let data = {};

            data['sig'] = this.canvas.getTrimmedCanvas().toDataURL();

            fetch(this.props.route + `/uploadSig`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                credentials: 'include',
                body: JSON.stringify(data)
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    if (res.response) {
                        M.toast({ html: `Signature Saved!`, classes: 'rounded green' });
                        this.setState({saved: true});
                        window.location.reload();
                        
                    } else {
                        M.toast({ html: `Error Saving!`, classes: 'rounded red' });
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        } else {
            M.toast({ html: `Please draw your signature!`, classes: 'rounded red' });
        }

    }

    render() {

        if (this.state.saved) {
            return <Redirect to='/Home' />
        }

        return (
            <div>
                <div className="row">
                    <div className="col s12">
                        <div className="setUp-signature">
                            <h4 className="hide-on-small-only">Please draw your signature</h4>
                            <br />
                            <div className="sig-canvas-bg">
                                <SignatureCanvas penColor='#3f51b5' clearOnResize={false} minWidth={2} maxWidth={2}
                                    ref={(ref) => { this.canvas = ref; }} canvasProps={{ className: 'sigCanvas' }} />
                            </div>
                            <br /><br />
                            <button onClick={this.uploadSig} className="btn btn-large  green z-depth-3"> <i className="material-icons prefix left">cloud_upload</i>Save</button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button onClick={this.clearSig} className="btn btn-large red z-depth-3"><i className="material-icons prefix left">delete</i>clear</button>

                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

export default CreateSignature;
