import React, { Component } from 'react';
import 'materialize-css/dist/css/materialize.min.css';
import './login.css';

class Login extends Component {

    constructor(props) {
        super(props);

        this.login = this.login.bind(this);
    }

    login(e) {
        //Prevent original form submit
        e.preventDefault();

        //Disable login btn until server responds.
        document.getElementById('login-btn').setAttribute("disabled", "disabled");

        //Get user details from form
        var data = {};
        data['username'] = e.target[0].value;
        data['password'] = e.target[1].value;

        fetch(this.props.route + `/login`, {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
            .then((res) => {
                return res.json();
            })
            .then((res) => {
                document.getElementById('login-btn').removeAttribute("disabled");
                this.props.changeAuth(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    render() {
        return (
            <div className="login-div z-depth-5">
                <form onSubmit={this.login}>
                    <h1 className="login-header center">Log In</h1>
                    <div className="login-inputs">
                        <div className="input-field">
                            <i className="material-icons prefix green-icon">account_circle</i>
                            <input id="login-username" type="text" className="validate login-color" required />
                            <label htmlFor="login-username">Username</label>
                        </div>
                        <br />
                        <div className="input-field">
                            <i className="material-icons prefix green-icon">lock</i>
                            <input className="login-color" id="login-password" type="password" className="validate login-color" required />
                            <label htmlFor="login-password">Password</label>
                        </div>
                    </div>
                    <br />
                    <div className="login-btn-div">
                        <button id="login-btn" className="btn waves-effect waves-light btn-large green login-btn" type="submit" name="action">Login
                            <i className="material-icons right">send</i>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Login;